
class VetEventAction {
    /**
     * VetEvent Action Class
     */
    constructor(axios) {
        this.http = axios;        
    }

    /**
     * Create one vet event
     * @param {Object} data 
     * @returns Promise object
     */
    create(data) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Read all events belong to vet
     * @param {Object} vetId vet id
     * @returns Promise object
     */
    read(vetId) {
        return this.http.get(`/api/events/read_by_index.php?id=${vetId}`);
    };
    /**
     * Read one vet event
     * @param {Object} id vet id
     * @returns Promise object
     */
    readOne(id) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Update one vet event
     * @param {Object} data
     * @returns Promise object
     */
    update(data) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Delete one vet event
     * @param {Object} id vet id
     * @returns Promise object
     */
    delete(id) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
}

export default VetEventAction;