import React from 'react';
import { Descriptions } from 'antd';
import store from 'store'
import DatabaseService from "../../services/database.service"
import { List, Form, Input, Button, InputNumber } from 'antd';
// https://github.com/prettymuchbryce/http-status-codes
import { StatusCodes as HttpStatus } from 'http-status-codes';
import "./PopUp.css";

class AddAnimal extends React.Component {

    onFinish = values => {
        const submitData = {
            id: store.get('usrId'),
            name: values.name,
            tierart: values.tierart,
            tierrasse: values.tierrasse,
            tierAlter: values.alter
        }
        DatabaseService.animal
            .create(submitData)
            .then(response => {
                if (response.status === HttpStatus.CREATED) {
                    console.log("AddAnimal.js: animal.create() ", response.data);
                    this.setState({ addAnimal: false });
                    this.props.refreshPets();
                    this.showpopup("myPopupconfirm");
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    async showpopup(id) {
        var popup = document.getElementById(id);

        if (!popup.classList.contains("show")) {
            popup.classList.add("show");

            let promise = new Promise((resolve, reject) => {
                setTimeout(() => resolve("done!"), 5000)
              });

            let result = await promise;

            popup.classList.remove("show");
        }
    }

    detectMob() {
        return ( ( window.innerWidth <= 800 ) && ( window.innerHeight <= 1000 ) );
    }

    render() {
        if (this.detectMob()) {
            return (
                <>
                    <h1 style={{ fontSize: 25, padding: 10 }}>Haustier hinzufügen</h1>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
                        <Form
                            style={{ width: "70%" }}
                            name="basic"
                            initialValues={{ remember: true }}
                            onFinish={this.onFinish}
                            onFinishFailed={this.onFinishFailed}
                        >
                            <Form.Item
                                name="name"
                                rules={[{ required: true, message: 'Bitte den Namen deines Tieres eingeben!' }]}
                            >
                                <Input size="large" placeholder="Name" />
                            </Form.Item>

                            <Form.Item
                                name="tierart"
                                rules={[{ required: true, message: 'Bitte die Tierart deines Tieres eingeben!' }]}
                            >
                                <Input size="large" placeholder="Tierart" />
                            </Form.Item>

                            <Form.Item
                                name="tierrasse"
                                rules={[{ required: true, message: 'Bitte die Tierrasse deines Tieres eingeben!' }]}
                            >
                                <Input size="large" placeholder="Tierrasse" />
                            </Form.Item>

                            <Form.Item
                                name="alter"
                                rules={[{ required: true, message: 'Bitte das Alter deines Tieres eingeben!' }]}
                            >
                                <InputNumber size="large" placeholder="Alter" />
                            </Form.Item>

                            <Form.Item {...this.tailLayout}>
                                <Button style={{ backgroundColor: "#25D2AA", color: "white", height: "45px", fontSize: 18 }} shape="round" htmlType="submit" block>
                                    Hinzufügen
                                </Button>
                                <div class="popup">
                                    <span class="popuptext" id="myPopupconfirm">Mein Haustier hinzugefügt!</span>
                                </div>
                            </Form.Item>
                        </Form>
                    </div>
                </>
            );
        }

        return (
            <>
                <h1>Haustier hinzufügen</h1>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
                    <Form
                        style={{ width: "70%" }}
                        name="basic"
                        initialValues={{ remember: true }}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                    >
                        <Form.Item
                            name="name"
                            rules={[{ required: true, message: 'Bitte den Namen deines Tieres eingeben!' }]}
                        >
                            <Input size="large" placeholder="Name" />
                        </Form.Item>

                        <Form.Item
                            name="tierart"
                            rules={[{ required: true, message: 'Bitte die Tierart deines Tieres eingeben!' }]}
                        >
                            <Input size="large" placeholder="Tierart" />
                        </Form.Item>

                        <Form.Item
                            name="tierrasse"
                            rules={[{ required: true, message: 'Bitte die Tierrasse deines Tieres eingeben!' }]}
                        >
                            <Input size="large" placeholder="Tierrasse" />
                        </Form.Item>

                        <Form.Item
                            name="alter"
                            rules={[{ required: true, message: 'Bitte das Alter deines Tieres eingeben!' }]}
                        >
                            <InputNumber size="large" placeholder="Alter" />
                        </Form.Item>

                        <Form.Item {...this.tailLayout}>
                            <Button style={{ backgroundColor: "#25D2AA", color: "white", height: "45px", fontSize: 18 }} shape="round" htmlType="submit" block>
                                Hinzufügen
                            </Button>
                            <div class="popup">
                                <span class="popuptext" id="myPopupconfirm">Mein Haustier hinzugefügt!</span>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            </>
        );
    }
}

export default AddAnimal
