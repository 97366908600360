import 'dotenv/config';

class cWebsocketService {
    constructor(options) {
        console.log("wss-socket.js: url: ", process.env.REACT_APP_WEBSOCKET_EVENTS_URL + "/" + options);
        // "wss://hv-websocket-server.herokuapp.com/" + store.get('usrId') + "?vet=false"
        this.Client = new WebSocket(process.env.REACT_APP_WEBSOCKET_EVENTS_URL + "/" + options);
    }
}

export {cWebsocketService};
