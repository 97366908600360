import React from 'react';
import { Select, Table, Button } from 'antd';

class Termin extends React.Component {

    constructor(props) {
        super(props);
        console.log("Termin.js: props: ", this.props);
        this.state = {
            data: this.props.vets.map(obj => { return { key: obj.vetID, name: obj.name, rate: obj.gebuehr + "€ / " + obj.slotlaenge + "min" } }),
            currentID: -1,
            currentPet: -1,
        }
    }

    onChange(checkedValues) {
        console.log('checked = ', checkedValues);
    }

    columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text) => <span style={{ fontWeight: "bold" }}>{text}</span>,
        },
        {
            title: 'Rate',
            dataIndex: 'rate',
        },
    ];

    rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            this.setState({ currentID: selectedRowKeys[0] });
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === 'Disabled User',
            // Column configuration not to be checked
            name: record.name,
        }),
    };

    detectMob() {
        return ( ( window.innerWidth <= 800 ) && ( window.innerHeight <= 1000 ) );
    }

    render() {
        const options = this.props.animalInfo.map(obj => {
            return { key: obj.id, label: obj.name, value: obj.name }
        });
        if (this.detectMob()) {
            return(
                <>  <div style={{ padding: 10 }}>
                        <h1 style={{ fontSize: 25 }}>Neuen Termin vereinbaren</h1>
                        <h4 style={{ marginTop: 20, fontSize: 18 }}>Ihr Tier</h4>
                        <Select size="large" style={{ width: "70%" }} options={options} onChange={(value, option) =>
                            this.setState({ currentPet: option.key })
                        } />
                        <h4 style={{ marginTop: 20 }}>Unsere Top-Vorschläge</h4>
                        <Table
                            rowSelection={{
                                type: "radio",
                                ...this.rowSelection,
                            }}
                            style={{ width: "100%" }}
                            pagination={false}
                            showHeader={false}
                            columns={this.columns}
                            dataSource={this.state.data}
                        />
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                            <Button size="large" style={{ opacity: !(this.state.currentID === -1 || this.state.currentPet === -1) ? 1 : 0.2, backgroundColor: "#25D2AA", color: "white", height: "50px", marginTop: 80 }} block shape="round" disabled={this.state.currentID === -1 || this.state.currentPet === -1} onClick={() => { this.props.showProfile(this.state.currentID, this.state.currentPet); }} >
                                Weiter zur Tierarztübersicht
                            </Button>
                            <Button onClick={() => this.props.handleHeaderMobileClick({ key: "suche" })} size="large" style={{ borderColor: "black", color: "black", height: "50px", marginTop: 20 }} ghost block shape="round" >Alle Ärzte anzeigen</Button>
                        </div>
                    </div>
                </>
            );
        }
        return (
            <>
                <h1>Neuen Termin vereinbaren</h1>
                <h4 style={{ marginTop: 20 }}>Ihr Tier</h4>
                <Select size="large" style={{ width: "70%" }} options={options} onChange={(value, option) =>
                    this.setState({ currentPet: option.key })
                } />
                <h4 style={{ marginTop: 20 }}>Unsere Top-Vorschläge</h4>
                <Table
                    rowSelection={{
                        type: "radio",
                        ...this.rowSelection,
                    }}
                    style={{ width: "100%" }}
                    pagination={false}
                    showHeader={false}
                    columns={this.columns}
                    dataSource={this.state.data}
                />
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                    <Button size="large" style={{ opacity: !(this.state.currentID === -1 || this.state.currentPet === -1) ? 1 : 0.2, backgroundColor: "#25D2AA", color: "white", height: "50px", marginTop: 80 }} block shape="round" disabled={this.state.currentID === -1 || this.state.currentPet === -1} onClick={() => { this.props.showProfile(this.state.currentID, this.state.currentPet); }} >
                        Weiter zur Tierarztübersicht
                    </Button>
                    <Button onClick={() => this.props.setLeftPane('suche')} size="large" style={{ borderColor: "black", color: "black", height: "50px", marginTop: 20 }} ghost block shape="round" >Alle Ärzte anzeigen</Button>
                </div>
            </>
        );
    }


}

export default Termin
