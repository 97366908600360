import React from "react"
import Paper from '@material-ui/core/Paper';
import { Select, Divider, Input, Button, Row, Col, Modal } from 'antd';
import { PlusOutlined, ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import RegisterUserModal from "./RegisterUserModal"
import { addMinutes, add, format, isBefore, getDay } from 'date-fns'
import DatabaseService from "../../services/database.service"
import store from 'store';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import isLoggedIn from "../../is_logged_in"
import { Redirect } from "react-router-dom";
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import { ButtonBase } from '@material-ui/core';
import {
    UserOutlined
} from '@ant-design/icons';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
// https://github.com/prettymuchbryce/http-status-codes
import { StatusCodes as HttpStatus } from 'http-status-codes';

const { Option } = Select;
const style = { backgroundColor: '#cbf4eb', borderRadius: "8px", borderStyle: "none" };

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <span style={{ fontSize: 20, fontWeight: "bold" }}>{children}</span>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});


class TerminVereinbaren extends React.Component {

    constructor(props) {
        super(props);
        console.log("TerminVereinbaren.js: props: ", this.props);
        this.state = {
            animalInfo: this.props.animalInfo,
            pet: this.props.pet,
            events: null,
            currentStep: 0,
            doctor: this.props.doctor,
            backdropOpen: true,
            localEvents: [],
            fields: { form: [] },
            petID: this.props.pet,
            formData: "",
            items: [],
            name: '',
            dataLoaded: false,
            modalVisible: false,
            terminButton: true,
            selectedVal: "",
            timeSlots: [],
            showMoreSlots: false,
            dayIndex: 0,
            selectedVal: "",
            valSelected: false,
            selectedTime: new Date(),
            redirect: false,
            showPetSelection: !this.props.pet,
            registerModalOpen: false,
            oeffnungszeiten: this.parseOeffnungszeiten()
        }

        this.updateEvents();
        this.getLeistungsspektrum();
    }

    parseOeffnungszeiten() {
        try {
            return JSON.parse(this.props.doctor.oeffnungszeiten);
        }
        catch (e) {
            console.log(e)
            return {};
        }
    }

    parseObj(data) {
        if (typeof data !== "undefined" && data != null) {
            var str = data.split("&quot;");
            var output = [];

            for(let i=1;i<str.length;i=i+2) {
                output.push(str[i]);
            }

            return output;
        }

        return [];
    }

    generateColumnXML(col) {
        var timespan;
        switch (getDay(col)) {
            case 0:
                timespan = this.state.oeffnungszeiten.availSunday;
                break;
            case 1:
                timespan = this.state.oeffnungszeiten.availMonday;
                break;
            case 2:
                timespan = this.state.oeffnungszeiten.availTuesday;
                break;
            case 3:
                timespan = this.state.oeffnungszeiten.availWednesday;
                break;
            case 4:
                timespan = this.state.oeffnungszeiten.availThursday;
                break;
            case 5:
                timespan = this.state.oeffnungszeiten.availFriday;
                break;
            case 6:
                timespan = this.state.oeffnungszeiten.availSaturday;
                break;
        }
        var start = new Date(timespan[0]);
        const end = new Date(timespan[1]);
        var slots = [];
        while (isBefore(start, end)) {
            const time = start;
            slots.push(<div key={"key_"+time} style={{ padding: 4 }}>
                <Button style={style} onClick={() => this.handleSlotSelection(time, col)}>{format(time, "H:mm")}</Button>
            </div>);
            start = addMinutes(start, this.state.doctor.slotlaenge);
        }
        return slots;
    }

    setUpTimeGrid() {
        if (!this.state.oeffnungszeiten || !this.state.oeffnungszeiten.availSunday) {
            return
        }
        var slots = [];
        for (var i = 1; i < 5; i++) {
            const col = add(new Date(), { days: i + this.state.dayIndex });
            slots.push(this.generateColumnXML(col));
        }
        this.setState({ timeSlots: slots });
    }

    componentDidMount() {
        this.setUpTimeGrid();
        try {
            this.setState({ fields: JSON.parse(this.props.doctor.form) });
        }
        catch (e) {
            console.log(e)
        };

    }

    registerModalClose = (id) => {
        this.setState({ registerModalOpen: false, pet: id }, () => {
            if (this.state.fields && this.state.fields.form.length !== 0) {
                this.setState({ modalVisible: true });
            }
            else {
                this.handleSelection();
            }
        });
    }

    updateEvents() {
        // DatabaseService.getEventsVets({ id: 22 })
        DatabaseService.vet.event.read(this.props.doctor.vetID)
            .then(response => {
                console.log("TerminVereinbaren.js: vet.event.read() response ", response);
                if (response.status === HttpStatus.OK) {
                    const events = response.data.records;
                    const eventsArray = events.map((singleEvent) => {
                        return { start: new Date(singleEvent.time), end: new Date(new Date(singleEvent.time).getTime() + singleEvent.slotlength * 60000) };
                    });

                    this.setState({ events: eventsArray })
                }
            })
            .catch(e => {
                console.log(e);
            });
    }

    getLeistungsspektrum() {
        DatabaseService.vet.profile
            .readOne(this.props.doctor.vetID)
            .then(response => {
                this.setState({ items: this.parseObj(response.data.leistungsspektrum) });
            })
    }

    handleSelection = () => {
        var form
        try {
            form = JSON.stringify(this.state.fields);
        }
        catch (e) {
            form = "";
        }
        const data = {
            besuchsgrund: this.state.selectedVal,
            vetID: this.state.doctor.vetID,
            userID: store.get('usrId'),
            time: this.state.selectedTime,
            slotlength: this.state.doctor.slotlaenge,
            petID: this.state.pet,
            form: form
        };

        DatabaseService.event
            .create(data)
            .then(response => {
                console.log(response.data);
                this.setState({ modalVisible: false })
                window.location.reload();
            })
            .catch(e => {
                console.log(e);
            });
    };

    handleSlotSelection = (time, column) => {
        var selectedDate = column
        selectedDate.setHours(time.getHours());
        selectedDate.setMinutes(time.getMinutes());
        selectedDate.setSeconds(0);
        this.setState({ selectedTime: selectedDate }, () => {
            if (!isLoggedIn()) {
                this.setState({ registerModalOpen: true });
            }
            else if (this.state.fields && this.state.fields.form.length !== 0) {
                this.setState({ modalVisible: true });
            }
            else {
                this.handleSelection();
            }
        });
    }

    renderSlots() {
        if (this.state.timeSlots.length !== 4) {
            return (<div />);
        } else if (!this.state.showMoreSlots) {
            return (
                <>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        {this.state.timeSlots[0].slice(0, 3)}
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        {this.state.timeSlots[1].slice(0, 3)}
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        {this.state.timeSlots[2].slice(0, 3)}
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        {this.state.timeSlots[3].slice(0, 3)}
                    </div>
                </>);
        }
        return (
            <>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    {this.state.timeSlots[0]}
                </div>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    {this.state.timeSlots[1]}
                </div>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    {this.state.timeSlots[2]}
                </div>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    {this.state.timeSlots[3]}
                </div>
            </>);
    }

    detectMob() {
        return ( ( window.innerWidth <= 800 ) && ( window.innerHeight <= 1000 ) );
    }

    render() {
        const { items, name } = this.state;
        var options;
        if (this.state.animalInfo) {
            options = this.props.animalInfo.map(obj => {
                return { key: obj.id, label: obj.name, value: obj.name }
            });
        }
        if (this.detectMob()) {
            return (
                <>
                    <Paper style={{ maxHeight: "90vh", width: "90%", display: "flex", flexDirection: "column", justifyContent: "flex-start", overflow: "auto", display: "block", padding: 10, marginLeft: "auto", marginRight: "auto", marginTop: 10 }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <span style={{ fontWeight: "bold", fontSize: 20, marginTop: 6 }}>Termin online vereinbaren</span>
                            <span style={{ fontWeight: "bold", marginTop: 20 }}>1. Besuchsgrund wählen</span>
                        </div>
                        <Select
                            style={{ width: "100%", marginTop: 10 }}
                            size="large"
                            onChange={(value) => this.setState({ selectedVal: value, valSelected: true })}
                            placeholder="Besuchsgrund auswählen"
                            dropdownRender={menu => (
                                <div>
                                    {menu}
                                    <Divider style={{ margin: '4px 0' }} />
                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                        <Input style={{ flex: 'auto' }} value={name} onChange={this.onNameChange} />
                                        <a
                                            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                            onClick={this.addItem}
                                        >
                                            <PlusOutlined /> Grund hinzufügen
                                        </a>
                                    </div>
                                </div>
                            )}
                        >
                            {items.map(item => (
                                <Option key={item}>{item}</Option>
                            ))}
                        </Select>
                        {this.state.showPetSelection && this.state.animalInfo ?
                            <>
                                <span style={{ fontWeight: "bold", marginTop: 20 }}>2. Patient auswählen</span>
                                <Select size="large" style={{ width: "70%", marginTop: 10 }} options={options} onChange={(value, option) =>
                                    this.setState({ pet: option.key })
                                } />
                            </>
                            : null}
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", opacity: this.state.valSelected ? 1 : 0.2, pointerEvents: this.state.valSelected ? "auto" : "none" }}>
                            <span style={{ fontWeight: "bold", marginTop: 20 }}>{this.state.showPetSelection && this.state.animalInfo ? "3" : "2"}. Freien Termin wählen</span>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginTop: 20 }}>
                                <Button size="large" style={{ opacity: this.state.dayIndex > 0 ? 1 : 0.2, borderColor: "black", color: "black" }} disabled={this.state.dayIndex <= 0} ghost shape="round" icon={<ArrowLeftOutlined />} onClick={() => this.setState({ dayIndex: this.state.dayIndex - 4 }, () => this.setUpTimeGrid())} />
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <span style={{ fontWeight: "bold", fontSize: 20, marginTop: 6 }}>KW {format(add(new Date(), { days: 1 + this.state.dayIndex }), "ww")}</span>
                                    <span>{format(add(new Date(), { days: 1 + this.state.dayIndex }), "d. LLL")} - {format(add(new Date(), { days: 4 + this.state.dayIndex }), "d. LLL")}</span>
                                </div>
                                <Button size="large" style={{ borderColor: "black", color: "black" }} ghost shape="round" icon={<ArrowRightOutlined />} onClick={() => this.setState({ dayIndex: this.state.dayIndex + 4 }, () => this.setUpTimeGrid())} />
                            </div>
                            <Divider />
                            <Row gutter={[8, 8]}>
                                <Col span={6} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <span>{format(add(new Date(), { days: 1 + this.state.dayIndex }), "E")}</span>
                                    <span>{format(add(new Date(), { days: 1 + this.state.dayIndex }), "d. LLL")}</span>
                                </Col>
                                <Col span={6} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <span>{format(add(new Date(), { days: 2 + this.state.dayIndex }), "E")}</span>
                                    <span>{format(add(new Date(), { days: 2 + this.state.dayIndex }), "d. LLL")}</span>
                                </Col>
                                <Col span={6} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <span>{format(add(new Date(), { days: 3 + this.state.dayIndex }), "E")}</span>
                                    <span>{format(add(new Date(), { days: 3 + this.state.dayIndex }), "d. LLL")}</span>
                                </Col>
                                <Col span={6} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <span>{format(add(new Date(), { days: 4 + this.state.dayIndex }), "E")}</span>
                                    <span>{format(add(new Date(), { days: 4 + this.state.dayIndex }), "d. LLL")}</span>
                                </Col>
                            </Row>


                            <div style={{ display: "flex", justifyContent: "space-around", width: "100%" }}>
                                {this.renderSlots()}
                            </div>

                            <Divider />

                            <Button size="large" style={{ borderColor: "black", color: "black" }} ghost shape="round" block onClick={() => this.setState({ showMoreSlots: !this.state.showMoreSlots })}>
                                {this.state.showMoreSlots ? "Spätere Termine verstecken" : "Spätere Termine anzeigen"}
                            </Button>
                        </div>
                    </Paper>
                    <RegisterUserModal onClose={this.registerModalClose} open={this.state.registerModalOpen} />
                    <Dialog onClose={() => this.setState({ modalVisible: false })} open={this.state.modalVisible} maxWidth={"lg"}>
                        <DialogTitle id="customized-dialog-title" onClose={this.props.onClose}>
                            <span style={{ fontSize: 22, fontFamily: "WorkSans", fontWeight: "bold" }} >Abschließende Fragen beantworten</span>
                        </DialogTitle>
                        <div style={{ width: "100%", display: "flex", paddingBottom: 15, paddingTop: 15, justifyContent: 'center', alignItems: 'center' }}>
                            <Formik
                                initialValues={this.state.fields}
                                onSubmit={async (values) => {
                                    this.setState({ fields: values, modalVisible: false });
                                    this.handleSelection();
                                }}
                            >
                                {({ values }) => (
                                    <Form>
                                        <FieldArray name="form">
                                            {({ insert, remove, push }) => (
                                                <div>
                                                    {values.form.length > 0 &&
                                                        values.form.map((field, index) => (
                                                            <div className="row" style={{ margin: 15 }} key={index}>
                                                                <span style={{ marginRight: 15 }}>{field.feldName}</span>
                                                                <Field
                                                                    name={`form.${index}.feldData`}
                                                                    type={field.feldTyp}
                                                                >
                                                                    {({ field, form, meta }) => (
                                                                        <div>
                                                                            <Input {...field} />
                                                                        </div>
                                                                    )}
                                                                </Field>
                                                                <ErrorMessage
                                                                    name={field.feldName}
                                                                    component="div"
                                                                    className="field-error"
                                                                />
                                                            </div>
                                                        ))}
                                                </div>
                                            )}
                                        </FieldArray>
                                        <Button size="large" style={{ backgroundColor: "#25D2AA", color: "white" }} shape="round" htmlType="submit">
                                            Termin vereinbaren
                                        </Button>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </Dialog>
                </>
            );
        }

        return (
            <>
                <Paper style={{ maxHeight: "90vh", width: "350px", display: "flex", flexDirection: "column", justifyContent: "flex-start", padding: 20, overflow: "auto" }}>
                    <span style={{ fontWeight: "bold", fontSize: 20, marginTop: 6 }}>Termin online vereinbaren</span>
                    <span style={{ fontWeight: "bold", marginTop: 20 }}>1. Besuchsgrund wählen</span>
                    <Select
                        style={{ width: "100%", marginTop: 10 }}
                        size="large"
                        onChange={(value) => this.setState({ selectedVal: value, valSelected: true })}
                        placeholder="Besuchsgrund auswählen"
                        dropdownRender={menu => (
                            <div>
                                {menu}
                                <Divider style={{ margin: '4px 0' }} />
                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                    <Input style={{ flex: 'auto' }} value={name} onChange={this.onNameChange} />
                                    <a
                                        style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                        onClick={this.addItem}
                                    >
                                        <PlusOutlined /> Grund hinzufügen
                                    </a>
                                </div>
                            </div>
                        )}
                    >
                        {items.map(item => (
                            <Option key={item}>{item}</Option>
                        ))}
                    </Select>
                    {this.state.showPetSelection && this.state.animalInfo ?
                        <>
                            <span style={{ fontWeight: "bold", marginTop: 20 }}>2. Patient auswählen</span>
                            <Select size="large" style={{ width: "70%", marginTop: 10 }} options={options} onChange={(value, option) =>
                                this.setState({ pet: option.key })
                            } />
                        </>
                        : null}
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", opacity: this.state.valSelected ? 1 : 0.2, pointerEvents: this.state.valSelected ? "auto" : "none" }}>
                        <span style={{ fontWeight: "bold", marginTop: 20 }}>{this.state.showPetSelection && this.state.animalInfo ? "3" : "2"}. Freien Termin wählen</span>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginTop: 20 }}>
                            <Button size="large" style={{ opacity: this.state.dayIndex > 0 ? 1 : 0.2, borderColor: "black", color: "black" }} disabled={this.state.dayIndex <= 0} ghost shape="round" icon={<ArrowLeftOutlined />} onClick={() => this.setState({ dayIndex: this.state.dayIndex - 4 }, () => this.setUpTimeGrid())} />
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <span style={{ fontWeight: "bold", fontSize: 20, marginTop: 6 }}>KW {format(add(new Date(), { days: 1 + this.state.dayIndex }), "ww")}</span>
                                <span>{format(add(new Date(), { days: 1 + this.state.dayIndex }), "d. LLL")} - {format(add(new Date(), { days: 4 + this.state.dayIndex }), "d. LLL")}</span>
                            </div>
                            <Button size="large" style={{ borderColor: "black", color: "black" }} ghost shape="round" icon={<ArrowRightOutlined />} onClick={() => this.setState({ dayIndex: this.state.dayIndex + 4 }, () => this.setUpTimeGrid())} />
                        </div>
                        <Divider />
                        <Row gutter={[8, 8]}>
                            <Col span={6} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <span>{format(add(new Date(), { days: 1 + this.state.dayIndex }), "E")}</span>
                                <span>{format(add(new Date(), { days: 1 + this.state.dayIndex }), "d. LLL")}</span>
                            </Col>
                            <Col span={6} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <span>{format(add(new Date(), { days: 2 + this.state.dayIndex }), "E")}</span>
                                <span>{format(add(new Date(), { days: 2 + this.state.dayIndex }), "d. LLL")}</span>
                            </Col>
                            <Col span={6} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <span>{format(add(new Date(), { days: 3 + this.state.dayIndex }), "E")}</span>
                                <span>{format(add(new Date(), { days: 3 + this.state.dayIndex }), "d. LLL")}</span>
                            </Col>
                            <Col span={6} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <span>{format(add(new Date(), { days: 4 + this.state.dayIndex }), "E")}</span>
                                <span>{format(add(new Date(), { days: 4 + this.state.dayIndex }), "d. LLL")}</span>
                            </Col>
                        </Row>


                        <div style={{ display: "flex", justifyContent: "space-around", width: "100%" }}>
                            {this.renderSlots()}
                        </div>

                        <Divider />

                        <Button size="large" style={{ borderColor: "black", color: "black" }} ghost shape="round" block onClick={() => this.setState({ showMoreSlots: !this.state.showMoreSlots })}>
                            {this.state.showMoreSlots ? "Spätere Termine verstecken" : "Spätere Termine anzeigen"}
                        </Button>
                    </div>
                </Paper>
                <RegisterUserModal onClose={this.registerModalClose} open={this.state.registerModalOpen} />
                <Dialog onClose={() => this.setState({ modalVisible: false })} open={this.state.modalVisible} maxWidth={"lg"}>
                    <DialogTitle id="customized-dialog-title" onClose={this.props.onClose}>
                        <span style={{ fontSize: 22, fontFamily: "WorkSans", fontWeight: "bold" }} >Abschließende Fragen beantworten</span>
                    </DialogTitle>
                    <div style={{ width: "100%", display: "flex", paddingBottom: 15, paddingTop: 15, justifyContent: 'center', alignItems: 'center' }}>
                        <Formik
                            initialValues={this.state.fields}
                            onSubmit={async (values) => {
                                this.setState({ fields: values, modalVisible: false });
                                this.handleSelection();
                            }}
                        >
                            {({ values }) => (
                                <Form>
                                    <FieldArray name="form">
                                        {({ insert, remove, push }) => (
                                            <div>
                                                {values.form.length > 0 &&
                                                    values.form.map((field, index) => (
                                                        <div className="row" style={{ margin: 15 }} key={index}>
                                                            <span style={{ marginRight: 15 }}>{field.feldName}</span>
                                                            <Field
                                                                name={`form.${index}.feldData`}
                                                                type={field.feldTyp}
                                                            >
                                                                {({ field, form, meta }) => (
                                                                    <div>
                                                                        <Input {...field} />
                                                                    </div>
                                                                )}
                                                            </Field>
                                                            <ErrorMessage
                                                                name={field.feldName}
                                                                component="div"
                                                                className="field-error"
                                                            />
                                                        </div>
                                                    ))}
                                            </div>
                                        )}
                                    </FieldArray>
                                    <Button size="large" style={{ backgroundColor: "#25D2AA", color: "white" }} shape="round" htmlType="submit">
                                        Termin vereinbaren
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Dialog>
            </>
        );

    }


}
export default TerminVereinbaren
