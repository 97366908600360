import React from "react"
import { List, Card, Tooltip, Form, Button, Modal, Input, Select } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import store from 'store'
import { Redirect, useParams } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Avatar from "./UploadAvatar"
import DatabaseService from "../../../services/database.service"
import "../PopUp.css";

const { Option } = Select;

class Profil extends React.Component {

    constructor(props) {
        super(props);
        console.log("Profil.js: props: ", this.props);
    }

    onFinish = data => {
        console.log(this.props, data);
        const submitData = {
            id: store.get('usrId'),
            vorname: data.vorname,
            nachname: data.nachname,
            username: data.benutzername,
            geschlecht: data.geschlecht,
            email: data.email,
            addresse: data.addresse,
            token: this.props.user.token,
            validated: this.props.user.validated
        }

        DatabaseService.animalOwner
            .update(submitData)
            .then(response => {
                console.log(response.data);
                this.props.onChange(submitData);
                this.showpopup("myPopupconfirm");
                // store.set('redirectPage', "/dashboard");
            })
            .catch(e => {
                console.log(e);
            });
    }

    async showpopup(id) {
        var popup = document.getElementById(id);

        if (!popup.classList.contains("show")) {
            popup.classList.add("show");

            let promise = new Promise((resolve, reject) => {
                setTimeout(() => resolve("done!"), 5000)
              });

            let result = await promise;

            popup.classList.remove("show");
        }
    }

    detectMob() {
        return ( ( window.innerWidth <= 800 ) && ( window.innerHeight <= 1000 ) );
    }

    render() {
        if (this.detectMob()) {
            return (
                <Paper elevation={3} style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", padding: 25, margin: 30, overflow: "auto", width: "100%", height: "90%", alignSelf: "center" }}>
                    <span style={{ fontSize: 22, fontWeight: "bold" }}>Profil</span>
                    <span style={{ color: "grey", marginTop: 7 }}>In diesem Bereich können Sie Ihre Daten ändern oder aktualisieren</span>
                    <Avatar {...this.props} style={{ height: "100px", width: "100px" }} imageUrl={this.props.user.profilePic} />

                    <Form
                        layout="vertical"
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                        style={{ width: "100%", marginTop: 20 }}
                        requiredMark={false}
                        initialValues={{
                            geschlecht: this.props.user.geschlecht,
                            benutzername: this.props.user.username,
                            vorname: this.props.user.vorname,
                            nachname: this.props.user.nachname,
                            addresse: this.props.user.addresse,
                            email: this.props.user.email
                        }}
                    >

                        <Form.Item
                            label="Geschlecht"
                            name="geschlecht"
                            rules={[{ required: true, message: 'Bitte eine Auswahl treffen!' }]}
                        >
                            <Select placeholder="Bitte auswählen">
                                <Select.Option value="männlich">Männlich</Select.Option>
                                <Select.Option value="weiblich">Weiblich</Select.Option>
                                <Select.Option value="unbestimmt">Unbestimmt</Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Benutzername"
                            name="benutzername"
                            rules={[{ required: true, message: 'Bitte eine Auswahl treffen!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Vorname"
                            name="vorname"
                            rules={[{ required: true, message: 'Bitte Vorname eingeben!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Nachname"
                            name="nachname"
                            rules={[{ required: true, message: 'Bitte Nachname eingeben!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Addresse"
                            name="addresse"
                            rules={[{ required: true, message: 'Bitte eine Auswahl treffen!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="E-Mail Addresse"
                            name="email"
                            rules={[{ required: true, message: 'Bitte E-Mail eingeben!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item >
                            <Button style={{ backgroundColor: "#25D2AA", color: "white", height: "45px", fontSize: 18 }} shape="round" htmlType="submit" block>
                                Speichern
                            </Button>
                            <div class="popup">
                                <span class="popuptext" id="myPopupconfirm">Profil aktualisiert!</span>
                            </div>
                        </Form.Item>
                    </Form>
                </Paper>
            );
        }

        return (
            <Paper elevation={3} style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", padding: 25, margin: 30, overflow: "auto", width: "70%", height: "90%" }}>
                <span style={{ fontSize: 22, fontWeight: "bold" }}>Profil</span>
                <span style={{ color: "grey", marginTop: 7 }}>In diesem Bereich können Sie Ihre Daten ändern oder aktualisieren</span>
                <Avatar {...this.props} style={{ height: "100px", width: "100px" }} imageUrl={this.props.user.profilePic} />

                <Form
                    layout="vertical"
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    style={{ width: "70%", marginTop: 20 }}
                    requiredMark={false}
                    initialValues={{
                        geschlecht: this.props.user.geschlecht,
                        benutzername: this.props.user.username,
                        vorname: this.props.user.vorname,
                        nachname: this.props.user.nachname,
                        addresse: this.props.user.addresse,
                        email: this.props.user.email
                    }}
                >

                    <Form.Item
                        label="Geschlecht"
                        name="geschlecht"
                        rules={[{ required: true, message: 'Bitte eine Auswahl treffen!' }]}
                    >
                        <Select placeholder="Bitte auswählen">
                            <Select.Option value="männlich">Männlich</Select.Option>
                            <Select.Option value="weiblich">Weiblich</Select.Option>
                            <Select.Option value="unbestimmt">Unbestimmt</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Benutzername"
                        name="benutzername"
                        rules={[{ required: true, message: 'Bitte eine Auswahl treffen!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Vorname"
                        name="vorname"
                        rules={[{ required: true, message: 'Bitte Vorname eingeben!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Nachname"
                        name="nachname"
                        rules={[{ required: true, message: 'Bitte Nachname eingeben!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Addresse"
                        name="addresse"
                        rules={[{ required: true, message: 'Bitte eine Auswahl treffen!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="E-Mail Addresse"
                        name="email"
                        rules={[{ required: true, message: 'Bitte E-Mail eingeben!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item >
                        <Button style={{ backgroundColor: "#25D2AA", color: "white", height: "45px", fontSize: 18 }} shape="round" htmlType="submit" block>
                            Speichern
                        </Button>
                        <div class="popup">
                            <span class="popuptext" id="myPopupconfirm">Profil aktualisiert!</span>
                        </div>
                    </Form.Item>
                </Form>
            </Paper>
        );
    }

}

export default Profil
