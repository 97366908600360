import React from "react"
import { Layout, Menu, Button, Avatar, List, Dropdown, Form, Image } from 'antd';
import { UserOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import Divider from '@material-ui/core/Divider';
import Screenshot from "./Screenshot.png"
import TerminVereinbaren from "./TerminVereinbaren"
import DatabaseService from "../../services/database.service"
import profile from "../../Profile_Pic.png";
import { ButtonBase } from "@material-ui/core";
// https://github.com/prettymuchbryce/http-status-codes
import { StatusCodes as HttpStatus } from 'http-status-codes';

class ProfilePage extends React.Component {

    constructor(props) {
        super(props);
        console.log("ProfilePage.js: props: ", this.props);
        this.state = {
            fileList: [],
            clinicInfo: {},
            leistungsspektrum: [],
            oeffnungszeiten: [],
            usrDataLoaded: false
        }

        DatabaseService.vet.profile
            .readOne(this.props.doctor)
            .then(response => {
                console.log("ProfilePage.js: vet.profile.readOne() response: ", response);
                if (response.status === HttpStatus.OK) {
                    this.setState({
                        doctor: response.data,
                        fileList: this.parsePicturesJson(response.data.vetPictures),
                        name: response.data.name,
                        about: response.data.about,
                        leistungsspektrum: this.parseObj(response.data.leistungsspektrum),
                        website: response.data.website,
                        zugangsinformationen: response.data.zugangsinformationen,
                        behandlerprofil: response.data.behandlerprofil,
                        oeffnungszeiten: this.parseJson(response.data.oeffnungszeiten),
                        sprachenString: response.data.sprachen,
                        profilePic: response.data.profilePic,
                        about: response.data.about,
                        website: response.data.website,
                        zugangsinformationen: response.data.zugangsinformationen,
                        behandlerprofil: response.data.behandlerprofil,
                        sprachenString: response.data.sprachen,
                        addresse: response.data.addresse,
                        usrDataLoaded: true,
                        ausbildung: response.data.ausbildung,
                        fachgebiet: response.data.fachgebiet
                    })

                    DatabaseService.clinic.profile
                        .readOne(response.data.clinicID)
                        .then(response => {
                            console.log("ProfilePage.js: clinic.profile.readOne() response: ", response);
                            if (response.status === HttpStatus.OK) {
                                this.setState({
                                    clinicInfo: response.data
                                })
                            }
                        })
                        .catch(e => {
                            console.log("ProfilePage.js: clinic.profile.readOne() e: ", e);
                        });

                }
            })
            .catch(e => {
                console.log("ProfilePage.js: vet.profile.readOne() e: ", e);
            });
    }

    parseObj(data) {
        if (typeof data !== "undefined" && data != null) {
            var str = data.split("&quot;");
            var output = [];

            for(let i=1;i<str.length;i=i+2) {
                output.push(str[i]);
            }

            return output;
        }

        return [];
    }

    parseJson(value) {
        try {
            return JSON.parse(value)
        }
        catch (error) {
            return [];
        }
    }

    parsePicturesJson(value) {
        try {
            const parsedJson = JSON.parse(value);
            return parsedJson.vetPictures;
        }
        catch (error) {
            return [];
        }
    }

    detectMob() {
        return ( ( window.innerWidth <= 800 ) && ( window.innerHeight <= 1000 ) );
    }

    render() {
        if (this.detectMob()) {
            return (
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", width: "100%", backgroundColor: "#fffcf7" }}>
                    {this.state.usrDataLoaded &&
                        <TerminVereinbaren leistungsspektrum={this.state.leistungsspektrum} doctor={this.state.doctor} user={this.props.user} animalInfo={this.props.animalInfo} pet={this.props.currentPet} />
                    }
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "stretch", width: "100%", marginRight: 10 }}>

                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", padding: 5, margin: 10 }}>
                            <Avatar size={60} src={this.state.profilePic ? this.state.profilePic : profile} />
                            <div style={{ display: "flex", marginLeft: 20, flexDirection: "column" }}>
                                <span style={{ fontWeight: "bold", fontSize: 20 }}>{this.state.name}</span>
                                <span style={{ fontWeight: "bold", marginTop: 7 }}>{this.state.ausbildung} für {this.state.fachgebiet}</span>
                                <span style={{ color: "grey", marginTop: 7 }}>{this.state.addresse}</span>
                                <span style={{ color: "grey", width: "100%", marginTop: 14 }}>{this.state.about}</span>
                            </div>
                        </div>
                        <Divider />
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", padding: 25, margin: 30 }}>
                            <span style={{ fontWeight: "bold", fontSize: 20 }}>Leistungsspektrum</span>
                            <span style={{ color: "grey", marginTop: 7 }}>{this.state.leistungsspektrum && this.state.leistungsspektrum.map( (elem, idx) => {
                                if (idx < this.state.leistungsspektrum.length-1) return <span>{elem}, </span>
                                else return <span>{elem}</span>
                            })}</span>
                        </div>
                        <Divider />
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", padding: 25, margin: 30 }}>
                            <span style={{ fontWeight: "bold", fontSize: 20 }}>Addresse und Zugangsinformation</span>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", marginTop: 15 }}>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                    <span style={{ fontWeight: "bold", marginTop: 20 }}>{this.state.clinicInfo.name}</span>
                                    <span style={{ color: "grey", marginTop: 7 }}>{this.state.clinicInfo.addresse}</span>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                                    <span style={{ fontWeight: "bold", marginTop: 20 }}>Zugangsinformationen</span>
                                    <span style={{ color: "grey", marginTop: 7 }}>{this.state.zugangsinformationen}</span>
                                </div>
                            </div>
                        </div>
                        <Divider />
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", padding: 25, margin: 30 }}>
                            <span style={{ fontWeight: "bold", fontSize: 20 }}>Behandlerprofil</span>
                            <span style={{ width: "100%", marginTop: 7 }}>{this.state.behandlerprofil} </span>
                            <div style={{ display: "flex", flexDirection: "row", marginBottom: 15 }}>
                                {this.state.fileList.map(image => {
                                    return (<Image
                                        style={{ margin: 10 }}
                                        height={100}
                                        src={image.url}
                                    />);
                                })}
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", marginTop: 15 }}>
                                <div style={{ display: "flex", flexDirection: "column", width: "20vw" }}>
                                    <span style={{ fontWeight: "bold", marginTop: 7 }}>Gesprochene Sprachen</span>
                                    <span style={{ marginTop: 7 }}>{this.state.sprachenString}</span>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", marginTop: 15 }}>
                                    <span style={{ fontWeight: "bold", marginTop: 7 }}>Website</span>
                                    <span style={{ width: "100%", marginTop: 7, color: "#25D2AA" }}>Website öffnen</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", width: "100%", backgroundColor: "#fffcf7" }}>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "stretch", width: "70%", marginRight: 20 }}>

                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", padding: 25, margin: 30 }}>
                        <Avatar size={150} src={this.state.profilePic ? this.state.profilePic : profile} />
                        <div style={{ display: "flex", marginLeft: 20, flexDirection: "column" }}>
                            <span style={{ fontWeight: "bold", fontSize: 20 }}>{this.state.name}</span>
                            <span style={{ fontWeight: "bold", marginTop: 7 }}>{this.state.ausbildung} für {this.state.fachgebiet}</span>
                            <span style={{ color: "grey", marginTop: 7 }}>{this.state.addresse}</span>
                            <span style={{ color: "grey", width: "500px", marginTop: 14 }}>{this.state.about}</span>
                        </div>
                    </div>
                    <Divider />
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", padding: 25, margin: 30 }}>
                        <span style={{ fontWeight: "bold", fontSize: 20 }}>Leistungsspektrum</span>
                        <span style={{ color: "grey", marginTop: 7 }}>{this.state.leistungsspektrum && this.state.leistungsspektrum.map( (elem, idx) => {
                            if (idx < this.state.leistungsspektrum.length-1) return <span>{elem}, </span>
                            else return <span>{elem}</span>
                        })}</span>
                    </div>
                    <Divider />
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", padding: 25, margin: 30 }}>
                        <span style={{ fontWeight: "bold", fontSize: 20 }}>Addresse und Zugangsinformation</span>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", marginTop: 15 }}>
                            <ButtonBase onClick={() => this.props.showClinic(this.state.clinicInfo.clinicID)}>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                    <span style={{ fontWeight: "bold", marginTop: 20 }}>{this.state.clinicInfo.name}</span>
                                    <span style={{ color: "grey", marginTop: 7 }}>{this.state.clinicInfo.addresse}</span>
                                </div>
                            </ButtonBase>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginLeft: 70 }}>
                                <span style={{ fontWeight: "bold", marginTop: 20 }}>Zugangsinformationen</span>
                                <span style={{ color: "grey", marginTop: 7 }}>{this.state.zugangsinformationen}</span>
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", padding: 25, margin: 30 }}>
                        <span style={{ fontWeight: "bold", fontSize: 20 }}>Behandlerprofil</span>
                        <span style={{ width: "500px", marginTop: 7 }}>{this.state.behandlerprofil} </span>
                        <div style={{ display: "flex", flexDirection: "row", marginBottom: 15 }}>
                            {this.state.fileList.map(image => {
                                return (<Image
                                    style={{ margin: 10 }}
                                    height={100}
                                    src={image.url}
                                />);
                            })}
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", marginTop: 15 }}>
                            <div style={{ display: "flex", flexDirection: "column", width: "20vw" }}>
                                <span style={{ fontWeight: "bold", marginTop: 7 }}>Gesprochene Sprachen</span>
                                <span style={{ marginTop: 7 }}>{this.state.sprachenString}</span>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <span style={{ fontWeight: "bold", marginTop: 7 }}>Website</span>
                                <span style={{ width: "500px", marginTop: 7, color: "#25D2AA" }}>Website öffnen</span>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.usrDataLoaded &&
                    <TerminVereinbaren leistungsspektrum={this.state.leistungsspektrum} doctor={this.state.doctor} user={this.props.user} animalInfo={this.props.animalInfo} pet={this.props.currentPet} />
                }
            </div>
        );
    }
}

export default ProfilePage
