import Event from "./animalOwnerEvent";

class AnimalOwner {
    /**
     * AnimalOwner Action Class
     */
    constructor(axios) {
        this.http = axios;
        this._event = new Event(axios);
    }


    /**
     * Owner Event Service
     */
    get event() {
        return this._event;
    }

    
    /**
     * Create one owner
     * @param {Object} data 
     * @returns Promise object
     */
    create(data) {
        return this.http.post("/api/userbase/create.php", data);
    };
    /**
     * Read all owners
     * @param {Array} idList list of owner id's
     * @returns Promise object
     */
    read(idList) {
        return Promise.reject('NOT_SUPPORTED');
    };
    /**
     * Read one owner
     * @param {string} id owner id
     * @returns Promise object
     */
    readOne(id) {
        return this.http.get(`/api/userbase/read_by_index.php?id=${id}`);
    };
    /**
     * Update one owner
     * @param {Object} data 
     * @returns Promise object
     */
    update(data) {
        return this.http.patch("/api/userbase/update.php", data);
    };
    /**
     * Delete one owner
     * @param {string} id of an owner 
     * @returns Promise object
     */
    delete(id) {
        return this.http.delete(`/api/userbase/delete.php?id=${id}`);
    };
    /**
     * Read all animal belongs to the owner
     * @param {string} ownerId owner id
     * @returns Promise object
     */
    readAllAnimals(ownerId) {
        return this.http.get(`/api/animalbase/read_by_index.php?id=${ownerId}`);
    };
    /**
     * Check owner email
     * @param {string} id owner id
     * @returns Promise object
     */
     checkEmail(id) {
        return this.http.get(`/api/userbase/check_email.php?mail=${id}`);
    };
    /**
     * Auth owner
     * @param {Object} data 
     * @returns Promise object
     */
    auth(data) {
        return this.http.get(`/api/userbase/auth.php?mail=${data.email}&passwort=${data.passwort}`);
    };
    /**
     * Update owner avatar
     * @param {Object} data 
     * @returns Promise object
     */
    updateAvatar(data) {
        return this.http.patch("/api/userbase/update_avatar.php", data);
    };

}

export default AnimalOwner;