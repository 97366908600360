import React from "react"
import Paper from '@material-ui/core/Paper';
import { Layout, InputNumber, Button, Avatar, List, Form, Popover, Input } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import Divider from '@material-ui/core/Divider';
import { motion, AnimateSharedLayout, AnimatePresence } from "framer-motion";
import { SearchOutlined } from '@ant-design/icons';
import TerminVereinbaren from "./TerminVereinbaren"
import ClinicPic from "../assets/building.svg"
import profile from "../../Profile_Pic.png";
import DatabaseService from "../../services/database.service";
import GeocodingService from '@mapbox/mapbox-sdk/services/geocoding';
import Map from "./Map"

const spring = {
    type: "spring",
    stiffness: 500,
    damping: 30
};

class Results extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showAppointment: false,
            selectedDoctor: {},
            markers: [],
            currentHeaderKey: 'arzt',
            doctors: [],
            clinics: [],
            geocodingClient: new GeocodingService({ accessToken: 'pk.eyJ1IjoiZG9taW5pay1jYXYiLCJhIjoiY2tsMWNlZW1rMHVhMTJwcGVkaXo0YnpucCJ9.implwE-8ZbHzk49FRRZGDw' }),
            location: this.props.location,
            popoverVisible: false,
            radius: 25,
            coords: {},
            initialDataFetched: false,
            searchQuery: ""
        }
    }

    componentDidMount() {
        this.state.geocodingClient.forwardGeocode({
            query: this.state.location,
            countries: ['de'],
            limit: 2
        })
            .send()
            .then(response => {
                const coordinates = { lng: response.body.features[0].center[0], lat: response.body.features[0].center[1] };
                this.setState({ coords: coordinates });
                this.searchVets({ ...coordinates, query: "" });
            });
    }


    changeToDoctors = () => {
        this.setState({ currentHeaderKey: 'arzt' });
        this.searchVets({ ...this.state.coords, query: this.state.searchQuery });
    }

    changeToClinics = () => {
        this.setState({ currentHeaderKey: 'klinik' });
        this.searchClinics({ ...this.state.coords, query: this.state.searchQuery });
    }

    searchQueryChanged = ({ target: { value } }) => {
        this.setState({ searchQuery: value })
        if (this.state.currentHeaderKey === 'arzt')
            this.searchVets({ ...this.state.coords, query: value });
        else
            this.searchClinics({ ...this.state.coords, query: value });
    }

    searchChanged = (values) => {
        this.setState({ location: values.postleitzahl, radius: values.radius, popoverVisible: false }, () => {
            this.state.geocodingClient.forwardGeocode({
                query: this.state.location,
                countries: ['de'],
                limit: 2
            })
                .send()
                .then(response => {
                    const coordinates = { lng: response.body.features[0].center[0], lat: response.body.features[0].center[1] };
                    this.setState({ coords: coordinates });

                    if (this.state.currentHeaderKey === 'arzt')
                        this.searchVets({ ...coordinates, query: this.state.searchQuery });
                    else
                        this.searchClinics({ ...coordinates, query: this.state.searchQuery });
                });
        });
    }

    searchVets = data => {
        DatabaseService.vet.profile
            .search({ ...data, radius: this.state.radius })
            .then(response => {
                if (!response.data.records)
                    this.setState({ doctors: [] });
                else {
                    const doctors = response.data.records.map(elem => {
                        return [elem.lng, elem.lat];
                    });
                    this.setState({ doctors: response.data.records, markers: doctors, initialDataFetched: true });
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    searchClinics = data => {
        DatabaseService.clinic.profile
            .search({ ...data, radius: this.state.radius })
            .then(response => {
                if (!response.data.records)
                    this.setState({ clinics: [] });
                else {
                    const clinics = response.data.records.map(elem => {
                        return [elem.lng, elem.lat];
                    });
                    this.setState({ clinics: response.data.records, markers: clinics, initialDataFetched: true });
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    detectMob() {
        return ( ( window.innerWidth <= 800 ) && ( window.innerHeight <= 1000 ) );
    }

    render() {
        if (!this.state.initialDataFetched)
            return (
                <div />
            )

        const menu = (
            <Form
                layout="vertical"
                onFinish={this.searchChanged}
                initialValues={{
                    postleitzahl: this.state.location,
                    radius: this.state.radius
                }}
            >
                <Form.Item label="Postleitzahl" name="postleitzahl">
                    <Input />
                </Form.Item>
                <Form.Item label="Umkreis" name="radius">
                    <InputNumber />
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                    <Button size="large" style={{ backgroundColor: "#25D2AA", color: "white" }} shape="round" htmlType="submit" block>
                        Speichern
                    </Button>
                </Form.Item>
            </Form>
        );

        if (this.detectMob()) {
            return (
                <div style={{ display: "flex", flexDirection: "column", padding: 10 }}>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", width: "100%" }}>
                        <Input onChange={this.searchQueryChanged} style={{ borderRadius: "30px", width: "50%", marginTop: 20, marginBottom: 15 }} size="large" placeholder="Suche" prefix={<SearchOutlined />} />
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%", fontSize: 22 }}>
                            <AnimateSharedLayout>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", marginTop: 10 }}>
                                    <span style={{ fontWeight: "bold" }} onClick={() => this.changeToDoctors()}>Ärzte</span>
                                    {this.state.currentHeaderKey === 'arzt' && <motion.div className="horizontalLineMenu" layoutId="marker" transition={spring} />}
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", marginTop: 10, marginLeft: 30 }}>
                                    <span style={{ fontWeight: "bold" }} onClick={() => this.changeToClinics()}>Kliniken</span>
                                    {this.state.currentHeaderKey === 'klinik' && <motion.div className="horizontalLineMenu" layoutId="marker" transition={spring} />}
                                </div>
                            </AnimateSharedLayout>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", width: "100%", marginTop: 20, marginBottom: 15 }}>
                            <span style={{ fontWeight: "bold", fontSize: 20, marginRight: 30 }}>{this.state.doctors.length} Tierarztpraxen im {this.state.radius}km Umkreis um {this.state.location}</span>
                            <Popover
                                content={menu}
                                trigger="click"
                                visible={this.state.popoverVisible}
                                onVisibleChange={(visible) => this.setState({ popoverVisible: visible })}
                            >
                                <Button size="large" style={{ borderColor: "black", color: "black", height: "40px", width: "100px" }} ghost shape="round">Ändern</Button>
                            </Popover>
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <AnimatePresence initial={false}>
                            {!this.state.showAppointment && (
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    style={{ width: "100%", padding: 30 }}
                                >
                                    <Map markers={this.state.markers} />
                                </motion.div>
                            )}
                            {this.state.showAppointment && (
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    style={{ width: "100%", padding: 30 }}
                                >
                                    <TerminVereinbaren doctor={this.state.selectedDoctor} />
                                </motion.div>
                            )}
                        </AnimatePresence>
                        {this.state.currentHeaderKey === 'arzt' ?
                            <List
                                itemLayout="horizontal"
                                style={{ width: "100%", height: "100%" }}
                                dataSource={this.state.doctors}
                                renderItem={item => (
                                    <List.Item style={{ width: "100%", height: "100%" }} >
                                        <Paper elevation={3} style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", width: "100%", height: "380px", padding: 25, margin: 10 }}>
                                            <Avatar size={96} src={item.profilePic ? item.profilePic : profile} />
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <span style={{ fontWeight: "bold", color: "#25D2AA" }}>{item.name}</span>
                                                <span style={{ fontWeight: "bold", marginTop: 7 }}>Facharzt für Hunde</span>
                                                <span style={{ color: "grey", marginTop: 7 }}>{item.addresse}</span>
                                            </div>
                                            <Divider variant="middle" style={{ marginTop: 10, marginBottom: 10 }} />
                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                <div style={{ height: "100%", width: "4px", backgroundColor: "#25D2AA", marginRight: 15 }} />
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <span style={{ fontWeight: "bold" }}>Nächster Termin:</span>
                                                    <span>Montag, 25. Januar 2021</span>
                                                </div>
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", marginTop: 20 }}>
                                                <Button size="large" style={{ borderColor: "black", color: "black", height: "50px", width: "200px" }} ghost shape="round" onClick={() => this.props.showProfile(item.vetID)}>Details anzeigen</Button>
                                                <Button size="large" style={{ backgroundColor: "#25D2AA", color: "white", height: "50px", width: "200px", marginTop: 10 }} shape="round" onClick={() => this.setState({ selectedDoctor: item, showAppointment: true })}>
                                                    + Termin vereinbaren
                                                </Button>
                                            </div>
                                        </Paper>
                                    </List.Item>
                                )}
                            />
                            :
                            <List
                                itemLayout="horizontal"
                                style={{ width: "100%", height: "100%" }}
                                dataSource={this.state.clinics}
                                renderItem={item => (
                                    <List.Item style={{ width: "100%", height: "100%" }} >
                                        <Paper elevation={3} style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", width: "500px", height: "380px", padding: 25, margin: 10 }}>
                                            <Avatar shape="square" size={86} src={item.profilePic ? item.profilePic : ClinicPic} />
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <span style={{ fontWeight: "bold", color: "#25D2AA" }}>{item.name}</span>
                                                <span style={{ fontWeight: "bold", marginTop: 7 }}>Facharzt für Hunde</span>
                                                <span style={{ color: "grey", marginTop: 7 }}>{item.addresse}</span>
                                            </div>
                                            <Divider variant="middle" style={{ marginTop: 10, marginBottom: 10 }} />
                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                <div style={{ height: "100%", width: "4px", backgroundColor: "#25D2AA", marginRight: 15 }} />
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <span style={{ fontWeight: "bold" }}>Nächster Termin:</span>
                                                    <span>Montag, 25. Januar 2021</span>
                                                </div>
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: 20 }}>
                                                <Button size="large" style={{ borderColor: "black", color: "black", height: "50px", width: "400px" }} ghost shape="round" onClick={() => this.props.showClinic(item.clinicID)}>Details anzeigen</Button>
                                                {/*<Button size="large" style={{ backgroundColor: "#25D2AA", color: "white", height: "50px", width: "200px" }} shape="round" onClick={() => this.setState({ selectedDoctor: item, showAppointment: true })}>
                                            + Termin vereinbaren
                                            </Button>*/}
                                            </div>
                                        </Paper>
                                    </List.Item>
                                )}
                            />
                        }
                    </div>
                </div>
            );
        }

        return (
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", width: "100%" }}>
                    <Input onChange={this.searchQueryChanged} style={{ borderRadius: "30px", width: "50%", marginTop: 20, marginBottom: 15 }} size="large" placeholder="Suche" prefix={<SearchOutlined />} />
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%", fontSize: 22 }}>
                        <AnimateSharedLayout>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", marginTop: 10 }}>
                                <span style={{ fontWeight: "bold" }} onClick={() => this.changeToDoctors()}>Ärzte</span>
                                {this.state.currentHeaderKey === 'arzt' && <motion.div className="horizontalLineMenu" layoutId="marker" transition={spring} />}
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", marginTop: 10, marginLeft: 30 }}>
                                <span style={{ fontWeight: "bold" }} onClick={() => this.changeToClinics()}>Kliniken</span>
                                {this.state.currentHeaderKey === 'klinik' && <motion.div className="horizontalLineMenu" layoutId="marker" transition={spring} />}
                            </div>
                        </AnimateSharedLayout>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", width: "100%", marginTop: 20, marginBottom: 15 }}>
                        <span style={{ fontWeight: "bold", fontSize: 20, marginRight: 30 }}>{this.state.doctors.length} Tierarztpraxen im {this.state.radius}km Umkreis um {this.state.location}</span>
                        <Popover
                            content={menu}
                            trigger="click"
                            visible={this.state.popoverVisible}
                            onVisibleChange={(visible) => this.setState({ popoverVisible: visible })}
                        >
                            <Button size="large" style={{ borderColor: "black", color: "black", height: "40px", width: "100px" }} ghost shape="round">Ändern</Button>
                        </Popover>
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    {this.state.currentHeaderKey === 'arzt' ?
                        <List
                            itemLayout="horizontal"
                            style={{ width: "100%", height: "100%" }}
                            dataSource={this.state.doctors}
                            renderItem={item => (
                                <List.Item style={{ width: "100%", height: "100%" }} >
                                    <Paper elevation={3} style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", width: "500px", height: "380px", padding: 25, margin: 10 }}>
                                        <Avatar size={96} src={item.profilePic ? item.profilePic : profile} />
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <span style={{ fontWeight: "bold", color: "#25D2AA" }}>{item.name}</span>
                                            <span style={{ fontWeight: "bold", marginTop: 7 }}>Facharzt für Hunde</span>
                                            <span style={{ color: "grey", marginTop: 7 }}>{item.addresse}</span>
                                        </div>
                                        <Divider variant="middle" style={{ marginTop: 10, marginBottom: 10 }} />
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <div style={{ height: "100%", width: "4px", backgroundColor: "#25D2AA", marginRight: 15 }} />
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <span style={{ fontWeight: "bold" }}>Nächster Termin:</span>
                                                <span>Montag, 25. Januar 2021</span>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 20 }}>
                                            <Button size="large" style={{ borderColor: "black", color: "black", height: "50px", width: "200px" }} ghost shape="round" onClick={() => this.props.showProfile(item.vetID)}>Details anzeigen</Button>
                                            <Button size="large" style={{ backgroundColor: "#25D2AA", color: "white", height: "50px", width: "200px" }} shape="round" onClick={() => this.setState({ selectedDoctor: item, showAppointment: true })}>
                                                + Termin vereinbaren
                                            </Button>
                                        </div>
                                    </Paper>
                                </List.Item>
                            )}
                        />
                        :
                        <List
                            itemLayout="horizontal"
                            style={{ width: "100%", height: "100%" }}
                            dataSource={this.state.clinics}
                            renderItem={item => (
                                <List.Item style={{ width: "100%", height: "100%" }} >
                                    <Paper elevation={3} style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", width: "500px", height: "380px", padding: 25, margin: 10 }}>
                                        <Avatar shape="square" size={86} src={item.profilePic ? item.profilePic : ClinicPic} />
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <span style={{ fontWeight: "bold", color: "#25D2AA" }}>{item.name}</span>
                                            <span style={{ fontWeight: "bold", marginTop: 7 }}>Facharzt für Hunde</span>
                                            <span style={{ color: "grey", marginTop: 7 }}>{item.addresse}</span>
                                        </div>
                                        <Divider variant="middle" style={{ marginTop: 10, marginBottom: 10 }} />
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <div style={{ height: "100%", width: "4px", backgroundColor: "#25D2AA", marginRight: 15 }} />
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <span style={{ fontWeight: "bold" }}>Nächster Termin:</span>
                                                <span>Montag, 25. Januar 2021</span>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: 20 }}>
                                            <Button size="large" style={{ borderColor: "black", color: "black", height: "50px", width: "400px" }} ghost shape="round" onClick={() => this.props.showClinic(item.clinicID)}>Details anzeigen</Button>
                                            {/*<Button size="large" style={{ backgroundColor: "#25D2AA", color: "white", height: "50px", width: "200px" }} shape="round" onClick={() => this.setState({ selectedDoctor: item, showAppointment: true })}>
                                        + Termin vereinbaren
                                        </Button>*/}
                                        </div>
                                    </Paper>
                                </List.Item>
                            )}
                        />
                    }

                    <AnimatePresence initial={false}>
                        {!this.state.showAppointment && (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                style={{ width: "100%", padding: 30 }}
                            >
                                <Map markers={this.state.markers} />
                            </motion.div>
                        )}
                        {this.state.showAppointment && (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                style={{ width: "100%", padding: 30 }}
                            >
                                <TerminVereinbaren doctor={this.state.selectedDoctor} />
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
            </div>
        );
    }


}

export default Results
