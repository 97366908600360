import React from 'react';
import { Layout, Button, Form, Input, Checkbox } from 'antd';
import MainSearchBar from "./new-dashboard/MainSearchBar"
import Pfote from "./Pfote.png";
import "./LogIn.css"
import { ButtonBase, Paper } from '@material-ui/core';
import BackgroundImage from "./Volna4k-Edit.png"
import { NavLink, Link } from "react-router-dom";
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Redirect } from "react-router-dom";
import store from 'store'
import isLoggedIn from '../is_logged_in';
import DatabaseService from "../services/database.service";

const { Header, Content, Footer } = Layout;

class LogIn extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {}
        };
    }

    handleHeaderClick = e => {
        console.log(e.key);
    };

    onFinish = values => {
        console.log('Received values of form: ', values);
        var data = {
            email: values.email,
            passwort: values.password,
        };

        DatabaseService.animalOwner
            .auth(data)
            .then(response => {
                if (!response.data.checkStatus) {
                    return this.setState({ errors: { loginFailed: true } });
                }
                else {
                    store.set('loggedIn', true);
                    store.set('usrId', response.data.id);
                    store.set('redirectPage', "/dashboard");
                    this.setState({ submitted: true });
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    render() {

        if (isLoggedIn()) {
            return (<Redirect to={store.get("redirectPage")} />);
        }
        var errorMsg;
        if (this.state.errors.loginFailed) {
            errorMsg = (
                <p style={{ color: "red" }}>
                    Falsche E-Mail oder Passwort
                </p>
            );
        }
        else {
            errorMsg = (null);
        }
        return (
            <Layout className="layout" style={{ height: "100vh", backgroundImage: `url(${BackgroundImage})` }}>
                <Header className="header" style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Link to="/">
                        <img src={Pfote} width="65" height="65" alt="" loading="eager" />
                    </Link>
                    <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Link to="/Register">
                            <Button style={{ borderColor: "#F66054", backgroundColor: "#F66054", color: "white" }}>Jetzt Registrieren</Button>
                        </Link>
                    </div>
                </Header>
                <Content>
                    <div className="sitelayoutcontent">
                        <Form
                            name="normal_login"
                            className="login-form"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={this.onFinish}
                            size="large"
                        >
                            {errorMsg}
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte E-mail eingeben!',
                                    },
                                ]}
                            >
                                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="E-mail" />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte Passwort eingeben!',
                                    },
                                ]}
                            >
                                <Input.Password
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                    placeholder="Passwort"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Form.Item name="remember" valuePropName="checked" noStyle>
                                    <Checkbox>Angemeldet bleiben</Checkbox>
                                </Form.Item>

                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="login-form-button" block>
                                    Log in
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Content>
                <Footer style={{ height: "15px", display: "flex", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <NavLink className="nav-item nav-link" to="/team">
                        Team
                    </NavLink>
                    <NavLink className="nav-item nav-link" to="/contact">
                        Kontakt
                    </NavLink>
                    <NavLink className="nav-item nav-link" to="/news">
                        News
                    </NavLink>
                    <NavLink className="nav-item nav-link" to="/impressum">
                        Impressum
                    </NavLink>
                </Footer>
            </Layout>
        );
    }
}

export default LogIn;
