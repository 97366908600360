import React from "react";
import "./App.css";
import { Route, Switch } from "react-router-dom";
import JitsiComp from "./JitsiComp";
import Dashboard from "./nicos-design/nicos-dashboard/MainDashboard"
import { BrowserView, MobileView } from "react-device-detect";
import StartScreenMobile from "./components-redesign-mobile/StartScreen"
import DashboardMobile from "./components-redesign-mobile/new-dashboard/Dashboard"
import LogInMobile from "./components-redesign-mobile/LogIn"
import RegisterMobile from "./components-redesign-mobile/Register"
import Anmelden from "./nicos-design-mobile/Anmelden"
import Registrieren from "./nicos-design/Registrieren"
import UserSearch from "./nicos-design/UserSearch"
import ValidationRequired from "./nicos-design/ValidationReqired"
import './landing-page/AppRealease.css';
import UserHome from './landing-page/UserHomeRelease'
import UserHomeMobile from "./landing-page/UserHomeMobile"
import SoFunktionierts from './landing-page/SoFunktionierts'
import Fragen from "./landing-page/Fragen"
import Impressum from "./landing-page/Impressum"
import Datenschutz from "./landing-page/Datenschutz"
import Partner from "./landing-page/Partner"

function App() {
    return (
        <React.Fragment>
            <Switch>
                <Route path="/videocall">
                    <JitsiComp />
                </Route>
                <Route path="/dashboard">
                    <Dashboard />
                </Route>
                <Route path="/login" component={Anmelden} />
                <Route path="/search" component={UserSearch} />
                <Route path="/register" component={Registrieren} />
                <Route path="/validation-required" component={ValidationRequired} />

                <Route path="/datenschutz">
                    <Datenschutz />
                </Route>

                <Route path="/impressum">
                    <Impressum />
                </Route>

                <Route path="/manual">
                    <SoFunktionierts />
                </Route>

                <Route path="/qa">
                    <Fragen />
                </Route>

                <Route path="/newsletter">
                    <Anmelden />
                </Route>

                <Route path="/partner">
                    <Partner />
                </Route>

                <Route path="/">
                    <BrowserView>
                        <UserHome />
                    </BrowserView>

                    <MobileView>
                        <UserHomeMobile />
                    </MobileView>
                </Route>
            </Switch>

        </React.Fragment>
    );
}

export default App;
