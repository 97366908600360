import React, { Component } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import {  Form, Input, Button, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

class Settings extends Component {


  layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };


  render(){

    return(
        <div style={{display:"flex", flexDirection: 'column', alignItems: 'center', overflow: 'hidden'}}>
          <Avatar size={96} style={{marginBottom: 25 }} icon={<UserOutlined />} />
          <Form {...this.layout} name="nest-messages">
            <Form.Item name= 'vorname'  label="Vorname">
              <Input />
            </Form.Item>
            <Form.Item name= 'nachname'  label="Nachname">
              <Input />
            </Form.Item>
            <Form.Item name='username' label="Username">
              <Input />
            </Form.Item>
            <Form.Item label="Passwort" name="password">
              <Input.Password />
            </Form.Item>
            <Form.Item wrapperCol={{ ...this.layout.wrapperCol, offset: 8 }}>
              <Button type="primary" htmlType="submit">
                Update
                </Button>
            </Form.Item>
          </Form>
        </div>
    );

  }


}

export default Settings;
