
class EventAction {
    /**
     * Event Action Class
     */
    constructor(axios) {
        this.http = axios;        
    }


    /**
     * Create one event
     * @param {Object} data 
     * @returns Promise object
     */
    create(data) {
        return this.http.post("/api/events/create.php", data);
    };
    /**
     * Read all events
     * @param {string} ownerId owner id
     * @returns Promise object
     */
    read(ownerId) {
        return this.http.get(`/api/events/read_by_index_user.php?id=${ownerId}`);
    };
    /**
     * Read one event
     * @param {Object} id event id
     * @returns Promise object
     */
    readOne(id) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Update one event
     * @param {Object} data
     * @returns Promise object
     */
    update(data) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Delete one event
     * @param {String} name event name
     * @returns Promise object
     */
    delete(name) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
}

export default EventAction;