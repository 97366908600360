
class NotificationAction {
    /**
     * Notification Action Class
     */
    constructor(axios) {
        this.http = axios;        
    }
    
    
    /**
     * Create one notification
     * @param {Object} data 
     * @returns Promise object
     */
    create(data) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Read all notifications
     * @param {Object} ownerId owner id
     * @returns Promise object
     */
    read(ownerId) {
        return this.http.get(`/api/notifications/read_by_index.php?id=${ownerId}`);
    };
    /**
     * Read one notification
     * @param {Object} id notification id
     * @returns Promise object
     */
    readOne(id) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Update one notification - NOT_IMPLEMENTED!
     * @param {Object} data
     * @returns Promise object
     */
    update(data) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Delete one notification - NOT_IMPLEMENTED!
     * @param {Object} id notification id
     * @returns Promise object
     */
    delete(id) {
        return Promise.reject('NOT_IMPLEMENTED');
    };


}

export default NotificationAction;