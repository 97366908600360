import React, { Component } from 'react';
import { Progress } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./uploadArea.css"
import store from 'store'
import { Upload, message, Button } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import http from "../../http-node-service"
import DatabaseService from "../../services/database.service"
import { StatusCodes as HttpStatus } from 'http-status-codes';
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';


const { Dragger } = Upload;
const props = {
  name: 'file',
  multiple: true,
  //action: `http://localhost:8080/uploadFile/${store.get('usrId')}`,
  //action: process.env.REACT_APP_HTTP_S3_BASE_URL + `/uploadFile/${store.get('usrId')}`,
  listType: "picture"
  /*
  onChange(info) {
    const { status } = info.file;
    if (status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  }
  */
};

class UploadArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      loaded: 0,
      uploaderId: store.get('usrId'),
      fileList: [],
      filekeys: [],
      menu: {},
      selectedItem: "Bitte Tierarzt auswählen",
      selectedVetID: {},
      uuid:{},
      disableDragger: true
    }

    this.loadData();
  }

  async loadData() {
    await http.get("/getFileList/"+ store.get('usrId'))
      .then(response => {
        const fileList = response.data.Contents.map( file => {
          const parts=file.Key.split("!");
          return {
            uid: -1,
            name: parts.pop(),
            url: process.env.REACT_APP_HTTP_S3_USER_FILES + "/" + file.Key
          }
        })
        this.setState({fileList:fileList})
      })
      .catch( e => console.log(e));

    await DatabaseService.vet.profile
      .read()
      .then(response => {
        console.log(response);
        if(response.status === HttpStatus.OK) {
          response.data.records.sort((a, b) => {
            let fa = a.name.toLowerCase(),
                fb = b.name.toLowerCase();

            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        });

          const menus = response.data.records.map(vet => {
            return (
              <Menu.Item value={vet.name} key={vet.vetID} onClick={ this.onClick }>
                {vet.name}
              </Menu.Item>
            )
          });
          const menu = () => {
            return (
              <Menu>
                {menus}
              </Menu>
            )
          }

          this.setState({menu: menu});
        }
      })
  }

  onClick = (event) => {
    console.log(event);

    this.setState({
      selectedItem: event.item.props.value,
      selectedVetID: event.key,
      disableDragger: false
    });
  }

  uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }

  detectMob() {
    return ( ( window.innerWidth <= 800 ) && ( window.innerHeight <= 1000 ) );
  }

  render() {
    if (this.detectMob()) {
      return (
        <div style={{display:"flex", flexDirection:"column", alignItems:"center", width:"100%", padding: 25}}>
          <h1 style={{ fontSize: 25, padding: 10 }}>Unterlagen hinzufügen</h1>
          <Dropdown overlay={this.state.menu} trigger={['click']}>
            <a id="dropdown" className="ant-dropdown-link" onClick={e => e.preventDefault()}>
              {this.state.selectedItem} <DownOutlined />
            </a>
          </Dropdown>
          <fieldset disabled={this.state.disableDragger}>
            <Dragger {...props} fileList={this.state.fileList}
                                style={{ marginTop: 20, maxHeight: 170 }}
                                onChange={async (info) => {
                                  const { status } = info.file;
                                  if (status !== 'uploading') {
                                    console.log(info.file, info.fileList);
                                  }
                                  if (status === 'done') {
                                    await DatabaseService.documents
                                    .create({
                                        filekey: this.state.uuid,
                                        vetID: this.state.selectedVetID
                                    })
                                    .then(response => {
                                        console.log("UserProfileModal.js: documents.create() response ", response);
                                        if(response.status === HttpStatus.CREATED) {
                                            console.log("UserProfileModal.js: documents.create(): Document created.");
                                        }
                                    })
                                    .catch(e =>
                                        console.log(e)
                                    );

                                    this.loadData();
                                    this.render();

                                    message.success(`${info.file.name} erfolgreich hochgeladen.`);
                                  } else if (status === 'error') {
                                    message.error(`Dateiupload für ${info.file.name} fehlgeschlagen.`);
                                  }
                                }}
                                beforeUpload={(file) => {
                                      this.setState({ uuid: this.uuidv4() });
                                    }
                                }
                                action={process.env.REACT_APP_HTTP_S3_BASE_URL + `/uploadFile/` + store.get('usrId')  + "!" + this.state.uuid}
                                >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Zum Hochladen, diesen Bereich Anklicken oder Dateien hierher ziehen</p>
              <p className="ant-upload-hint">
                Sie können ein oder mehrere Dateien hochladen
          </p>
          </Dragger>
        </fieldset>
        </div>
      );
    }
    return (
        <div style={{display:"flex", flexDirection:"column", alignItems:"center", width:"100%"}}>
          <h1>Unterlagen hinzufügen</h1>
          <Dropdown overlay={this.state.menu} trigger={['click']}>
            <a id="dropdown" className="ant-dropdown-link" onClick={e => e.preventDefault()}>
              {this.state.selectedItem} <DownOutlined />
            </a>
          </Dropdown>
          <fieldset disabled={this.state.disableDragger}>
            <Dragger {...props} fileList={this.state.fileList}
                                style={{ marginTop: 30, maxHeight: 150 }}
                                onChange={(info) => {
                                  const { status } = info.file;
                                  if (status !== 'uploading') {
                                    console.log(info.file, info.fileList);
                                  }
                                  if (status === 'done') {
                                    DatabaseService.documents
                                    .create({
                                        filekey: this.state.uuid,
                                        vetID: this.state.selectedVetID
                                    })
                                    .then(response => {
                                        console.log("UserProfileModal.js: documents.create() response ", response);
                                        if(response.status === HttpStatus.CREATED) {
                                            console.log("UserProfileModal.js: documents.create(): Document created.");
                                        }
                                    })
                                    .catch(e =>
                                        console.log(e)
                                    );

                                    this.loadData();
                                    this.render();

                                    message.success(`${info.file.name} erfolgreich hochgeladen.`);
                                  } else if (status === 'error') {
                                    message.error(`Dateiupload für ${info.file.name} fehlgeschlagen.`);
                                  }
                                }}
                                beforeUpload={(file) => {
                                      this.setState({ uuid: this.uuidv4() });
                                    }
                                }
                                action={process.env.REACT_APP_HTTP_S3_BASE_URL + `/uploadFile/` + store.get('usrId')  + "!" + this.state.uuid}
                                >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Zum Hochladen, diesen Bereich Anklicken oder Dateien hierher ziehen</p>
              <p className="ant-upload-hint">
                Sie können ein oder mehrere Dateien hochladen
          </p>
          </Dragger>
        </fieldset>
        </div>
    );
  }
}

export default UploadArea;
