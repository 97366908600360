import React from 'react';
import './Dashboard.css';
import { Layout, Menu, Button, Card, Drawer, Divider } from 'antd';
import Pfote from "./Pfote.png";
import { ButtonBase } from '@material-ui/core';
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import CallDialog from "./callDialog"
import DatabaseService from "../../services/database.service"
import UploadService from "../../services/upload.service"
import store from 'store'
import { Link, Redirect } from "react-router-dom";
import SettingsDialog from "./SettingsDialog";
import Auswahl from "./Auswahl"
import Termin from "./Termin"
import MeineTermine from "./MeineTermine"
import Suche from "./Suche"
import TerminVereinbaren from "./TerminVereinbaren"
import Tiere from "./Tiere"
import VetUebersicht from "./VetUebersicht"
import Erinnerungen from "./Erinnerungen"
import AddAnimal from "./AddAnimal"
import UploadTile from "./UploadTile"
import { motion, AnimateSharedLayout } from "framer-motion";
import Settings from "./settings/Settings"
import ProfilePage from "../search-components/ProfilePage"
import PraxisProfile from "../search-components/PraxisProfile"
import Results from "../search-components/Results"
import VideocallComp from "../novics-jitsi-integration/CallComp"
import CallEndIcon from '@material-ui/icons/CallEnd';
import IconButton from '@material-ui/core/IconButton';
import AnimalDetails from "./AnimalDetails"
import Overview from "./Overview"
import { RepeatOneSharp } from '@material-ui/icons';
import { v4 as uuidv4 } from 'uuid';
import { cWebsocketService as WebsocketService } from "../../wss-socket";
// https://github.com/prettymuchbryce/http-status-codes
import { StatusCodes as HttpStatus } from 'http-status-codes';
import {isMobile} from 'react-device-detect';
import "./Menu.css"


const { Sider, Content, Footer } = Layout;
const { SubMenu } = Menu;
const spring = {
    type: "spring",
    stiffness: 500,
    damping: 30
};

class MainDashboardComp extends React.Component {
    constructor(props) {
        super(props);
        console.log("MainDashboard.js: props: ", this.props);
        this.state = {
            currentHeaderKey: 'uebersicht',
            currentLeftPane: 'uebersicht',
            currentRightPane: 'erinnerungen',
            currentPaneMobile: 'uebersicht',
            currentClinic: {},
            settingsDialog: false,
            doctorsCalling: false,
            callDeclined: false,
            nextEvent: {},
            userEvents: [],
            updateUserEvents: false,
            sessionDoctor: {},
            user: {},
            animalInfo: [],
            vets: [],
            currentDoctor: null,
            currentPet: null,
            eventsReady: false,
            petsReady: false,
            doctor: {},
            sessionName: "",
            visible: false
        };
    }


    componentDidMount() {
        this.updateEvents();

        this.connect();

        let pr = [];
        pr.push(new Promise((resolve, reject) => {
            DatabaseService.vet.profile
                .read()
                .then(response => {
                    if (response.status === HttpStatus.OK) {
                        this.setState({ vets: response.data.records });
                    }
                    resolve(true);
                })
                .catch(e => {
                    console.log(e);
                    reject(e);
                });
        })
        );

        pr.push(new Promise((resolve, reject) => {
            DatabaseService.animalOwner
                .readOne(store.get('usrId'))
                .then(response => {
                    if (response.status === HttpStatus.OK) {
                        this.setState({ user: response.data });
                        store.set('name', response.data.vorname + " " + response.data.nachname);
                    }
                    resolve(true);
                })
                .catch(e => {
                    console.log(e);
                    reject(e);
                });
        })
        );

        pr.push(new Promise((resolve, reject) => {
            DatabaseService.animal
                .read(parseInt(store.get('usrId'), 10))
                .then(response => {
                    console.log("MainDashboard.js: animal.read() ", JSON.parse(response.data));
                    if (response.status === HttpStatus.OK) {
                        this.setState({
                            animalInfo: JSON.parse(response.data),
                            petsReady: true
                        });
                    }
                    resolve(true);
                })
                .catch(e => {
                    console.log(e);
                    reject(e);
                });
        })
        );

        (async () => {
            await Promise
                .all(pr, x => {
                    console.log("MainDashboard.js: Promise.all()", x)
                })
                .then(res => {
                    console.log("MainDashboard.js: Promise.then()", res)
                })
                .catch(e => {
                    console.log("MainDashboard.js: Promise.catch()", e)
                })
                .finally(res => {
                    console.log("MainDashboard.js: Promise.finally()", res)
                })
        })();
        setInterval(async () => {
            this.updateEvents();
        }, 10000);
    }



    componentWillUnmount() {
        clearInterval(this.interval);
    }

    timeout = 250; // Initial timeout duration as a class variable

    /**
     * @function connect
     * This function establishes the connect with the websocket and also ensures constant reconnection if connection closes
     */
    connect = () => {
        if (this.state.ws) {
            this.state.ws.close();
        }
        const that = this; // cache the this
        // var ws = new WebSocket("wss://hv-websocket-server.herokuapp.com/" + store.get('usrId') + "?vet=false");
        // url format: ws://localhost:18082/134?vet=false

        var ws = (new WebsocketService(store.get('usrId') + "?vet=false")).Client;
        var connectInterval;

        // websocket onopen event listener
        ws.onopen = (s) => {
            console.log("MainDashboard.js: connect() websocket on open ", s);
            that.setState({ ws: ws });
            that.timeout = 250; // reset timer to 250 on open of websocket connection
            clearTimeout(connectInterval);

            ws.onmessage = function (event) {
                console.log("MainDashboard.js: connect() websocket onmessage ", event);
                var message;
                try {
                    message = JSON.parse(event.data);
                    if (message.method === "call-request") {
                        that.setState({ doctorsCalling: true, sessionName: message.message.sessionName, sessionDoctor: message.message.vetName });
                    }
                    else if (message.method === "call-confirmation") {
                    }
                }
                catch (e) {
                    console.log(e)
                }
            }

            that.interval = setInterval(() => {
                const { ws } = that.state;
                const request = {
                    method: "keep-alive",
                    data: "",
                    message: ""
                }
                try {
                    ws.send(JSON.stringify(request))
                } catch (error) {
                    console.log("MainDashboard.js: Websocket catch() ", error)
                }
            }, 10000);
        };

        // websocket onclose event listener
        ws.onclose = e => {
            let sec = Math.min(10000 / 1000, (that.timeout + that.timeout) / 1000);
            console.log(`MainDashboard.js: connect() on close. Reconnect will be attempted in ${sec} second.`, e.reason);
            that.timeout = that.timeout + that.timeout; //increment retry interval
            connectInterval = setTimeout(this.check, Math.min(10000, that.timeout)); //call check function after timeout
        };

        // websocket onerror event listener
        ws.onerror = err => {
            console.error("Socket encountered error: ", err.message, "Closing socket");
            ws.close();
        };

    };

    /**
     * utilited by the @function connect to check if the connection is close, if so attempts to reconnect
     */
    check = () => {
        const { ws } = this.state;
        if (!ws || ws.readyState == WebSocket.CLOSED) this.connect(); //check if websocket instance is closed, if so call `connect` function.
    };

    callUser = usrId => {
        const { ws } = this.state;
        const session = uuidv4().toLowerCase();
        this.setState({ usrID: usrId, sessionActive: true, sessionName: session });
        const request = {
            method: "call-request",
            destination: "user",
            data: usrId,
            message: session
        }
        try {
            ws.send(JSON.stringify(request)) //send data to the server
        } catch (error) {
            console.log(error) // catch error
        }
    }

    handleCallDialogClose() {
        this.setState({ doctorsCalling: false, callDeclined: true });
    }

    handleCallAccept() {
        if (this.detectMob()) {
            this.setState({ currentPaneMobile: "videocall", doctorsCalling: false });
        }else {
            this.setState({ currentLeftPane: "videocall", doctorsCalling: false });
        }
    }


    handleHeaderClick = e => {
        if (e.key === "uebersicht") {
            this.setState({ currentLeftPane: 'uebersicht' })
            this.setState({ currentRightPane: 'erinnerungen' })
        }
        else if (e.key === 'animals') {
            this.setState({ currentLeftPane: 'tiere' })
            this.setState({ currentRightPane: 'fileUpload' })
        }
        else if (e.key === 'calendar') {
            this.setState({ currentLeftPane: 'termine' })
            this.setState({ currentRightPane: 'erinnerungen' })
        }
        else if (e.key === "setting:logout") {
            store.remove('loggedIn');
        }
        else this.setState({ currentLeftPane: e.key });
        this.setState({ currentHeaderKey: e.key });
    };

    handleHeaderMobileClick = e => {
        console.log("e: " + e.key);

        switch (e.key) {
            case 'uebersicht':
                this.setState({ currentPaneMobile: 'uebersicht' });
                break;
            case 'animals':
                this.setState({ currentPaneMobile: 'tiere' });
                break;
            case 'animalDetails':
                this.setState({ currentPaneMobile: 'animalDetails' });
                break;
            case 'addAnimal':
                this.setState({ currentPaneMobile: 'addAnimal' });
                break;
            case 'calendar':
                this.setState({ currentPaneMobile: 'termine' });
                break;
            case 'termin':
                this.setState({ currentPaneMobile: 'termin' });
                break;
            case 'erinnerungen':
                this.setState({ currentPaneMobile: 'erinnerungen' })
                break;
            case 'suche':
                this.setState({ currentPaneMobile: 'suche' });
                break;
            case 'settings':
                this.setState({ currentPaneMobile: 'settings' });
                break;
            case 'fileUpload':
                this.setState({ currentPaneMobile: 'fileUpload' });
                break;
            case 'logout':
                this.setState({ currentPaneMobile: 'logout' });
                store.remove('loggedIn');
                break;
            default:
        }

        this.setState({ visible: false });
    };


    refreshPets = () => {
        const data = { id: parseInt(store.get('usrId'), 10) };
        DatabaseService.animal
            .read(data.id)
            .then(response => {
                if (response.status === HttpStatus.OK) {
                    console.log("MainDashboard.js: refreshPets() ", JSON.parse(response.data));
                    this.setState({ animalInfo: JSON.parse(response.data), petsReady: true });
                }
            })
            .catch(e => {
                console.log(e);
            });
    }

    refreshUser = () => {
        DatabaseService.animalOwner
            .readOne(store.get('usrId'))
            .then(response => {
                this.setState({ user: response.data });
                store.set('name', response.data.vorname + " " + response.data.nachname);
            })
            .catch(e => {
                console.log(e);
            });
    }

    handleSettingsClose = () => {
        this.setState({ settingsDialog: false })
    };

    showProfile = (current, currentPet) => {
        if (this.detectMob()) {
            this.setState({ doctor: current, currentPet: currentPet, currentPaneMobile: 'profile' });
        }else {
            this.setState({ doctor: current, currentPet: currentPet }, () => {
                this.setLeftPane('profile');
            });
        }
    }

    showClinic = (current, currentPet) => {
        this.setState({ currentClinic: current, currentPet: currentPet }, () => {
            this.setLeftPane('praxis');
        });
    }

    setRightPane = val => {
        this.setState({ currentRightPane: val })
    }

    setCurrentPet = val => {
        this.setState({ currentPet: val })
    }

    setLeftPane = val => {
        this.setState({ currentLeftPane: val })
    }

    setCurrentDoctor = val => {
        this.setState({ currentDoctor: val })
        this.setState({ doctor: this.state.vets.find(element => element.id === val) }, () => {
            console.log(this.state.doctor)
        })
    }

    updateEvents() {
        DatabaseService.event
            .read(store.get('usrId'))
            .then(response => {
                // console.log("MainDashboard.js: updateEvents() ", response);
                if (response.status === HttpStatus.OK) {
                    // DATA parse [in]
                    console.log("MainDashboard.js: updateEvents() ", JSON.parse(response.data));
                    const events = JSON.parse(response.data);
                    this.setState({
                        nextEvent: {
                            id: events[0].eventID,
                            confirmed: events[0].confirmed,
                            title: "Terminanfrage",
                            start: new Date(events[0].time),
                            end: new Date(new Date(events[0].time).getTime() + events[0].slotlength * 60000),
                            vetID: events[0].vetID,
                            userID: events[0].userID,
                            petId: events[0].petID
                        }
                    });
                    console.log("MainDashboard.js: updateEvents() events ", events, this.state.nextEvent);
                    var mappedData = events.map((singleEvent) => {
                        const now = new Date();
                        const mappedEvent = { ...singleEvent, title: Number(singleEvent.confirmed) ? "Bestätigt" : "Terminanfrage", start: new Date(singleEvent.time), end: new Date(new Date(singleEvent.time).getTime() + singleEvent.slotlength * 60000) };
                        const differenceNext = this.state.nextEvent.end.valueOf() - now.valueOf();
                        const differenceMap = mappedEvent.end.valueOf() - now.valueOf();
                        if (differenceNext < 0 || (differenceMap < differenceNext && differenceMap > 0)) {
                            this.setState({ nextEvent: mappedEvent });
                        }
                        return mappedEvent;
                    });
                    console.log("MainDashboard.js: updateEvents() userEvents ", mappedData);
                    this.setState({ userEvents: mappedData, eventsReady: true });
                } else {
                    // if (!response.data || !response.data[0]) {
                    this.setState({ eventsReady: true });
                    // return;
                    // }
                }

            })
            .then(response => {
            })
            .catch(e => {
                this.setState({ eventsReady: true });
                console.log("MainDashboard.js: updateEvents() Error ", e);
            });
    };



    /**
     * Event handler, Einstellungen haben sich geändert und State muss aktualisiert werden
     * @param {string} key Mögliche Varianten [ meinetermiene || meinetiere || einstellungen:profil ]
     * @param {object} data Ein KeyValue Object
     */
    onSettingsChanged = (key, data) => {
        console.log("MainDashboard.js: onSettingsChanged() ", key, data, this.state);

        if (key === 'meinetermiene') {
            // this.setState(state => {
            //     console.log("MainDashboard.js: onSettingsChanged() meinkonto state ", state);
            //     return state;
            // })
        } else if (key === 'meinetiere') {
            // this.setState(state => {
            //     for (const st in data) {
            //         console.log("MainDashboard.js: onSettingsChanged() meinprofil ", st, data[st]);
            //         state.vetProfile[st] = data[st];
            //     }
            //     console.log("MainDashboard.js: onSettingsChanged() meinprofil state ", state);
            //     return state;
            // })
        } else if (key === 'einstellungen:profil') {
            this.setState(state => {
                state.user.addresse = data.addresse;
                state.user.email = data.email;
                state.user.geschlecht = data.geschlecht;
                state.user.vorname = data.vorname;
                state.user.nachname = data.nachname;
                state.user.profilePic = data.profilePic;
                state.user.username = data.username;
                state.user.validated = data.validated;
                console.log("MainDashboard.js: onSettingsChanged() einstellungen state ", state);
                return state;
            })
        }

    }

    detectMob() {
        return ( ( window.innerWidth <= 800 ) && ( window.innerHeight <= 1000 ) );
    }

    showDrawer = () => {
        this.setState({
          visible: true,
        });
      };
    onClose = () => {
        this.setState({
          visible: false,
        });
      };

    render() {
        var currentContent;
        const localizer = momentLocalizer(moment);
        // console.log("MainDashboard.js: render() state ", this.state);

        const paneProps = {
            refreshPets: () => this.refreshPets(),
            refreshUser: () => this.refreshUser(),
            eventsReady: this.state.eventsReady,
            petsReady: this.state.petsReady,
            currentPet: this.state.currentPet,
            doctor: this.state.doctor,
            doctors: this.state.vets,
            clinic: this.state.currentClinic,
            location: "Irschenberg",
            showProfile: (currentID, currentPet) => this.showProfile(currentID, currentPet),
            showClinic: (currentID, currentPet) => this.showClinic(currentID, currentPet),
            setCurrentPet: val => this.setCurrentPet(val),
            setCurrentDoctor: val => this.setCurrentDoctor(val),
            animalInfo: this.state.animalInfo,
            currentDoctor: this.state.currentDoctor,
            setLeftPane: val => this.setLeftPane(val),
            setRightPane: val => this.setRightPane(val),
            handleHeaderMobileClick: e => this.handleHeaderMobileClick(e),
            vets: this.state.vets,
            events: this.state.userEvents,
            user: this.state.user,
            onChange: (key, data) => this.onSettingsChanged(key, data)
        }

        if (this.detectMob()) {
            return (
                <div style={{ display: "flex", flexDirection: "column" }}>
                <nav className="menuBar">
                    <div className="menuCon">
                        <Button className="barsMenu" type="primary" onClick={this.showDrawer}>
                            <span className="barsBtn"></span>
                        </Button>
                        <Drawer
                        title="Hauptmenü"
                        placement="right"
                        closable={false}
                        onClose={this.onClose}
                        visible={this.state.visible}
                        >
                        <Divider />
                        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                            {this.state.currentPaneMobile === 'uebersicht' ? (<motion.div className="verticalLineMenu" layoutId="marker" transition={spring} />) : (<div style={{ width: "26px" }} />)}
                            <ButtonBase onClick={() => this.handleHeaderMobileClick({ key: "uebersicht" })} style={{ border: "none" }}>
                                <span style={{ fontWeight: "bold", color: this.state.currentPaneMobile === 'uebersicht' ? "#25D2AA" : "black" }} >Übersicht</span>
                            </ButtonBase>
                        </div>
                        <Divider />
                        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginTop: 20 }}>
                            {this.state.currentPaneMobile === 'termine' ||
                             this.state.currentPaneMobile === 'termin' ||
                             this.state.currentPaneMobile === 'profile' ||
                             this.state.currentPaneMobile === 'suche' ? (<motion.div className="verticalLineMenu" layoutId="marker" transition={spring} />) : (<div style={{ width: "26px" }} />)}
                            <ButtonBase onClick={() => this.handleHeaderMobileClick({ key: "calendar" })} style={{ border: "none" }}>
                                <span style={{ fontWeight: "bold", color: this.state.currentPaneMobile === 'termine' ||
                                this.state.currentPaneMobile === 'termin' ||
                                this.state.currentPaneMobile === 'profile' ||
                                this.state.currentPaneMobile === 'suche' ? "#25D2AA" : "black" }} >Meine Termine</span>
                            </ButtonBase>
                        </div>
                        <Divider />
                        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                            {this.state.currentPaneMobile === 'erinnerungen' ? (<motion.div className="verticalLineMenu" layoutId="marker" transition={spring} />) : (<div style={{ width: "26px" }} />)}
                            <ButtonBase onClick={() => this.handleHeaderMobileClick({ key: "erinnerungen" })} style={{ border: "none" }}>
                                <span style={{ fontWeight: "bold", color: this.state.currentPaneMobile === 'erinnerungen' ? "#25D2AA" : "black" }} >Erinnerungen</span>
                            </ButtonBase>
                        </div>
                        <Divider />
                            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginTop: 20 }}>
                                {this.state.currentPaneMobile === 'tiere' ||
                                 this.state.currentPaneMobile === 'animalDetails' ||
                                 this.state.currentPaneMobile === 'addAnimal' ? (<motion.div className="verticalLineMenu" layoutId="marker" transition={spring} />) : (<div style={{ width: "26px" }} />)}
                                <ButtonBase onClick={() => this.handleHeaderMobileClick({ key: "animals" })} style={{ border: "none" }}>
                                    <span style={{ fontWeight: "bold", color: this.state.currentPaneMobile === 'tiere' ||
                                    this.state.currentPaneMobile === 'animalDetails' ||
                                    this.state.currentPaneMobile === 'addAnimal' ? "#25D2AA" : "black" }} >Meine Tiere</span>
                                </ButtonBase>
                            </div>
                        <Divider />
                            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginTop: 20 }}>
                                {this.state.currentPaneMobile === 'settings' ? (<motion.div className="verticalLineMenu" layoutId="marker" transition={spring} />) : (<div style={{ width: "26px" }} />)}
                                <ButtonBase onClick={() => this.handleHeaderMobileClick({ key: "settings" })} style={{ border: "none" }}>
                                    <span style={{ fontWeight: "bold", color: this.state.currentPaneMobile === 'settings' ? "#25D2AA" : "black" }} >Einstellungen</span>
                                </ButtonBase>
                            </div>
                        <Divider />
                            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginTop: 20 }}>
                                {this.state.currentPaneMobile === 'fileUpload' ? (<motion.div className="verticalLineMenu" layoutId="marker" transition={spring} />) : (<div style={{ width: "26px" }} />)}
                                <ButtonBase onClick={() => this.handleHeaderMobileClick({ key: "fileUpload" })} style={{ border: "none" }}>
                                    <span style={{ fontWeight: "bold", color: this.state.currentPaneMobile === 'fileUpload' ? "#25D2AA" : "black" }} >Unterlagen</span>
                                </ButtonBase>
                            </div>
                        <Divider />
                            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginTop: 20 }}>
                                {this.state.currentPaneMobile === 'logout' ? (<motion.div className="verticalLineMenu" layoutId="marker" transition={spring} />) : (<div style={{ width: "26px" }} />)}
                                <Link to="/">
                                    <ButtonBase onClick={() => this.handleHeaderMobileClick({ key: "logout" })} style={{ border: "none" }}>
                                        <span style={{ fontWeight: "bold", color: this.state.currentPaneMobile === 'logout' ? "#25D2AA" : "black" }} >Logout</span>
                                    </ButtonBase>
                                </Link>
                            </div>
                        <Divider />
                        </Drawer>
                    </div>
                </nav>
                {this.state.currentPaneMobile === 'uebersicht' && <Overview />}
                {this.state.currentPaneMobile === 'termine' && <MeineTermine {...paneProps} />}
                {this.state.currentPaneMobile === 'termin' && <Termin  {...paneProps} />}
                {this.state.currentPaneMobile === 'erinnerungen' && <Erinnerungen {...paneProps} />}
                {this.state.currentPaneMobile === 'profile' && <ProfilePage {...paneProps} />}
                {this.state.currentPaneMobile === 'suche' && <Results {...paneProps} />}
                {this.state.currentPaneMobile === 'tiere' && <Tiere {...paneProps} />}
                {this.state.currentPaneMobile === 'animalDetails' && <AnimalDetails {...paneProps} />}
                {this.state.currentPaneMobile === 'addAnimal' && <AddAnimal {...paneProps} />}
                {this.state.currentPaneMobile === 'settings' && <Settings {...paneProps} />}
                {this.state.currentPaneMobile === 'fileUpload' && <UploadTile animalId={this.state.currentPet} {...paneProps} />}
                {this.state.currentPaneMobile === 'videocall' &&
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", alignItems: "center" }}>
                                <VideocallComp sessionName={this.state.sessionName} />
                                <IconButton onClick={() => this.setState({ currentPaneMobile: 'uebersicht' })}>
                                    <CallEndIcon style={{ fontSize: 32, color: "red" }} />
                                </IconButton>
                            </div>
                        }
                <CallDialog
                    open={this.state.doctorsCalling}
                    onClose={() => this.handleCallDialogClose()}
                    onAccept={() => this.handleCallAccept()}
                    name={this.state.sessionDoctor}
                />
                </div>
            )
        }
        return (
            <Layout className="layout" style={{ height: "100vh", display: "flex", flexDirection: "row" }}>
                <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'space-between', backgroundColor: "#fffcf7", height: "100%", minWidth: "200px" }}>
                    <ButtonBase onClick={() => this.setState({ currentLeftPane: 'termine', currentRightPane: "erinnerungen" })}>
                        <img src={Pfote} width="180" alt="" loading="eager" />
                    </ButtonBase>
                    <div>
                        <AnimateSharedLayout>
                            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                {this.state.currentHeaderKey === 'uebersicht' ? (<motion.div className="verticalLineMenu" layoutId="marker" transition={spring} />) : (<div style={{ width: "26px" }} />)}
                                <ButtonBase onClick={() => this.handleHeaderClick({ key: "uebersicht" })}>
                                    <span style={{ fontWeight: "bold", color: this.state.currentHeaderKey === 'uebersicht' ? "#25D2AA" : "black" }} >Übersicht</span>
                                </ButtonBase>
                            </div>
                            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginTop: 20 }}>
                                {this.state.currentHeaderKey === 'calendar' ? (<motion.div className="verticalLineMenu" layoutId="marker" transition={spring} />) : (<div style={{ width: "26px" }} />)}
                                <ButtonBase onClick={() => this.handleHeaderClick({ key: "calendar" })}>
                                    <span style={{ fontWeight: "bold", color: this.state.currentHeaderKey === 'calendar' ? "#25D2AA" : "black" }} >Meine Termine</span>
                                </ButtonBase>
                            </div>
                            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginTop: 20 }}>
                                {this.state.currentHeaderKey === 'animals' ? (<motion.div className="verticalLineMenu" layoutId="marker" transition={spring} />) : (<div style={{ width: "26px" }} />)}
                                <ButtonBase onClick={() => this.handleHeaderClick({ key: "animals" })}>
                                    <span style={{ fontWeight: "bold", color: this.state.currentHeaderKey === 'animals' ? "#25D2AA" : "black" }} >Meine Tiere</span>
                                </ButtonBase>
                            </div>
                            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginTop: 20 }}>
                                {this.state.currentHeaderKey === 'settings' ? (<motion.div className="verticalLineMenu" layoutId="marker" transition={spring} />) : (<div style={{ width: "26px" }} />)}
                                <ButtonBase onClick={() => this.handleHeaderClick({ key: "settings" })}>
                                    <span style={{ fontWeight: "bold", color: this.state.currentHeaderKey === 'settings' ? "#25D2AA" : "black" }} >Einstellungen</span>
                                </ButtonBase>
                            </div>
                        </AnimateSharedLayout>
                    </div>
                    <Link to="/">
                        <Button onClick={() => store.remove('loggedIn')} style={{ margin: 20 }}>Logout</Button>
                    </Link>
                </div>
                <Content style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                    <div className="left-pane" style={{ backgroundColor: this.state.currentLeftPane === 'profile' || this.state.currentLeftPane === 'suche' || this.state.currentLeftPane === 'settings' ? "#fffcf7" : "transsparent" }}>
                        {this.state.currentLeftPane === 'uebersicht' && <Overview />}
                        {this.state.currentLeftPane === 'termine' && <MeineTermine {...paneProps} />}
                        {this.state.currentLeftPane === 'tiere' && <Tiere {...paneProps} />}
                        {this.state.currentLeftPane === 'suche' && <Results {...paneProps} /> }
                        {this.state.currentLeftPane === 'profile' && <ProfilePage {...paneProps} />}
                        {this.state.currentLeftPane === 'praxis' && <PraxisProfile {...paneProps} />}
                        {this.state.currentLeftPane === 'settings' && <Settings {...paneProps} />}
                        {this.state.currentLeftPane === 'videocall' &&
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", alignItems: "center" }}>
                                <VideocallComp sessionName={this.state.sessionName} />
                                <IconButton onClick={() => this.setState({ currentLeftPane: 'termine', currentRightPane: 'erinnerungen' })}>
                                    <CallEndIcon style={{ fontSize: 32, color: "red" }} />
                                </IconButton>
                            </div>
                        }
                        {this.state.currentLeftPane === 'animalDetails' &&
                            <AnimalDetails {...paneProps} />
                        }

                    </div>
                    <div className="site-sider-content" style={{ display: this.state.currentLeftPane === 'profile' || this.state.currentLeftPane === 'praxis' || this.state.currentLeftPane === 'videocall' || this.state.currentLeftPane === 'suche' || this.state.currentLeftPane === 'settings' ? "none" : "flex" }}>
                        {this.state.currentRightPane === 'addAnimal' && <AddAnimal {...paneProps} />}
                        {this.state.currentRightPane === 'erinnerungen' && <Erinnerungen {...paneProps} />}
                        {this.state.currentRightPane === 'terminVerein' && <TerminVereinbaren {...paneProps} />}
                        {this.state.currentRightPane === 'auswahl' && <Auswahl {...paneProps} />}
                        {this.state.currentRightPane === 'termin' && <Termin  {...paneProps} />}
                        {this.state.currentRightPane === 'vet' && <VetUebersicht {...paneProps} />}
                        {this.state.currentRightPane === 'fileUpload' && <UploadTile animalId={this.state.currentPet} {...paneProps} />}
                    </div>
                </Content>
                <CallDialog
                    open={this.state.doctorsCalling}
                    onClose={() => this.handleCallDialogClose()}
                    onAccept={() => this.handleCallAccept()}
                    name={this.state.sessionDoctor}
                />
            </Layout>
        );
    }
}

export default MainDashboardComp;
