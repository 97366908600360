import React, {Component} from "react"

class Overview extends Component{

    constructor(props){
        super(props);

    }

    render(){
        return(
            <div style={{height:"100%", width:"100%"}}>
                
            </div>
        );
    }

}

export default Overview