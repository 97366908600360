import React, { Component, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import PropTypes from "prop-types";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import AnimalsList from "./animalsList";
import DialogContent from "@material-ui/core/DialogContent";
import CallIcon from "@material-ui/icons/Call";
import CallEndIcon from "@material-ui/icons/CallEnd";
import IconButton from "@material-ui/core/IconButton";
import store from 'store';
import DatabaseService from "../../services/database.service";

function ChoosePetDialog(props) {
  const { open, onClose, eventStart } = props;

  const handleSelection = (info) => {
    const data = { vetID: props.id, userID: store.get('usrId'), time: eventStart, slotlength: props.slotLength , petId: info.id };

    DatabaseService.event
        .create(data)
        .then(response => {
            console.log(response.data);
            props.updateEvents();
        })
        .catch(e => {
            console.log(e);
        });
    onClose();
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
    >
      <DialogContent>
        <p
          style={{
            color: "#1e90ff",
            textAlign: "center",
            fontSize: 40,
          }}
        >
          Wähle deinen Patient aus:
      </p>
        <AnimalsList onChoose={handleSelection} />
      </DialogContent>
    </Dialog>
  );
}

ChoosePetDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function VetSelectedDialog(props) {
  const { onClose, open, onRedirect } = props;
  const [callDialog, setDialog] = useState(false);
  const [petDialog, setPetDialog] = useState(false);
  const [eventStart, setEventStart] = useState("");

  const handleClose = () => {
    onClose();
    setDialog(false);
  };

  const handlePetDialogClose = () => {
    setPetDialog(false);
    props.update(props.id, props.slotLength);
  };

  const handlePetDialogOpen = (start, end) => {
    if (!props.events.some((element) => element.start.valueOf() <= start && element.end.valueOf() > start)) {
      setPetDialog(true);
      setEventStart(start);
    }
  };

  const localizer = momentLocalizer(moment);

  const customSlotPropGetter = date => {
    if (props.events === null || props.events.length === 0) return {}
    if (props.events.some((element) => element.start.valueOf() <= date.valueOf() && element.end.valueOf() > date.valueOf()))
      return {
        style: {
          backgroundColor: '#faa'
        },
      }
    else return {}
  }

  if (callDialog) {
    return (
      <Dialog
        onClose={handleClose}
        maxWidth={"xs"}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            width: "200px",
            height: "200px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <span style={{ textAlign: "center", fontSize: 25 }}>
              {props.name}
            </span>
            <span style={{ textAlign: "center", fontSize: 25 }}>ruft an</span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <IconButton onClick={onRedirect} aria-label="take-call">
              <CallIcon fontSize="large" style={{ color: "green" }} />
            </IconButton>
            <IconButton onClick={handleClose} aria-label="deny-call">
              <CallEndIcon fontSize="large" style={{ color: "red" }} />
            </IconButton>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
  return (
    <Dialog
      onClose={handleClose}
      fullWidth={true}
      maxWidth={false}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogContent
        style={{ display: "flex", flexDirection: "column", height: "700px" }}
      >
        <Calendar
          selectable
          localizer={localizer}
          events={[]}
          step={props.slotLength}
          timeslots={2}
          slotPropGetter={customSlotPropGetter}
          min={new Date(`December 1, 1970 ${props.min}`)}
          max={new Date(`December 1, 1970 ${props.max}`)}
          defaultView={Views.DAY}
          scrollToTime={new Date(1970, 1, 1)}
          defaultDate={new Date()}
          views={[ "day"]}
          onSelectEvent={(event) => alert(event.title)}
          onSelectSlot={({ start, end }) => handlePetDialogOpen(start, end)}
          style={{ height: "92%" }}
        />
      </DialogContent>
      <ChoosePetDialog
        open={petDialog}
        onClose={handlePetDialogClose}
        id={props.id}
        updateEvents={props.updateEvents}
        eventStart={eventStart}
        slotLength={props.slotLength}
      />
    </Dialog>
  );
}

VetSelectedDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default VetSelectedDialog
