import React from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import DatabaseService from '../../services/database.service';
import store from "store"
import { StatusCodes as HttpStatus } from 'http-status-codes';


class Erinnerungen extends React.Component {

    constructor(props) {
        super(props);
        console.log("Erinnerungen.js: props: ", this.props);
        this.state = {
            notifications: []
        }

        DatabaseService.notification
            .read(store.get('usrId'))
            .then(response => {
                // console.log("Erinnerungen.js: response ", response);
                let notif = [];
                if (response.status === HttpStatus.OK) {
                    const notifications = JSON.parse(response.data);
                    console.log("Erinnerungen.js: notifications ", notifications);
                    notif = notifications.map(notification => {
                        try {
                            const data = JSON.parse(notification.data);
                            notification.data = data;
                            return notification;
                        }
                        catch (e) {
                            return {};
                        }
                    })
                }
                console.log("Erinnerungen.js: notifications ", notif);
                this.setState({
                    notifications: notif
                });
            })
            .catch(e =>
                console.log(e)
            );
    }

    getlastnotif = () => {
        var lastnotif = [];

        if (this.state.notifications.length > 0) {
            for (var i=0;i<this.state.notifications.length;i++) {
                var not = this.state.notifications[i];
                var isin = false;

                for (var j=0;j<this.state.notifications.length;j++) {
                    if (not.data.event.eventID == this.state.notifications[j].data.event.eventID) not = this.state.notifications[j];
                }

                for (var k=0;k<lastnotif.length;k++) {
                    if (not.data.event.eventID == lastnotif[k].data.event.eventID) isin = true;
                }
                if (!isin) lastnotif.push(not);
            }

            return lastnotif;
        }
        else {return null;}
    }

    isToday = (t) => {
        const today = new Date();
        const date = new Date(t);
        return date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();
    };

    toLocaleDateString = (timestamp, params) => {
        return new Date(timestamp).toLocaleDateString('de-DE', params);
    }

    toLocaleTimeString = (timestamp, params) => {
        return new Date(timestamp).toLocaleTimeString('de-DE', params);
    }

    detectMob() {
        return ( ( window.innerWidth <= 800 ) && ( window.innerHeight <= 1000 ) );
    }

    render() {
        if (this.detectMob()) {
            return (
                <>
                    <div style={{ height: "100%", width: "100%", display: "flex", flexDirection: "column" }}>
                        <h1 style={{ fontSize: 25, padding: 10 }}>Erinnerungen und Hinweise</h1>
                        {this.state.notifications.length === 0 ?
                            <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <p>Zur Zeit liegen keine </p>
                                <p>Erinnerungen oder</p>
                                <p> Hinweise vor</p>
                            </div>
                            :
                            this.getlastnotif().map(notif => {
                                return (
                                    <div id={notif.message+"_"+notif.type+"_"+notif.data.event.eventID} key={notif.data.event.eventID} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "90%", alignSelf: "center" }}>
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingRight: 12 }}>
                                            {notif.data.confirmed ?
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#25D2AA", borderRadius: 40, padding: 8, width: 90}}>
                                                    <span style={{ fontSize: 18, color: "white" }}>Bestätigt</span>
                                                </div>
                                                :
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#cc0000", borderRadius: 40, padding: 8, width: 90}}>
                                                    <span style={{ fontSize: 18, color: "white" }}>Abgelehnt</span>
                                                </div>
                                            }
                                        </div>
                                        <div className="verticalLine" style={{ borderColor: "#25D2AA" }} />
                                        <div style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                            <span style={{ fontWeight: "bold", fontSize: 12 }}>{notif.data.besuchsgrund}</span>
                                            <span style={{ fontSize: 12 }}>{notif.data.vetName}</span>
                                            <span style={{ fontSize: 12 }}>{notif.data.name}</span>
                                        </div>
                                        <div style={{ flex: 2, display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                                            <span style={{ fontWeight: "bold", fontSize: 10 }}>
                                                {this.isToday(notif.data.event.start) ? "Heute" : this.toLocaleDateString(notif.data.event.start, { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })}
                                            </span>
                                            <span style={{ fontSize: 10 }}>
                                                {this.toLocaleTimeString(notif.data.event.start, {hour: 'numeric', minute: 'numeric'})}
                                            </span>
                                            <span style={{ color: "grey", fontSize: 10 }}>
                                                {this.toLocaleTimeString(notif.data.event.end, {hour: 'numeric', minute: 'numeric'})}
                                            </span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </>
            );
        }

        return (
            <>
                <div style={{ height: "100%", width: "100%", display: "flex", flexDirection: "column" }}>
                    <h1>Erinnerungen und Hinweise</h1>
                    {this.state.notifications.length === 0 ?
                        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <p>Zur Zeit liegen keine </p>
                            <p>Erinnerungen oder</p>
                            <p> Hinweise vor</p>
                        </div>
                        :
                        this.getlastnotif().map(notif => {
                            return (
                                <div id={notif.message+"_"+notif.type+"_"+notif.data.event.eventID} key={notif.data.event.eventID} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%" }}>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingRight: 12 }}>
                                        {notif.data.confirmed ?
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#25D2AA", borderRadius: 40, padding: 8, width: 90}}>
                                                <span style={{ fontSize: 18, color: "white" }}>Bestätigt</span>
                                            </div>
                                            :
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#cc0000", borderRadius: 40, padding: 8, width: 90}}>
                                                <span style={{ fontSize: 18, color: "white" }}>Abgelehnt</span>
                                            </div>
                                        }
                                    </div>
                                    <div className="verticalLine" style={{ borderColor: "#25D2AA" }} />
                                    <div style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                        <span style={{ fontWeight: "bold" }}>{notif.data.besuchsgrund}</span>
                                        <span >{notif.data.vetName}</span>
                                        <span >{notif.data.name}</span>
                                    </div>
                                    <div style={{ flex: 2, display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                                        <span style={{ fontWeight: "bold" }}>
                                            {this.isToday(notif.data.event.start) ? "Heute" : this.toLocaleDateString(notif.data.event.start, { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })}
                                        </span>
                                        <span>
                                            {this.toLocaleTimeString(notif.data.event.start, {hour: 'numeric', minute: 'numeric'})}
                                        </span>
                                        <span style={{ color: "grey" }}>
                                            {this.toLocaleTimeString(notif.data.event.end, {hour: 'numeric', minute: 'numeric'})}
                                        </span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </>
        );
    }


}

export default Erinnerungen
