import Profile from './clinicProfile';

class ClinicAction {
    /**
     * Clinic Action Class
     */
    constructor(axios) {
        this.http = axios;
        this._profile = new Profile(axios);
    }


    /**
     * Clinic Profile Service
     */
    get profile() {
        return this._profile;
    }

    /**
     * Create one clinic
     * @param {Object} data
     * @returns Promise object
     */
    create(data) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Read all clinics by id
     * @param {string | number} id clinic id
     * @returns Promise object
     */
    read(id) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Read one clinic
     * @param {string | number} id clinic id
     * @returns Promise object
     */
    readOne(id) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Update one clinic
     * @param {Object} data
     * @returns Promise object
     */
    update(data) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Delete one clinic
     * @param {string | number} id clinic id
     * @returns Promise object
     */
    delete(id) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Search clinic by name
     * @param {String} value clinic name
     * @returns Promise object
     */
    search(clinicName) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
}

export default ClinicAction;
