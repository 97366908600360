import React from "react";
import _ from 'lodash'

class Local extends React.Component {
    constructor(props) {
        super(props);
        console.log("Local.js: props: ", this.props);

        this.state = {
            selectedMicDeviceId: 'none',
            selectedVideoDeviceId: 'none',
            loaded: false,
            trackList: []
        }
        this.videoRef = React.createRef();
    }

    componentDidMount() {

        window.JitsiMeetJS.createLocalTracks({ devices: ['audio', 'video'] })
            .then((tracks) => {
                var trackList = [];
                let deviceIds = _.map(this.props.deviceList, (nd) => nd.id)
                for (let track of tracks) {
                    if (_.indexOf(deviceIds, track.deviceId) !== -1) {
                        trackList.push(track)
                    }
                }
                this.setState({
                    loaded: true,
                    deviceList: this.props.deviceList,
                    selectedMicDeviceId: this.props.defaultMicId,
                    selectedVideoDeviceId: this.props.defaultVideoId,
                    trackList: trackList
                }, () => {
                    this.updateLocalTrack(this.props.defaultVideoId)

                    if (this.props.activeRoom) {
                        let videoTrack = _.find(this.state.trackList, (t) => { return t.deviceId === this.props.defaultVideoId })
                        let micTrack = _.find(this.state.trackList, (t) => { return t.deviceId === this.props.defaultMicId })
                        if (videoTrack) {
                            this.props.activeRoom.addTrack(videoTrack)
                        }
                        if (micTrack) {
                            this.props.activeRoom.addTrack(micTrack)
                        }
                    }
                })
            })
            .catch(e =>
                console.log(e)
            );


    }

    componentWillUnmount() {
        if (this.props.activeRoom) {
            let videoTrack = _.find(this.state.trackList, (t) => { return t.deviceId === this.state.selectedVideoDeviceId })
            let micTrack = _.find(this.state.trackList, (t) => { return t.deviceId === this.state.selectedMicDeviceId })
            if (videoTrack) {
                videoTrack.detach(this.videoRef.current)
                videoTrack.dispose()
            }
            if (micTrack) {
                micTrack.dispose()
            }
        }
    }

    updateLocalTrack = (deviceId) => {
        let setTrack = _.find(this.state.trackList, (t) => { return t.deviceId === deviceId });
        if (setTrack && this.videoRef.current) {
            setTrack.attach(this.videoRef.current)
        }
    }

    render() {
        return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <span>Ich</span>
                <div style={{ backgroundColor: "white", width: "100%", height: "100%", borderRadius: 5, borderColor: "#25D2AA", borderStyle: "solid", borderWidth: "3px", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}>
                    <video autoPlay='1' ref={this.videoRef} style={{ width: "100%", height: "100%", borderRadius: 2 }} />
                </div>
            </div>
        )

    }
}

export default Local