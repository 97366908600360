import React from "react";
// @see: https://ant.design/components/upload/
import { Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import DatabaseService from "../../../services/database.service";
import store from 'store'
import 'dotenv/config';


function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

async function beforeUpload(file, fileList, imageUUID, id) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
        return false;
    }
    let pSuccess = await DatabaseService.animal
        .updateAvatar({
            id: id,
            profilePic: process.env.REACT_APP_HTTP_S3_USER_FILES + "/" + imageUUID
        })
        .then(response => {
            console.log(response.data);
            return Promise.resolve(true);
        })
        .catch(e => {
            console.log(e);
            return Promise.reject(false);
        });
    console.log("promise ", pSuccess);
    return pSuccess;
}


class Avatar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            imageUUID: uuidv4(),
            imageUrl: this.props.imageUrl,
            id: this.props.id
        }
    }

    handleChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrl =>
                this.setState({
                    imageUrl,
                    loading: false,
                }),
            );

            this.props.refreshPets();
        }
    };

    render() {
        const { loading, imageUrl } = this.state;
        const uploadButton = (
            <div>
                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        );
        return (
            <Upload
                name="avatar"
                listType="picture-card"
                style={this.props.style}
                showUploadList={false}
                action={process.env.REACT_APP_HTTP_S3_BASE_URL + "/uploadPP/" + this.state.imageUUID}
                beforeUpload={(file, fileList) => beforeUpload(file, fileList, this.state.imageUUID, this.state.id)}
                onChange={this.handleChange}
            >
                {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
            </Upload>
        );
    }
}

export default Avatar
