import React from "react"
import './Map.css';
import mapboxgl from 'mapbox-gl';


class Map extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    map: null,
    lng: 5,
    lat: 34,
    zoom: 2,
    currentSearchString: "",
    };

    // eslint-disable-next-line import/no-webpack-loader-syntax
    mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
    mapboxgl.accessToken = 'pk.eyJ1IjoiZG9taW5pay1jYXYiLCJhIjoiY2tsMWNlZW1rMHVhMTJwcGVkaXo0YnpucCJ9.implwE-8ZbHzk49FRRZGDw';

    }

    componentDidMount() {
      const map = new mapboxgl.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [this.state.lng, this.state.lat],
      zoom: this.state.zoom
      });

      map.on('move', () => {
        this.setState({
        lng: map.getCenter().lng.toFixed(4),
        lat: map.getCenter().lat.toFixed(4),
        zoom: map.getZoom().toFixed(2)
        });
        });

        this.setState({map:map}, () => this.addMarkers());
      }

      addMarkers(){
        for(var m of this.props.markers){
          // create a HTML element for each feature
          var el = document.createElement('div');
          el.className = 'marker';

          // make a marker for each feature and add to the map
          new mapboxgl.Marker(el)
          .setLngLat(m)
          .setPopup(new mapboxgl.Popup({ offset: 25 }) // add popups
            .setHTML('<h3>' + "Titel" + '</h3><p>' + "Test Description" + '</p>'))
          .addTo(this.state.map);
        }

        /*var cameraTransform = this.state.map.cameraForBounds(this.props.markers, {
          padding: {top: 60, bottom:60, left: 60, right: 60}
          });

          this.state.map.flyTo(cameraTransform);*/

      }

      detectMob() {
        return ( ( window.innerWidth <= 800 ) && ( window.innerHeight <= 1000 ) );
      }

      render() {
        if (this.detectMob()) {
          return (
            <div>
            <div ref={el => this.mapContainer = el} className='mapContainerMobile' />
            </div>
            )
        }
        return (
        <div>
        <div ref={el => this.mapContainer = el} className='mapContainer' />
        </div>
        )
      }

}

export default Map;
