import React from 'react';
import { List, Button, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import store from 'store'
import DatabaseService from "../../services/database.service";
import Paper from '@material-ui/core/Paper';
import { ButtonBase } from '@material-ui/core';
import profile from "../../Profile_Pic.png";

class Tiere extends React.Component {

    constructor(props) {
        super(props);
        console.log("Tiere.js: props: ", this.props);
        this.state = {
            // animalInfo: [{ name: "", tierart: "", tierrasse: "", alter: "" }],
            animalInfo: this.props.animalInfo || [{ name: "", tierart: "", tierrasse: "", alter: "", addAnimal: true }],
        }
    }

    componentDidMount() {
        if(this.props.animalInfo) {
            this.setState({ animalInfo: this.props.animalInfo.concat({ addAnimal: true }) });
        }
        console.log("Tiere.js: animalInfo: ", this.state.animalInfo);
    }

    componentDidUpdate() {
        console.log("Tiere.js: componentDidUpdate() ", this.props.animalInfo, this.state.animalInfo);
        if (this.props.animalInfo && ((this.state.animalInfo.length -1) !== this.props.animalInfo.length)) {
            const intersection = this.props.animalInfo.filter(item1 => !this.state.animalInfo.some(item2 => item1.id === item2.id));
            console.log("Tiere.js: componentDidUpdate() setState intersection ", intersection);
            intersection.map(x => {
                this.setState(state => {
                    this.state.animalInfo.push(x);
                    console.log("Tiere.js: componentDidUpdate() state ", state);
                    return state;
                })
            });
            console.log("Tiere.js: componentDidUpdate() setState ", this.props.animalInfo);
        }
    }

    onChange(checkedValues) {
        console.log('checked = ', checkedValues);
    }

    detectMob() {
        return ( ( window.innerWidth <= 800 ) && ( window.innerHeight <= 1000 ) );
    }

    render() {
        if (this.detectMob()) {
            return (
                <>
                    <h1 style={{ fontSize: 25, padding: 10 }}>Meine Tiere ({this.state.animalInfo.length - 1})</h1>
                    <div style={{ height: "100%", width: "100%", padding: 10 }}>
                        <List
                            style={{ paddingTop: 30, height: "100%", width: "100%" }}
                            grid={{
                                gutter: 16,
                                xs: 1,
                                sm: 1,
                                md: 1,
                                lg: 1,
                                xl: 1,
                                xxl: 1,
                            }}
                            dataSource={this.state.animalInfo}
                            renderItem={item => (
                                <List.Item style={{ height: "280px", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    { item.addAnimal ?
                                        <ButtonBase style={{ width: "100%", height: "100%" }} onClick={() => this.props.handleHeaderMobileClick({ key: "addAnimal" })}>
                                            <Paper elevation={3} style={{ backgroundColor: "transparent", borderWidth: "2px", borderColor: "black", height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <span style={{ fontSize: 25 }}>+ Neues Haustier hinzufügen </span>
                                            </Paper>
                                        </ButtonBase> :
                                        <Paper elevation={3} style={{ backgroundColor: "transparent", borderWidth: "2px", borderColor: "black", height: "100%", width: "100%", display: "flex", flexDirection: 'column', justifyContent: 'space-around', alignItems: "stretch", padding: 20 }}>
                                            <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between', alignItems: "center", margin: 8, width: "100%", height: "100%" }}>
                                                <Avatar
                                                    size={64}
                                                    src={item.profilePic ? item.profilePic : profile}
                                                />
                                                <span style={{ fontSize: 30, fontWeight: 'bold', textAlign: 'center' }}>{item.name}</span>
                                                <div style={{ width: "64px" }} />
                                            </div>
                                            <div style={{ display: "flex", flexDirection: 'column', alignItems: "flex-start", margin: 10, height: "100%" }}>
                                                <span style={{ fontSize: 20, fontWeight: "bold", marginTop: 7 }}>{item.tierrasse}</span>
                                                <span style={{ fontSize: 20, color: "grey", marginTop: 7 }}>{item.tierart}</span>
                                                <span style={{ fontSize: 20, color: "grey", marginTop: 7 }}>{item.tierAlter} Jahre</span>
                                            </div>

                                            <Button size="large" style={{ backgroundColor: "#25D2AA", color: "white", height: "50px" }} shape="round" onClick={() => {
                                                this.props.setCurrentPet(item.id);
                                                //this.props.setRightPane("fileUpload");
                                                this.props.handleHeaderMobileClick({ key: "animalDetails" });
                                            }}>
                                                Details anzeigen
                                            </Button>
                                        </Paper>
                                    }
                                </List.Item>
                            )}
                        />
                    </div>
                </>
            );
        }

        return (
            <>
                <h1>Meine Tiere ({this.state.animalInfo.length - 1})</h1>
                <div style={{ height: "100%", width: "100%" }}>
                    <List
                        style={{ paddingTop: 30, height: "100%", width: "100%" }}
                        grid={{
                            gutter: 16,
                            xs: 2,
                            sm: 2,
                            md: 2,
                            lg: 2,
                            xl: 2,
                            xxl: 2,
                        }}
                        dataSource={this.state.animalInfo}
                        renderItem={item => (
                            <List.Item style={{ height: "280px", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                { item.addAnimal ?
                                    <ButtonBase style={{ width: "100%", height: "100%" }} onClick={() => this.props.setRightPane("addAnimal")}>
                                        <Paper elevation={3} style={{ backgroundColor: "transparent", borderWidth: "2px", borderColor: "black", height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <span style={{ fontSize: 25 }}>+ Neues Haustier hinzufügen </span>
                                        </Paper>
                                    </ButtonBase> :
                                    <Paper elevation={3} style={{ backgroundColor: "transparent", borderWidth: "2px", borderColor: "black", height: "100%", width: "100%", display: "flex", flexDirection: 'column', justifyContent: 'space-around', alignItems: "stretch", padding: 20 }}>
                                        <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between', alignItems: "center", margin: 8, width: "100%", height: "100%" }}>
                                            <Avatar
                                                size={64}
                                                src={item.profilePic ? item.profilePic : profile}
                                            />
                                            <span style={{ fontSize: 30, fontWeight: 'bold', textAlign: 'center' }}>{item.name}</span>
                                            <div style={{ width: "64px" }} />
                                        </div>
                                        <div style={{ display: "flex", flexDirection: 'column', alignItems: "flex-start", margin: 10, height: "100%" }}>
                                            <span style={{ fontSize: 20, fontWeight: "bold", marginTop: 7 }}>{item.tierrasse}</span>
                                            <span style={{ fontSize: 20, color: "grey", marginTop: 7 }}>{item.tierart}</span>
                                            <span style={{ fontSize: 20, color: "grey", marginTop: 7 }}>{item.tierAlter} Jahre</span>
                                        </div>

                                        <Button size="large" style={{ backgroundColor: "#25D2AA", color: "white", height: "50px" }} shape="round" onClick={() => {
                                            this.props.setCurrentPet(item.id);
                                            //this.props.setRightPane("fileUpload");
                                            this.props.setLeftPane('animalDetails');
                                        }}>
                                            Details anzeigen
                                        </Button>
                                    </Paper>
                                }
                            </List.Item>
                        )}
                    />
                </div>
            </>
        );
    }
}

export default Tiere
