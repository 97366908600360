
class AnimalAction {
    /**
     * Animal Action Class
     */
    constructor(axios) {
        this.http = axios;

    }
    /**
     * Create one animal
     * @param {Object} data
     * @returns Promise object
     */
    create(data) {
        return this.http.post('/api/animalbase/create.php', data);
    };
    /**
     * Read all animals
     * @param {string} ownerId animal owner id
     * @returns Promise object
     */
    read(ownerId) {
        return this.http.get(`/api/animalbase/read_by_index.php?id=${ownerId}`);
    };
    /**
     * Read one animal
     * @param {string} id animal id
     * @returns Promise object
     */
    readOne(id) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Update one animal
     * @param {Object} data
     * @returns Promise object
     */
    update(data) {
        return this.http.post('/api/animalbase/update.php', data);
    };
    /**
     * Delete one animal - NOT_IMPLEMENTED!
     * @param {Object} id animal id
     * @returns Promise object
     */
    delete(id) {
        return Promise.reject('NOT_IMPLEMENTED');
    };

    updateAvatar(data) {
        return this.http.patch("/api/animalbase/update_avatar.php", data);
    };

}

export default AnimalAction;
