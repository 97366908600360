import React from 'react';
import { Layout, Button } from 'antd';
import MainSearchBar from "./new-dashboard/MainSearchBar"
import Pfote from "./Pfote.png";
import "./StartScreen.css"
import { ButtonBase, Paper } from '@material-ui/core';
import BackgroundImage from "./Volna4k-Edit.png"
import { NavLink, Link } from "react-router-dom";


const { Header, Content, Footer } = Layout;

class Dashboard extends React.Component {

  handleHeaderClick = e => {
    console.log(e.key);
  };

  render() {


    return (
        <Layout style={{minHeight: "100vh", backgroundImage: `url(${BackgroundImage})`}}>
        <Content>
          <div className="sitelayoutcontentstart">
          <div style={{width: "100%", display: "flex", flexDirection: 'row', justifyContent: 'space-around'}}>
          <img src={Pfote} width="65" height="65" alt="" loading="eager" />
          <span
            style={{
              color: "#1e90ff",
              fontSize: 50,
              textAlign: 'center'
            }}
          >
            Call-a.vet
        </span>
        </div>
        <div style={{display: "flex", flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
        <Link to="/Register">
          <Button style={{borderColor: "#F66054", backgroundColor: "#F66054", color: "white"}}>Registrieren</Button>
          </Link>
          <span style={{color: "#1e90ff", margin: 10}}>oder</span>
          <Link to="/Login">
          <Button type="primary" >Anmelden</Button>
          </Link>
        </div>
          <div className="searchBar" >
            <span style={{marginBottom: 30, color:"#1e90ff"}}>Vereinbaren sie gleich einen Termin bei einem Tierarzt ihrer Wahl</span>
            <MainSearchBar />
            </div>
          </div>
          <div style={{display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <span style={{fontSize: 20}}>
            Es gibt viele Gründe für Call-a.vet
          </span>
          <div style={{display: "flex",flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <span style={{width: "200px", margin: 30}}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </span>
            <span style={{width: "200px", margin:30}}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </span>
            <span style={{width: "200px", margin:30}}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </span>
          </div>
          </div>
          </Content>
          <Footer style={{ height: "15px", display: "flex", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <NavLink className="nav-item nav-link" to="/team">
              Team
                </NavLink>
            <NavLink className="nav-item nav-link" to="/contact">
              Kontakt
                </NavLink>
            <NavLink className="nav-item nav-link" to="/news">
              News
                </NavLink>
            <NavLink className="nav-item nav-link" to="/impressum">
              Impressum
                </NavLink>
          </Footer>
          </Layout>
    );
  }
}

export default Dashboard;
