import React from 'react';
import { Descriptions } from 'antd';

class Termin extends React.Component {

  onChange(checkedValues) {
    console.log('checked = ', checkedValues);
  }

  render(){
    const plainOptions = ['Apple', 'Pear', 'Orange'];

      return(
        <>
          <h1>Übersicht deiner Auswahl</h1>
          <Descriptions title={<h4>Xavi</h4>}>
            <Descriptions.Item><h5>Anlass</h5></Descriptions.Item>
            <Descriptions.Item span={2}><span style={{fontSize:20}}>Jahresuntersuchung</span></Descriptions.Item>
            <Descriptions.Item><h5>Termin</h5></Descriptions.Item>
            <Descriptions.Item span={2}><span style={{fontSize:20}}>1.12.2020</span></Descriptions.Item>
            <Descriptions.Item ><h5>Arzt</h5></Descriptions.Item>
            <Descriptions.Item span={2}><span style={{fontSize:20}}>Dr.Schnee</span></Descriptions.Item>
            <Descriptions.Item ><h5>Entfernung</h5></Descriptions.Item>
            <Descriptions.Item span={2}><span style={{fontSize:20}}>6km</span></Descriptions.Item>
          </Descriptions>
        </>
      );
  }


}

export default Termin
