
class VetProfileAction {
    /**
     * VetProfile Action Class
     */
    constructor(axios) {
        this.http = axios;        
    }


    /**
     * Create one vet profile
     * @param {Object} data 
     * @returns Promise object
     */
    create(data) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Read all vet profiles
     * @returns Promise object
     */
    read() {
        return this.http.get("/api/vetprofile/read.php");
    };
    /**
     * Read one vet profile
     * @param {string} id vet id
     * @returns Promise object
     */
    readOne(vetId) {
        return this.http.get(`/api/vetprofile/read_by_index.php?id=${vetId}`);
        // is the same
        // return this.http.get(`/api/vetprofile/read_vet_clinic.php?id=${vetId}`);
    };
    /**
     * Update one vet profile
     * @param {Object} data
     * @returns Promise object
     */
    update(data) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Delete one vet profile
     * @param {Object} id vet id
     * @returns Promise object
     */
    delete(id) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Search all vet profils
     * @param {Object} data 
     * @returns Promise object
     */
    search(data) {
        return this.http.post("/api/vetprofile/search.php", data);
    };
}

export default VetProfileAction;