import http from "../http-node-service";

class UploadService {
  getFileList(id) {
    return http.get(`/getFileList/${id}`);
  }

  getFile(fI){
  return http.get(
          "/getUsrFile/"+fI,
          { responseType: 'arraybuffer' },
        );
  }

  sessionActive(id){
    return http.get(`/sessionActive/${id}`);
  }
}

export default new UploadService();
