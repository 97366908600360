import React, { Component } from "react"
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import { ButtonBase } from '@material-ui/core';
import {
    UserOutlined
} from '@ant-design/icons';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DatabaseService from "../../services/database.service"
import store from 'store';
import { Form, Input, Button, Checkbox, InputNumber, Divider } from 'antd';
import { Redirect } from "react-router-dom";
import Axios from "axios";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <span style={{ fontSize: 20, fontWeight: "bold" }}>{children}</span>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});


class RegisterUserModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            submitted: false,
            registerStage: "user",
            usrID: -1
        }

    }

    onFinishPet = values => {

        const submitData = {
            id: this.state.usrID,
            name: values.name,
            tierart: values.tierart,
            tierrasse: values.tierrasse,
            tierAlter: values.alter
        }

        DatabaseService.animal
            .create(submitData)
            .then(response => {
                this.props.onClose(response.data.id);
            })
            .catch(e => {
                console.log(e);
            });
    };

    onFinishUser = values => {
        let data = {
            vorname: values.vorname,
            nachname: values.nachname,
            username: values.username,
            email: values.email,
            passwort: values.passwort
        };

        DatabaseService.animalOwner
            .create(data)
            .then(response => {
                /*Axios.post("https://cav-mailer-service.herokuapp.com/mailUser",{
                    mail: values.email,
                    token: response.data.token
                })
                    .then(res=>{*/
                store.set('loggedIn', true);
                store.set('usrId', response.data.id);
                store.set('redirectPage', "/dashboard");
                this.setState({ registerStage: "pet", usrID: response.data.id });
                /*})
                .catch(e => {
                    console.log(e);
                  });*/
            })
            .catch(e => {
                console.log(e);
            });
        console.log('Success:', values);
    };

    onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    render() {
        return (
            <Dialog onClose={this.props.onClose} paper={{ backgroundColor: "#fffcf7" }} aria-labelledby="customized-dialog-title" open={this.props.open} maxWidth={"lg"}>
                <DialogTitle id="customized-dialog-title" onClose={this.props.onClose}>
                    <span style={{ fontSize: 22, fontFamily: "WorkSans", fontWeight: "bold" }} >Jetzt kostenlos registrieren</span>
                </DialogTitle>
                {this.state.registerStage === "user" && <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: 20, marginLeft: 15, marginRight: 15 }}>
                    <Form
                        layout="vertical"
                        onFinish={this.onFinishUser}
                        onFinishFailed={this.onFinishFailed}
                        requiredMark={false}
                    >
                        <Form.Item
                            label="Benutzername"
                            name="username"
                            rules={[{ required: true, message: 'Bitte Benutzername eingeben' }]}
                        >
                            <Input placeholder="max.mustermann" />
                        </Form.Item>

                        <Form.Item
                            label="Vorname"
                            name="vorname"
                            rules={[{ required: true, message: 'Bitte Vorname eingeben' }]}
                            style={{ display: 'inline-block', width: 'calc(50% - 10px)' }}
                        >
                            <Input placeholder="Max" />
                        </Form.Item>
                        <Form.Item
                            label="Nachname"
                            name="nachname"
                            rules={[{ required: true, message: 'Bitte Nachname eingeben' }]}
                            style={{ display: 'inline-block', width: 'calc(50% - 10px)', margin: '0 10px' }}
                        >
                            <Input placeholder="Mustermann" />
                        </Form.Item>

                        <Form.Item
                            label="Telefon"
                            name="telefon"
                            rules={[{ required: true, message: 'Bitte Telefonnummer eingeben' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="E-Mail Addresse"
                            name="email"
                            rules={[{ required: true, message: 'Bitte E-Mail eingeben' }]}
                        >
                            <Input placeholder="max.mustermann@net.de" />
                        </Form.Item>

                        <Form.Item
                            label="Passwort"
                            name="passwort"
                            rules={[{ required: true, message: 'Bitte Passwort eingeben' }]}
                        >
                            <Input.Password />
                        </Form.Item>


                        <Form.Item
                            name="agb"
                            valuePropName="checked"
                            rules={[
                                {
                                    validator: (_, value) =>
                                        value ? Promise.resolve() : Promise.reject('Geschäftsbedingungen zustimmen!'),
                                },
                            ]}
                        >
                            <Checkbox>Ich habe die AGBs gelesen und stimme zu</Checkbox>
                        </Form.Item>

                        <Form.Item >
                            <Button style={{ backgroundColor: "#25D2AA", color: "white", height: "45px", fontSize: 18 }} shape="round" htmlType="submit" block>
                                Weiter
                            </Button>
                        </Form.Item>
                    </Form>
                </div>}
                {this.state.registerStage === "pet" &&
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
                        <span style={{ fontSize: 20, fontFamily: "WorkSans" }} >Nun ihr Haustier registrieren</span>
                        <Form
                            style={{ width: "70%", marginTop: 15 }}
                            name="basic"
                            initialValues={{ remember: true }}
                            onFinish={this.onFinishPet}
                            onFinishFailed={this.onFinishFailed}
                        >
                            <Form.Item
                                name="name"
                                rules={[{ required: true, message: 'Bitte den Namen deines Tieres eingeben!' }]}
                            >
                                <Input size="large" placeholder="Name" />
                            </Form.Item>

                            <Form.Item
                                name="tierart"
                                rules={[{ required: true, message: 'Bitte die Tierart deines Tieres eingeben!' }]}
                            >
                                <Input size="large" placeholder="Tierart" />
                            </Form.Item>

                            <Form.Item
                                name="tierrasse"
                                rules={[{ required: true, message: 'Bitte die Tierrasse deines Tieres eingeben!' }]}
                            >
                                <Input size="large" placeholder="Tierrasse" />
                            </Form.Item>

                            <Form.Item
                                name="alter"
                                rules={[{ required: true, message: 'Bitte das Alter deines Tieres eingeben!' }]}
                            >
                                <InputNumber size="large" placeholder="Alter" />
                            </Form.Item>

                            <Form.Item {...this.tailLayout}>
                                <Button style={{ backgroundColor: "#25D2AA", color: "white", height: "45px", fontSize: 18 }} shape="round" htmlType="submit" block>
                                    Hinzufügen
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                }
            </Dialog>
        )
    }
}


export default RegisterUserModal