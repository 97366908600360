import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CallIcon from "@material-ui/icons/Call";
import CallEndIcon from "@material-ui/icons/CallEnd";
import IconButton from "@material-ui/core/IconButton";
import { Link, Redirect } from "react-router-dom";


function CallDialog(props) {
  const { onClose, open, onRedirect } = props;

  const handleClose = () => {
    onClose();
  };

    return (
      <Dialog
        onClose={handleClose}
        maxWidth={"xs"}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            width: "200px",
            height: "200px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <span style={{ textAlign: "center", fontSize: 25 }}>
              {props.name}
            </span>
            <span style={{ textAlign: "center", fontSize: 25 }}>ruft an</span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
          <Link to="/jitsi">
            <IconButton aria-label="take-call">
              <CallIcon fontSize="large" style={{ color: "green" }} />
            </IconButton>
          </Link>
            <IconButton onClick={handleClose} aria-label="deny-call">
              <CallEndIcon fontSize="large" style={{ color: "red" }} />
            </IconButton>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  export default CallDialog
