import Event from './vetEvent';
import Profile from './vetProfile';


class VetAction {
    /**
     * Vet Action Class
     */
    constructor(axios) {
        this.http = axios;
        this._event = new Event(axios);
        this._profile = new Profile(axios);
    }


    /**
     * Vet Profile Service
     */
    get event() {
        return this._event;
    }

    /**
     * Vet Profile Service
     */
    get profile() {
        return this._profile;
    }


    /**
     * Create one vet
     * @param {Object} data 
     * @returns Promise object
     */
    create(data) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Read all events
     * @returns Promise object
     */
    read() {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Read one vet
     * @param {Object} id vet id
     * @returns Promise object
     */
    readOne(id) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Update one vet
     * @param {Object} data
     * @returns Promise object
     */
    update(data) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Delete one vet
     * @param {Object} id vet id
     * @returns Promise object
     */
    delete(id) {
        return Promise.reject('NOT_IMPLEMENTED');
    };

}

export default VetAction;