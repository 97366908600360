import React from 'react';
import { List, Avatar, Space, Spin, Typography, Button } from 'antd';
import { MenuOpenSharp } from '@material-ui/icons';
import Paper from '@material-ui/core/Paper';
import { ButtonBase } from '@material-ui/core';
import profile from "../../Profile_Pic.png";

const { Text } = Typography;

class MeineTermine extends React.Component {

    constructor(props) {
        super(props);
        console.log("MeineTermine.js: props: ", this.props);

        this.state = {
            listData: [{
                addAppointment: true
            }],
            profilePic: {}
        }
    }

    onChange(checkedValues) {
        console.log('checked = ', checkedValues);
    }

    componentDidMount() {
        if (this.props.eventsReady) {
            const now = new Date();
            const data = this.props.events.filter(event => event.end.valueOf() >= now.valueOf());

            for (var i=0;i<data.length;i++) {
                data[i].profilePic = this.props.animalInfo.find(animal => animal.id == data[i].petID).profilePic;
            }

            data.push({
                addAppointment: true,
            });
            this.setState({ listData: data });
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.eventsReady && this.props.eventsReady) {
            const now = new Date()
            const data = this.props.events.filter(event => event.end.valueOf() >= now.valueOf())

            data.push({
                addAppointment: true
            });
            this.setState({ listData: data });
        }
    }

    detectMob() {
        return ( ( window.innerWidth <= 800 ) && ( window.innerHeight <= 1000 ) );
    }

    render() {
        if (this.detectMob()) {
            return (
                <>
                    <h1 style={{ fontSize: 25, padding: 10 }}>Meine Termine ({this.state.listData.length - 1})</h1>
                    <div style={{ height: "100%", width: "100%", padding: 10 }}>
                        <List
                            style={{ paddingTop: 30, height: "100%", width: "100%" }}
                            grid={{
                                gutter: 16,
                                xs: 2,
                                sm: 2,
                                md: 2,
                                lg: 2,
                                xl: 2,
                                xxl: 2,
                            }}
                            dataSource={this.state.listData}
                            renderItem={item => (
                                <List.Item style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    {item.addAppointment ?
                                        <ButtonBase onClick={() => this.props.handleHeaderMobileClick({ key: "termin" })}>
                                            <Paper elevation={3} style={{ backgroundColor: "transparent", borderWidth: "2px", borderColor: "black", height: "20vh", width: "26vw", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <span style={{ fontSize: 12, textAlign: "center" }}>+ Neuen Termin vereinbaren </span>
                                            </Paper>
                                        </ButtonBase> :
                                        <Paper elevation={3} style={{ backgroundColor: "transparent", borderWidth: "2px", borderColor: "black", height: "100%", width: "100%", display: "flex", flexDirection: 'column', justifyContent: 'space-around', alignItems: "stretch", padding: 8 }}>
                                            <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', alignItems: "stretch" }}>
                                                <span style={{ fontSize: 11, fontWeight: 'bold', textAlign: 'center' }}>{item.name},</span>
                                                <span style={{ fontSize: 11, fontWeight: 'bold', textAlign: 'center' }}>{item.besuchsgrund}</span>
                                                <span style={{ fontSize: 6, textAlign: 'center' }}>{item.start.toLocaleString('de-DE', { hour: "numeric", minute: "2-digit" }) + " - " + item.end.toLocaleString('de-DE', { hour: "numeric", minute: "2-digit" })}</span>
                                                <span style={{ fontSize: 6, textAlign: 'center' }}>{item.start.toLocaleDateString('de-DE', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</span>
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                <div style={{ display: "flex", flexDirection: 'column', alignItems: "flex-start", margin: 10, height: "100%" }}>
                                                    <span style={{ fontSize: 10, fontWeight: "bold", marginTop: 7 }}>{item.tierrasse}</span>
                                                    <span style={{ fontSize: 10, color: "grey", marginTop: 7 }}>{item.tierAlter} Jahre</span>
                                                </div>
                                                <Avatar
                                                    style={{ margin: 22 }}
                                                    size={18}
                                                    src={item.profilePic ? item.profilePic : profile}
                                                />
                                            </div>
                                        </Paper>
                                    }
                                </List.Item>
                            )}
                        />
                    </div>
                </>
            );
        }

        if (!this.props.eventsReady) {
            return (
                <Space style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Spin size="large" />
                </Space>
            );
        }
        return (
            <>
                <h1>Meine Termine ({this.state.listData.length - 1})</h1>
                <div style={{ height: "100%", width: "100%" }}>
                    <List
                        style={{ paddingTop: 30, height: "100%", width: "100%" }}
                        grid={{
                            gutter: 16,
                            xs: 2,
                            sm: 2,
                            md: 2,
                            lg: 2,
                            xl: 2,
                            xxl: 2,
                        }}
                        dataSource={this.state.listData}
                        renderItem={item => (
                            <List.Item style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {item.addAppointment ?
                                    <ButtonBase onClick={() => this.props.setRightPane("termin")}>
                                        <Paper elevation={3} style={{ backgroundColor: "transparent", borderWidth: "2px", borderColor: "black", height: "20vh", width: "20vw", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <span style={{ fontSize: 25, textAlign: "center" }}>+ Neuen Termin vereinbaren </span>
                                        </Paper>
                                    </ButtonBase> :
                                    <Paper elevation={3} style={{ backgroundColor: "transparent", borderWidth: "2px", borderColor: "black", height: "100%", width: "100%", display: "flex", flexDirection: 'column', justifyContent: 'space-around', alignItems: "stretch", padding: 20 }}>
                                        <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between', alignItems: "center", margin: 8, width: "100%", height: "100%" }}>
                                            <Avatar
                                                size={64}
                                                src={item.profilePic ? item.profilePic : profile}
                                            />
                                            <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', alignItems: "stretch" }}>
                                                <span style={{ fontSize: 22, fontWeight: 'bold', textAlign: 'center' }}>{item.name}, {item.besuchsgrund}</span>
                                                <span style={{ fontSize: 22, textAlign: 'center' }}>{item.start.toLocaleString('de-DE', { hour: "numeric", minute: "2-digit" }) + " - " + item.end.toLocaleString('de-DE', { hour: "numeric", minute: "2-digit" })}</span>
                                                <span style={{ fontSize: 22, textAlign: 'center' }}>{item.start.toLocaleDateString('de-DE', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</span>
                                            </div>
                                            <div style={{ width: "64px" }} />
                                        </div>
                                        <div style={{ display: "flex", flexDirection: 'column', alignItems: "flex-start", margin: 10, height: "100%" }}>
                                            <span style={{ fontSize: 20, fontWeight: "bold", marginTop: 7 }}>{item.tierrasse}</span>
                                            <span style={{ fontSize: 20, color: "grey", marginTop: 7 }}>{item.tierAlter} Jahre</span>
                                        </div>
                                    </Paper>
                                }
                            </List.Item>
                        )}
                    />
                </div>
            </>
        );
    }


}

export default MeineTermine
