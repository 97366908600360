import React from 'react';
import { motion, AnimateSharedLayout } from "framer-motion";
import { ButtonBase } from '@material-ui/core';
import { Form, Button, Input, Select } from 'antd';
import Paper from '@material-ui/core/Paper';
import Avatar from "./settings/UploadAvatarAnimal";
import DatabaseService from "../../services/database.service";
// https://github.com/prettymuchbryce/http-status-codes
import { StatusCodes as HttpStatus } from 'http-status-codes';
import store from 'store';
import "./PopUp.css";

const spring = {
    type: "spring",
    stiffness: 500,
    damping: 30
};


class AnimalDetails extends React.Component {

    constructor(props) {
        super(props);
        console.log("AnimalDetails.js: props: ", this.props);
        this.state = {
            currentHeaderKey: "profil",
            pet: this.props.animalInfo.find(animal => animal.id == this.props.currentPet)
        }
    }

    onFinish = values => {
        const submitData = {
            id: this.state.pet.id,
            ownerId: store.get('usrId'),
            name: this.state.pet.name,
            tierart: values.tierart,
            tierrasse: values.tierrasse,
            tierAlter: this.state.pet.tierAlter,
            geschlecht: values.geschlecht,
            ueber: values.about
        }
        console.log("AnimalDetails.js: values: ", values);
        console.log("AnimalDetails.js: submitData: ", submitData);
        console.log("AnimalDetails.js: DatabaseService: ", DatabaseService);
        DatabaseService.animal.update(submitData)
            .then(response => {
                if(response.status === HttpStatus.OK) {
                    console.log("AnimalDetails.js: updatePet() ",response.data);
                    this.setState({ addAnimal: false });
                    this.props.refreshPets();
                    this.showpopup("myPopupconfirm");
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    onFinishFailed = error => {
        console.log("AnimalDetails.js: onFinishFailed() ", error);
    };

    async showpopup(id) {
        var popup = document.getElementById(id);

        if (!popup.classList.contains("show")) {
            popup.classList.add("show");

            let promise = new Promise((resolve, reject) => {
                setTimeout(() => resolve("done!"), 5000)
              });

            let result = await promise;

            popup.classList.remove("show");
        }
    }

    detectMob() {
        return ( ( window.innerWidth <= 800 ) && ( window.innerHeight <= 1000 ) );
    }

    render() {
        if (this.detectMob()) {
            return (<div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", width: "100%" }}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%", fontSize: 22, padding: 10 }}>
                <AnimateSharedLayout>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", marginTop: 10 }}>
                        <ButtonBase onClick={() => { this.setState({ currentHeaderKey: 'back' }); this.props.handleHeaderMobileClick({ key: "animals" }); }}>
                            <span style={{ fontWeight: "bold" }} >Zurück</span>
                        </ButtonBase>
                        {this.state.currentHeaderKey === 'back' && <motion.div className="horizontalLineMenu" layoutId="marker" transition={spring} />}
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", marginTop: 10, marginLeft: 30 }}>
                        <span style={{ fontWeight: "bold" }} onClick={() => this.setState({ currentHeaderKey: 'profil' })}>{this.state.pet.name}</span>
                        {this.state.currentHeaderKey === 'profil' && <motion.div className="horizontalLineMenu" layoutId="marker" transition={spring} />}
                    </div>
                </AnimateSharedLayout>
            </div>
            <div>
            </div>
            <Paper elevation={3} style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", padding: 25, margin: 30, overflow: "auto", width: "95%", height: "90%", alignSelf: "center" }}>
                <span style={{ fontSize: 22, fontWeight: "bold" }}>Profil</span>
                <Avatar {...this.props} style={{ height: "100px", width: "100px", marginTop: 20 }} id={this.state.pet.id} imageUrl={this.state.pet.profilePic} />

                <Form
                    layout="vertical"
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    style={{ width: "100%", marginTop: 20 }}
                    requiredMark={false}
                    initialValues={{
                        tierart: this.state.pet.tierart,
                        tierrasse: this.state.pet.tierrasse,
                        geschlecht: this.state.pet.geschlecht,
                        about: this.state.pet.ueber
                    }}
                >

                    <Form.Item
                        label="Tierart"
                        name="tierart"
                        rules={[{ required: true, message: 'Bitte Tierart eingeben!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Tierrasse"
                        name="tierrasse"
                        rules={[{ required: true, message: 'Bitte Tierrasse eingeben!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Geschlecht"
                        name="geschlecht"
                        rules={[{ required: true, message: 'Bitte eine Auswahl treffen!' }]}
                    >
                        <Select placeholder="Bitte auswählen">
                            <Select.Option value="männlich">Männlich</Select.Option>
                            <Select.Option value="weiblich">Weiblich</Select.Option>
                            <Select.Option value="unbestimmt">Unbestimmt</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Über mich"
                        name="about"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item >
                        <Button style={{ backgroundColor: "#25D2AA", color: "white", height: "45px", fontSize: 18 }} shape="round" htmlType="submit" block>
                            Speichern
                        </Button>
                        <div class="popup">
                            <span class="popuptext" id="myPopupconfirm">Profil aktualisiert!</span>
                        </div>
                    </Form.Item>
                </Form>
            </Paper>
        </div>);
        }

        return (<div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", width: "100%" }}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%", fontSize: 22 }}>
                <AnimateSharedLayout>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", marginTop: 10 }}>
                        <ButtonBase onClick={() => { this.setState({ currentHeaderKey: 'back' }); this.props.setLeftPane('tiere'); }}>
                            <span style={{ fontWeight: "bold" }} >Zurück</span>
                        </ButtonBase>
                        {this.state.currentHeaderKey === 'back' && <motion.div className="horizontalLineMenu" layoutId="marker" transition={spring} />}
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", marginTop: 10, marginLeft: 30 }}>
                        <span style={{ fontWeight: "bold" }} onClick={() => this.setState({ currentHeaderKey: 'profil' })}>{this.state.pet.name}</span>
                        {this.state.currentHeaderKey === 'profil' && <motion.div className="horizontalLineMenu" layoutId="marker" transition={spring} />}
                    </div>
                </AnimateSharedLayout>
            </div>
            <div>
            </div>
            <Paper elevation={3} style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", padding: 25, margin: 30, overflow: "auto", width: "95%", height: "90%" }}>
                <span style={{ fontSize: 22, fontWeight: "bold" }}>Profil</span>
                <Avatar {...this.props} style={{ height: "100px", width: "100px", marginTop: 20 }} id={this.state.pet.id} imageUrl={this.state.pet.profilePic} />

                <Form
                    layout="vertical"
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    style={{ width: "70%", marginTop: 20 }}
                    requiredMark={false}
                    initialValues={{
                        tierart: this.state.pet.tierart,
                        tierrasse: this.state.pet.tierrasse,
                        geschlecht: this.state.pet.geschlecht,
                        about: this.state.pet.ueber
                    }}
                >

                    <Form.Item
                        label="Tierart"
                        name="tierart"
                        rules={[{ required: true, message: 'Bitte Tierart eingeben!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Tierrasse"
                        name="tierrasse"
                        rules={[{ required: true, message: 'Bitte Tierrasse eingeben!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Geschlecht"
                        name="geschlecht"
                        rules={[{ required: true, message: 'Bitte eine Auswahl treffen!' }]}
                    >
                        <Select placeholder="Bitte auswählen">
                            <Select.Option value="männlich">Männlich</Select.Option>
                            <Select.Option value="weiblich">Weiblich</Select.Option>
                            <Select.Option value="unbestimmt">Unbestimmt</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Über mich"
                        name="about"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item >
                        <Button style={{ backgroundColor: "#25D2AA", color: "white", height: "45px", fontSize: 18 }} shape="round" htmlType="submit" block>
                            Speichern
                        </Button>
                        <div class="popup">
                            <span class="popuptext" id="myPopupconfirm">Profil aktualisiert!</span>
                        </div>
                    </Form.Item>
                </Form>
            </Paper>
        </div>);
    }


}

export default AnimalDetails
