import React from "react"
import Remote from "./Remote"
import Local from "./Local"
import { Divider } from "antd"
import Grid from '@material-ui/core/Grid';
import _ from 'lodash'
import IconButton from '@material-ui/core/IconButton';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CallEndIcon from '@material-ui/icons/CallEnd';
import UploadService from "../../services/upload.service";
import store from 'store';
import 'dotenv/config';


class CallComp extends React.Component {

    constructor(props) {
        super(props)
        console.log("CallComp.js: props: ", this.props);
        this.state = {
            serverURL: process.env.REACT_APP_JITSI_AUTHORITY, //'callavet-conferencing.de',
            roomId: 'general',
            connected: false,
            defaultMicId: '',
            defaultVideoId: '',
            defaultSpeakerId: '',
            deviceList: [],
            loaded: false,
            activeConnection: null,
            activeRoom: null,
            remoteTracks: [],
            currentSpeakerId: -1,
            sessionID: null
        }
    }

    componentDidMount() {
        const audioOutput = window.JitsiMeetJS.mediaDevices.getAudioOutputDevice();
        console.log("CallComp.js: componentDidMount() audioOutput: ", audioOutput);
        console.log("CallComp.js: componentDidMount() window.JitsiMeetJS.mediaDevices: ", window.JitsiMeetJS.mediaDevices);
        window.JitsiMeetJS.mediaDevices.enumerateDevices((devices) => {
            console.log("CallComp.js: componentDidMount() devices: ", devices);
            let newDeviceList = [{ name: "Audio Output", id: audioOutput, type: 'audiooutput' }]
            for (let device of devices) {
                // if (device.deviceId !== 'default' && device.deviceId !== 'communications') {
                newDeviceList.push({ name: device.label, id: device.deviceId, type: device.kind })
                // }
            }
            let micId = (_.find(newDeviceList, { type: 'audioinput' }) || {}).id || 'none'
            let videoId = (_.find(newDeviceList, { type: 'videoinput' }) || {}).id || 'none'
            let speakerId = audioOutput || 'none'
            this.setState({
                deviceList: newDeviceList,
                defaultMicId: micId,
                defaultVideoId: videoId,
                defaultSpeakerId: speakerId,
                loaded: true,
                tracksUpdated: 0,
                roomId: this.props.sessionName
            }, () => {
                this.connect()
            });
        })
    }

    componentWillUnmount() {
        try {
            if (this.state.activeRoom) {
                this.state.activeRoom.leave()
                    .then(() => {
                        if (this.state.activeConnection) {
                            try {
                                this.state.activeConnection.disconnect();
                            }
                            catch (e) {
                                console.log(e)
                            }
                        }
                    })
                    .catch(e => {
                        console.log(e);
                        if (this.state.activeConnection) {
                            try {
                                this.state.activeConnection.disconnect();
                            }
                            catch (e) {
                                console.log(e)
                            }
                        }
                    });
            }
        }
        catch (e) {
            console.log(e)
        }
    }

    onRoomTrackAdded = (track) => {
        if (track.isLocal() === true) {
            return
        }
        let trackInfo = {
            id: track.getId(),
            type: track.getType(),
            track: track
        };
        const tracks = this.state.remoteTracks;
        if (tracks.filter(e => e.key === track.getParticipantId()).length > 0) {
            _.find(tracks, { "key": track.getParticipantId() }).value.push(trackInfo);
            this.setState({ remoteTracks: tracks }, () => this.setState({ tracksUpdated: this.state.tracksUpdated + 1 }));
        }
        else {
            tracks.push({ "key": track.getParticipantId(), "value": [trackInfo] });
            this.setState({ remoteTracks: tracks });
        }
    }

    onRoomTrackRemoved = (track) => {
        const tracks = this.state.remoteTracks;
        const participant = _.find(tracks, { "key": track.getParticipantId() })
        if (participant) {
            tracks.splice(participant.value.findIndex(e => e.id === track.getId()), 1);
            this.setState({ remoteTracks: tracks });
        }
    }

    trackAudioLevelChanged = (participantId, audioLevel) => {
        if (audioLevel > 0.6) {
            this.setState({ currentSpeakerId: participantId })
        }
    }

    startRecording = () => {
        this.state.activeRoom.startRecording({ mode: "file" })
            .then(session => {
                this.setState({ sessionID: session.getID() });
            })
            .catch(e => {
                console.log(e);
            });
    }

    stopRecording = () => {
        this.state.activeRoom.stopRecording(this.state.sessionID);
    }

    onConnectionFailed = (a, b, c, d) => {
        // Show error message
        console.log("CallComp.js: onConnectionFailed() ", a, b, c, d);
    }

    onConnectionDisconnect = (e) => {
        //return to dashboard
        console.log("CallComp.js: onConnectionDisconnect() ", e);
    }

    onConnectionSuccess = () => {
        const { roomId } = this.state
        try {
            this.setState({
                connected: true,
                activeRoom: this.state.activeConnection.initJitsiConference(roomId, {
                    openBridgeChannel: true,
                })
            }, () => {
                this.state.activeRoom.addEventListener(window.JitsiMeetJS.events.conference.TRACK_ADDED, this.onRoomTrackAdded)
                this.state.activeRoom.addEventListener(window.JitsiMeetJS.events.conference.TRACK_REMOVED, this.onRoomTrackRemoved)
                this.state.activeRoom.addEventListener(window.JitsiMeetJS.events.conference.TRACK_AUDIO_LEVEL_CHANGED, this.trackAudioLevelChanged)
                this.state.activeRoom.setDisplayName(store.get('name'));
                //Enter password in this Method if password is needed
                this.state.activeRoom.join()
            });
        } catch (error) {
            this.setState({
                status: 'closed',
                lastError: error.message
            })
        }
    }

    connect = () => {
        const { roomId, serverURL } = this.state;
        console.log("CallComp.js, connect() this.state: ", this.state);
        /*
        let jitsiConfig = {
            hosts: {
                domain: serverURL,
                muc: `conference.${serverURL}` // FIXME: use XEP-0030
            },
            serviceUrl: `wss://${serverURL}/xmpp-websocket?room=${roomId}`, //`wss://${serverURL}/xmpp-websocket?room=${roomId}`,
            // serviceUrl: `https://${serverURL}/http-bind?room=${roomId}`,
            clientNode: `https://${serverURL}`
        }
        */
        let jitsiConfig = {
            hosts: {
                anonymousdomain: "meet.jitsi",
                authdomain: "auth.meet.jitsi",
                domain: "meet.jitsi",
                muc: "muc.meet.jitsi" //`muc.${serverURL}` // FIXME: use XEP-0030
            },
            serviceUrl: `wss://${serverURL}/xmpp-websocket?room=${roomId}`, //`wss://${serverURL}/xmpp-websocket?room=${roomId}`,
            clientNode: `https://${serverURL}`
        }
        console.log("CallComp.js, connect() jitsiConfig: ", jitsiConfig);
        this.setState({
            activeConnection: new window.JitsiMeetJS.JitsiConnection(null, null, jitsiConfig)
        }, () => {
            this.state.activeConnection.addEventListener(window.JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED, this.onConnectionSuccess)
            this.state.activeConnection.addEventListener(window.JitsiMeetJS.events.connection.CONNECTION_FAILED, this.onConnectionFailed)
            this.state.activeConnection.addEventListener(window.JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED, this.onConnectionDisconnect)
            this.state.activeConnection.connect()
        });
    }

    detectMob() {
        return ( ( window.innerWidth <= 800 ) && ( window.innerHeight <= 1000 ) );
    }

    render() {
        if (!this.state.loaded) {
            return <div />
        }
        if (this.detectMob()) {
            return (
                <div style={{ display: "flex", flexDirection: "column" , alignItems:"center"}}>
                <Grid container spacing={3} style={{ width: "100%" }}>
                    {this.state.connected ?
                            <Grid item xs={10}>
                                <Local deviceList={this.state.deviceList} defaultMicId={this.state.defaultMicId} defaultVideoId={this.state.defaultVideoId} activeRoom={this.state.activeRoom} />
                            </Grid>
                            :
                        null}
                    {this.state.remoteTracks.map(({ key, value }) => {
                        const participant = this.state.activeRoom.getParticipantById(key);
                        return (
                            <Grid item xs={10}>
                                <Remote participant={participant} participantId={key} tracks={value} currentSpeaker={this.state.currentSpeakerId} tracksUpdated={this.state.tracksUpdated} />
                            </Grid>
                        );
                    })}
                </Grid>
            </div>
            );
        }
        return (
            <div style={{ display: "flex", flexDirection: "column" , alignItems:"center"}}>
                <Grid container spacing={3} style={{ width: "100%" }}>
                    {this.state.connected ?
                        <Grid item xs={6}>
                            <Local deviceList={this.state.deviceList} defaultMicId={this.state.defaultMicId} defaultVideoId={this.state.defaultVideoId} activeRoom={this.state.activeRoom} />
                        </Grid>
                        :
                        null}
                    {this.state.remoteTracks.map(({ key, value }) => {
                        const participant = this.state.activeRoom.getParticipantById(key);
                        return (
                            <Grid item xs={6}>
                                <Remote participant={participant} participantId={key} tracks={value} currentSpeaker={this.state.currentSpeakerId} tracksUpdated={this.state.tracksUpdated} />
                            </Grid>
                        );
                    })}
                </Grid>
            </div>
        );
    }
}

export default CallComp
