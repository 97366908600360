import React, { useState } from "react";
import Logo from "./assets/HelloVetLogoUser.png"
import CheckIcon from "./assets/CheckIcon.png"
import Background from "./assets/BackgroundLeft.png"
import Corner from "./assets/InfosCorner.png"
import { Form, Input, Button, Checkbox, Select, Divider } from 'antd';
import DatabaseService from "../services/database.service"
import store from "store"
import { Redirect } from "react-router-dom";
import Axios from "axios";
import { sha256 } from 'js-sha256';
import ChatMGMT from "../http-chat"
import whereAmI from "../whereAmI";

function Registrieren() {
    const [submitted, setSubmitted] = useState(false);

    async function rollback(userId) {
        console.log("Rollback user ", userId);
        await DatabaseService.animalOwner.delete(userId);
    }

    const onFinish = values => {
        let data = {
            vorname: values.vorname,
            nachname: values.nachname,
            username: values.username,
            email: values.email,
            passwort: sha256(values.passwort)
        };

        ChatMGMT.post({
            mail: values.email,
            name: values.vorname + " " + values.nachname,
            password: sha256(values.passwort),
            username: values.username
        })
            .catch(e => {
                console.log(e);
            });

        DatabaseService.animalOwner
            .checkEmail(values.email)
            .then(response => {
                if (response.data.result) {
                    console.log("E-mail verfügbar!");
                    return;
                } else {
                    DatabaseService.animalOwner
                        .create(data)
                        .then(response => {
                            console.log("Vet created ", response);
                            const userId = response.data.id;
                            store.set('loggedIn', true);
                            store.set('pwHash', sha256(values.passwort));
                            store.set('usrId', response.data.id);
                            store.set('redirectPage', "/dashboard");
                            setSubmitted(true);

                            DatabaseService.registration.mail
                                .create({
                                    mail: values.email,
                                    token: response.data.token,
                                    origin: whereAmI()
                                })
                                .then(response => {
                                    console.log("Registrieren.js: registration.mail.create() ", response.status, response.statusText);
                                })
                                .catch(e => {
                                    console.log("Registrieren.js: registration.mail.create() ", e);
                                    this.rollback(userId);
                                    return;
                                })
                        })
                        .catch(e => {
                            console.log("Registrieren.js: animalOwner.create() ", e);
                            return;
                        });
                }
            })
            .catch(e => {
                console.log("Registrieren.js: animalOwner.checkEmail() ", e);
                return;
            });
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    if (submitted) {
        return (
            <Redirect to="validation-required" />
        );
    }

    return (
        <div style={{ width: "100%", height: "100vh", display: "flex", flexDirection: "row" }}>
            <img src={Corner} style={{ position: "absolute", right: "0px", top: "0px", height: "15%", zIndex: 0 }} />
            <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", backgroundImage: `url(${Background})`, backgroundPosition: "left center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                <img src={Logo} style={{ width: "400px" }} />
                <div style={{ display: "flex", flexDirection: "column", width: "500px" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                        <img src={CheckIcon} style={{ height: "80px", marginTop: 15 }} />
                        <span style={{ marginTop: 15, fontSize: 22, fontFamily: "WorkSans" }} >Bequem von Zuhause ohne Anfahrt und Wartezimmerstress für dein Tier</span>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                        <img src={CheckIcon} style={{ height: "80px", marginTop: 15 }} />
                        <span style={{ marginTop: 15, fontSize: 22, fontFamily: "WorkSans" }} >Beratung per Videochat durch erfahrene Tierärzte</span>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                        <img src={CheckIcon} style={{ height: "80px", marginTop: 15 }} />
                        <span style={{ marginTop: 15, fontSize: 22, fontFamily: "WorkSans" }} >Löst viele Probleme ohne weiteren Arztbesuch im Anschluss</span>
                    </div>
                </div>
            </div>
            <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <span style={{ marginBottom: 30, fontSize: 22, fontFamily: "WorkSans", fontWeight: "bold" }} >Jetzt kostenlos registrieren</span>
                <Form
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        label="Benutzername"
                        name="username"
                        rules={[{ required: true, message: 'Bitte Benutzername eingeben!' }]}
                    >
                        <Input placeholder="max.mustermann" />
                    </Form.Item>

                    <Form.Item
                        label="Vorname"
                        name="vorname"
                        rules={[{ required: true, message: 'Bitte Vorname eingeben!' }]}
                        style={{ display: 'inline-block', width: 'calc(50% - 10px)' }}
                    >
                        <Input placeholder="Max" />
                    </Form.Item>
                    <Form.Item
                        label="Nachname"
                        name="nachname"
                        rules={[{ required: true, message: 'Bitte Nachname eingeben!' }]}
                        style={{ display: 'inline-block', width: 'calc(50% - 10px)', margin: '0 10px' }}
                    >
                        <Input placeholder="Mustermann" />
                    </Form.Item>

                    <Form.Item
                        label="E-Mail Addresse"
                        name="email"
                        rules={[{ required: true, message: 'Bitte E-Mail eingeben!' }]}
                    >
                        <Input placeholder="max.mustermann@net.de" />
                    </Form.Item>

                    <Form.Item
                        label="Passwort"
                        name="passwort"
                        rules={[{ required: true, message: 'Bitte Passwort eingeben!' }]}
                    >
                        <Input.Password />
                    </Form.Item>


                    <Form.Item
                        name="agb"
                        valuePropName="checked"
                        rules={[
                            {
                                validator: (_, value) =>
                                    value ? Promise.resolve() : Promise.reject('Geschäftsbedingungen zustimmen!'),
                            },
                        ]}
                    >
                        <Checkbox>Ich habe die AGBs gelesen und stimme zu</Checkbox>
                    </Form.Item>

                    <Form.Item >
                        <Button style={{ backgroundColor: "#25D2AA", color: "white", height: "45px", fontSize: 18 }} shape="round" htmlType="submit" block>
                            Kostenlos registrieren
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}

export default Registrieren
