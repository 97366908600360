import React from "react"
import Paper from '@material-ui/core/Paper';
import Pfote from "./Pfote.png";
import { Layout, Menu, Button, Avatar, List, Popover, Form, Input } from 'antd';
import DatabaseService from "../services/database.service"
import { motion, AnimateSharedLayout, AnimatePresence } from "framer-motion";
import Results from "./search-components/Results"
import ProfilePage from "./search-components/ProfilePage"
import PraxisProfile from "./search-components/PraxisProfile"
import { UserOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import GeocodingService from '@mapbox/mapbox-sdk/services/geocoding';
import isLoggedIn from "../is_logged_in"
import store from 'store';
import "./UserSearch.css"

const { Header, Footer, Sider, Content } = Layout;
const spring = {
    type: "spring",
    stiffness: 500,
    damping: 30
};

class UserSearch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            doctors: [],
            clinics: [],
            menuOption: 1,
            selectedDoctor: {},
            selectedClinic: {},
            showClinicProfile: false,
            geocodingClient: new GeocodingService({ accessToken: 'pk.eyJ1IjoiZG9taW5pay1jYXYiLCJhIjoiY2tsMWNlZW1rMHVhMTJwcGVkaXo0YnpucCJ9.implwE-8ZbHzk49FRRZGDw' }),
            plz: "83737",
            popoverVisible: false
        }

        if (isLoggedIn()) {
            DatabaseService.animal
                .read(parseInt(store.get('usrId'), 10))
                .then(response => {
                    this.setState({ animalInfo: response.data.records, petsReady: true });
                })
                .catch(e => {
                    console.log(e);
                });
        }
    }

    componentDidMount() {
        this.state.geocodingClient.forwardGeocode({
            query: this.state.plz,
            countries: ['de'],
            limit: 2
        })
            .send()
            .then(response => {
                this.searchVets({ lng: response.body.features[0].center[0], lat: response.body.features[0].center[1] });
            });
    }

    showProfile = (selectedDoctor) => {
        this.setState({ selectedDoctor: selectedDoctor, showClinicProfile: false, menuOption: 2 });
    }

    showClinic = (selectedClinic) => {
        this.setState({ selectedClinic: selectedClinic, showClinicProfile: true, menuOption: 2 });
    }

    goBack = () => {
        this.setState({ menuOption: 1 });
    }

    render() {
        if (!this.state.doctors || this.state.doctors.length === 0)
            return (
                <div />
            )
        return (
            <Layout style={{ height: "100vh", backgroundColor: "#fffcf7" }}>
                <Sider theme="light" style={{ display: "flex", flexDirection: "column", backgroundColor: "transparent" }}>
                    <div style={{ flex: 1, height: "50%" }}>
                        <img src={Pfote} height="55" alt="" loading="eager" />
                        <AnimateSharedLayout>

                            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginTop: 30 }}>
                                {this.state.menuOption === 1 ? (<motion.div className="verticalLineGreen" layoutId="marker" transition={spring} />) : (<div style={{ width: "26px" }} />)}
                                <span style={{ fontWeight: "bold", color: this.state.menuOption === 1 ? "#25D2AA" : "black" }}>1. Tierarzt Suche</span>
                            </div>
                            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginTop: 15 }}>
                                {this.state.menuOption === 2 ? (<motion.div className="verticalLineGreen" layoutId="marker" transition={spring} />) : (<div style={{ width: "26px" }} />)}
                                <span style={{ fontWeight: "bold", color: this.state.menuOption === 2 ? "#25D2AA" : "black" }}>2. Termin vereinbaren</span>
                            </div>
                            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginTop: 15 }}>
                                {this.state.menuOption === 3 ? (<motion.div className="verticalLineGreen" layoutId="marker" transition={spring} />) : (<div style={{ width: "26px" }} />)}
                                <span style={{ fontWeight: "bold", color: this.state.menuOption === 3 ? "#25D2AA" : "black" }}>3. Termin bestätigen</span>
                            </div>
                        </AnimateSharedLayout>
                    </div>
                    <div style={{ flex: 1, height: "50%", display: "flex", justifyContent: "center", alignItems: "flex-end" }}>
                        <Button size="large" style={{ marginBottom: 50, borderColor: "black", color: "black", width: "160px" }} ghost shape="round" onClick={() => this.setState({ showAppointment: true })}>Anmelden</Button>
                    </div>
                </Sider>
                <Layout>
                    <Content style={{ display: "flex", overflow: "auto", backgroundColor: "#fffcf7", paddingLeft: 50 }}>
                        {this.state.menuOption === 1 && <Results showProfile={id => this.showProfile(id)} showClinic={id => this.showClinic(id)} plz={this.state.plz} />}
                        {this.state.menuOption === 2 && !this.state.showClinicProfile && <ProfilePage showClinic={id => this.showClinic(id)} animalInfo={this.state.animalInfo} doctor={this.state.selectedDoctor} />}
                        {this.state.menuOption === 2 && this.state.showClinicProfile && <PraxisProfile showProfile={id => this.showProfile(id)} animalInfo={this.state.animalInfo} clinic={this.state.selectedClinic} />}
                    </Content>
                </Layout>
            </Layout>
        );
    }

}

export default UserSearch