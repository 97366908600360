import React, { Component } from "react";
import FileList from "./filesList"
import Paper from '@material-ui/core/Paper';
import { Tabs, Form, Input, InputNumber, Button } from 'antd';

class PetPopover extends Component {
  render() {
    const { TabPane } = Tabs;
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };
    return (
    <Paper style={{ padding: 15, height: "100%", width: "100%", minWidth: "300px", display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'stretch', backgroundColor: "white" }}>
      <Tabs defaultActiveKey="1" size="large" centered={true}>
        <TabPane tab="Unterlagen" key="1">
          <FileList animalId={this.props.animalId} />
        </TabPane>
        <TabPane tab="Profil" key="2">
        <Form {...layout} name="nest-messages">
          <Form.Item name= 'name'  label="Name">
            <Input />
          </Form.Item>
          <Form.Item name= 'tierart'  label="Tierart">
                      <Input />
          </Form.Item>
          <Form.Item name='tierrasse' label="Tierrasse">
            <Input />
          </Form.Item>
          <Form.Item label="Alter" name="alter">
            <InputNumber />
          </Form.Item>
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
            <Button type="primary" htmlType="submit">
              Update
              </Button>
          </Form.Item>
        </Form>
        </TabPane>
      </Tabs>
    </Paper>
    );

  }
}

export default PetPopover
