import React, { Component, useEffect, useState } from "react";
import { useJitsi } from 'react-jutsu'
import { Redirect } from "react-router-dom";
import store from 'store'
import FileList from "./FileListDialog"
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import IconButton from '@material-ui/core/IconButton';
import UploadService from "./services/upload.service"


function JitsiHook(props) {

  const roomName = props.sessionCreds.sessionId
  const parentNode = 'jitsi-container'
  const interfaceConfigOverwrite = {
    TOOLBAR_BUTTONS: [
        'microphone', 'camera', 'hangup', 'chat',
        'videoquality', 'filmstrip',
        'tileview', 'videobackgroundblur'
    ],
   }
  const [dashboard, setDashboard] = useState(false);
  const jitsi = useJitsi({ roomName, parentNode , interfaceConfigOverwrite}, "callavet-conferencing.de")

  useEffect(() => {
    if (jitsi) {
      jitsi.addEventListener('videoConferenceJoined', () => {
        jitsi.executeCommand('displayName', store.get("name"))
      })
      jitsi.addEventListener('videoConferenceLeft', () => {
        setDashboard(true)
      })
    }
    return () => jitsi && jitsi.dispose()
  }, [jitsi])

  if(dashboard)return <Redirect to={store.get("redirectPage")} />;

  return <div id={parentNode} style={{ height: props.height, width: props.width, overflow:"hidden" }} />;

}

class JitsiComp extends Component {

  /*Always start the dev server in https mode, otherwise chrome or firefox will block camera and audio access*/

  constructor(props) {
    super(props);
    document.body.style.overflow = "hidden";
    this.state = {
      width: 0,
      height: 0,
      fileDialog: false,
      sessionCreds:null,
      dataLoaded:false
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    UploadService.
    sessionActive(store.get('usrId'))
      .then(response => {
        this.setState({sessionCreds:response.data, dataLoaded:true});
      })
      .catch(e => {
        console.log(e);
      });
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  closeFileFialog(){
    this.setState({ fileDialog: false });
  }

  render() {
    if(!this.state.dataLoaded){
        return(<div/>);
    }
    return (
      <>
      <JitsiHook width={this.state.width} height={this.state.height} sessionCreds={this.state.sessionCreds}/>
      <IconButton onClick={()=>this.setState({ fileDialog: true})} style={{ position: "absolute", left:"50%", top:"18px",  zIndex: 10}}>
        <DescriptionOutlinedIcon style={{fontSize:40, color:"#DAEBFA"}} />
      </IconButton>
      <FileList
        open={this.state.fileDialog}
        onClose= {()=>this.closeFileFialog()}
        animalId={this.state.sessionCreds.petId}/>
        </>
    );
  }

}

export default JitsiComp
