import React, { Component } from "react";
import Paper from '@material-ui/core/Paper';
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import ButtonBase from "@material-ui/core/ButtonBase";
import store from 'store'
import DatabaseService from "../../services/database.service";

class AnimalsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      animalInfo: [{ name: "", tierart: "", tierrasse: "", alter: "" }],
    }

    const data = { id: parseInt(store.get('usrId'), 10) };

    DatabaseService.animal.read(data)
      .then(response => {
        console.log(response.data);
        this.setState({ animalInfo: response.data });
      })
      .catch(e => {
        console.log(e);
      });
  }

  render() {
    return (
      <GridList cols={1}>
        {this.state.animalInfo.map((info, index) => {
          return (
            <GridListTile key={index} cols={1}>
              <ButtonBase
                style={{ height: "100%", width: "100%" }}
                onClick={() => this.props.onChoose(info)}
              >
                <Paper style={{ display: "flex", flexDirection: 'column', justifyContent: 'space-around', alignContent: 'stretch', height: "90%", width: "90%" }}>
                  <span style={{ color: "#1e90ff", fontSize: 30, fontWeight: 'bold', textAlign: 'center' }}>{info.name}</span>
                  <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between' , margin: 8}}>
                    <div>
                      <span style={{ fontSize: 25, color:"#1e90ff"}}>Tierart: </span>
                      <span style={{ fontSize: 25 }}>{info.tierrasse}</span>
                    </div>
                    <div>
                      <span style={{ fontSize: 25, color:"#1e90ff"}}>Alter: </span>
                      <span style={{ fontSize: 25 }}>{info.tierAlter}</span>
                    </div>
                  </div>
                </Paper>
              </ButtonBase>
            </GridListTile>
          );
        })}
      </GridList>
    );
  }

}

export default AnimalsList;
