
class ClinicProfileAction {
    /**
     * ClinicProfile Action Class
     */
    constructor(axios) {
        this.http = axios;        
    }
    

    /**
     * Create one clinic profile
     * @param {Object} data 
     * @returns Promise object
     */
    create(data) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Read all clinics
     * @returns Promise object
     */
    read() {
        return this.http.get("/api/clinicprofile/read.php");
    };
    /**
     * Read one clinic profile
     * @param {Object} id clinic profile id
     * @returns Promise object
     */
    readOne(id) {
        return this.http.get(`/api/clinicprofile/read_by_index.php?id=${id}`);
    };
    /**
     * Update one clinic profile
     * @param {Object} data
     * @returns Promise object
     */
    update(data) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Delete one clinic profile
     * @param {Object} id clinic profile id
     * @returns Promise object
     */
    delete(id) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Read all accepted vets, but not admin
     * @param {Object} clinicId clinic profile id
     * @returns Promise object
     */
    readAccepted(clinicId) {
        return Promise.reject('NOT_IMPLEMENTED');
    };
    /**
     * Read all accepted vets, marked as admin
     * @param {Object} clinicId clinic profile id
     * @returns Promise object
     */
    readAdmin(clinicId) {
        return this.http.get(`/api/clinicprofile/get_staff.php?id=${clinicId}`);
    };
    /**
     * Search clinics
     * @param {Object} data clinic data, gps...
     * @returns Promise object
     */
    search(data) {
        return this.http.post("/api/clinicprofile/search.php", data);
    };

}

export default ClinicProfileAction;