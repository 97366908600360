import axios from "../http-database";
import Animal from './actions/animal';
import AnimalOwner from './actions/animalOwner';
import Clinic from './actions/clinic';
import Event from './actions/event';
import Notification from './actions/notification';
import Registration from './actions/registration';
import Vet from './actions/vet';
import Documents from './actions/documents';



class DatabaseService {

    constructor() {
        this._animal = new Animal(axios);
        this._animalOwner = new AnimalOwner(axios);
        this._clinic = new Clinic(axios);
        this._event = new Event(axios);
        this._notification = new Notification(axios);
        this._registration = new Registration(axios);
        this._vet = new Vet(axios);
        this._documents = new Documents(axios);
    }

    /**
     * Animal Service
     */
    get animal() {
        return this._animal;
    }

    /**
     * Animal Owner Service
     */
    get animalOwner() {
        return this._animalOwner;
    }

    /**
     * Clinic Service
     */
    get clinic() {
        return this._clinic;
    }

    /**
     * Event Service
     */
    get event() {
        return this._event;
    }

    /**
     * Event Service
     */
    get notification() {
        return this._notification;
    }

    /**
     * Registration Service
     */
    get registration() {
        return this._registration;
    }

    /**
     * Vet Service
     */
    get vet() {
        return this._vet;
    }

    /**
         * Document Service
         */
    get documents() {
        return this._documents;
    }

    // getAllClinics() {
    //     return http.get("/api/clinicprofile/read.php");
    // }

    // searchClinics(data) {
    //     return http.post("/api/clinicprofile/search.php", data);
    // }

    // getClinicInfo(id) {
    //     return http.get(`/api/clinicprofile/read_by_index.php?id=${id}`);
    // }

    // getClinicStaff(id) {
    //     return http.get(`/api/clinicprofile/get_staff.php?id=${id}`);
    // }



    // createEvent(data) {
    //     return http.post("/api/events/create.php", data);
    // }

    // getEventsUsers(id) {
    //     return http.get(`/api/events/read_by_index_user.php?id=${id}`);
    // }



    // getNotifications(id) {
    //     return http.get(`/api/notifications/read_by_index.php?id=${id}`);
    // }

    // createRegistrationMail(data) {
    //     return http.post("/api/mailservice/registration_mail.php", data);
    // }





    // createPet(data) {
    //     return http.post("/api/animalbase/create.php", data);
    // }

    // getAllPets(id) {
    //     return http.get(`/api/animalbase/read_by_index.php?id=${id}`);
    // }





    // getUsers(id) {
    //     return http.get(`/api/userbase/read_by_index.php?id=${id}`);
    // }

    // kommt 2x vor
    // checkUsernameAvail(id) {
    //     return http.post(`/check_username.php`, {
    //         id: id
    //     });
    // }

    // checkEmailAvail(id) {
    //     return http.get(`/api/userbase/check_email.php?mail=${id}`);
    // }

    // createUsers(data) {
    //     return http.post("/api/userbase/create.php", data);
    // }

    // checkPW(data) {
    //     return http.get(`/api/userbase/auth.php?mail=${data.email}&passwort=${data.passwort}`);
    // }

    // updateUsers(data) {
    //     return http.patch("/api/userbase/update.php", data);
    // }

    // deleteUser(id) {
    //     return this.http.delete(`/api/userbase/delete.php?id=${id}`);
    // }

    // updateUserProfilePic(data) {
    //     return http.patch("/api/userbase/update_avatar.php", data);
    // }





    // getAllVets() {
    //     return http.get("/api/vetprofile/read.php");
    // }

    // getVets(id) {
    //     return http.get(`/api/vetprofile/read_by_index.php?id=${id}`);
    // }

    // searchVets(data) {
    //     return http.post("/api/vetprofile/search.php", data);
    // }

    // getVetProfile(id) {
    //     return http.get(`/api/vetprofile/read_vet_clinic.php?id=${id}`);
    // }








    // createVets(data) {
    //     return http.post("/create_vet.php", data);
    // }

    // checkPWVets(data) {
    //     return http.post("/check_pw_vet.php", data);
    // }

    // updateVets(id, data) {
    //     return http.put(`/update_vet.php/?id=${id}`, data);
    // }


    // getPet(id) {
    //     return http.get(`/get_pet.php/?id=${id}`);
    // }

    // No api end point available! 'get_events.php' does not exists!
    // getEvents(data) {
    //     return http.put("/get_events.php", data);
    // }


    // getEventsVets(data) {
    //     return http.put("/get_vet_events.php", data);
    // }

    // updateEventsVets(id, data) {
    //     return http.put(`/update_event.php/?id=${id}`, data);
    // }

    // createClinic(data) {
    //     return http.post("/create_clinic.php", data);
    // }



}

export default new DatabaseService();
