import React from 'react';
import { Select, Descriptions, Button } from 'antd';

class VetUebersicht extends React.Component {

  constructor(props){
    super(props);
    this.state={
      currentPet: -1,
    }
  }


  onChange(checkedValues) {
    console.log('checked = ', checkedValues);
  }

  render(){
    const options = this.props.animalInfo.map(obj =>{
      return {label: obj.name,value: obj.name}
    });


      return(
        <>
          <h1>Übersicht</h1>
          <h4 style={{ marginTop:20 }}>Ihr Tier</h4>
          <Select size="large" style={{ width: "70%" }} options={options} onChange={(value, option)=>
            this.setState({currentPet:option.key})
          }/>
          <h4 style={{ marginTop:20 }}>Ausgewählter Arzt</h4>
          <Descriptions style={{marginTop:15}} title={<h5>{this.props.currentDoctor.titel + " "+this.props.currentDoctor.nachname }</h5>}>
            <Descriptions.Item><h5>Fachgebiet</h5></Descriptions.Item>
            <Descriptions.Item span={2}><span style={{fontSize:20}}>{this.props.currentDoctor.fachrichtung}</span></Descriptions.Item>
            <Descriptions.Item><h5>Preis</h5></Descriptions.Item>
            <Descriptions.Item span={2}><span style={{fontSize:20}}>{this.props.currentDoctor.rate}€ / {this.props.currentDoctor.slotLength}min</span></Descriptions.Item>
            <Descriptions.Item ><h5>Entfernung</h5></Descriptions.Item>
            <Descriptions.Item span={2}><span style={{fontSize:20}}>6km</span></Descriptions.Item>
          </Descriptions>
          <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width:"100%"}}>
       <Button disabled={this.state.currentID===-1} onClick={()=>{this.props.setLeftPane('profile');this.props.setRightPane('terminVerein'); this.props.setCurrentDoctor(this.props.currentDoctor.id); this.props.setCurrentPet(this.state.currentPet)}}  type="primary" style={{marginTop:20, backgroundColor:"black", width:"100%", fontSize:20, height:60}}>Weiter zur Tierarztübersicht</Button>
        </div>
        </>
      );
  }


}

export default VetUebersicht
