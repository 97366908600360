import React from 'react';
import { Select, Steps, Divider, Input, Space, Spin, Modal, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import DatabaseService from "../../services/database.service";
import moment from "moment";
import store from 'store';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
// https://github.com/prettymuchbryce/http-status-codes
import { StatusCodes as HttpStatus } from 'http-status-codes';

const { Step } = Steps;
const { Option } = Select;

let index = 0;

const localizer = momentLocalizer(moment);

class TerminVereinbaren extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            events: null,
            currentStep: 0,
            doctor: null,
            backdropOpen: true,
            localEvents: [],
            fields: { form: [] },
            petID: this.props.currentPet,
            formData: "",
            items: ['Jahresuntersuchung'],
            name: '',
            dataLoaded: false,
            modalVisible: false,
            terminButton: true,
            selectedVal: ""
        }

        DatabaseService.vet.profile
            .readOne(this.props.currentDoctor)
            .then(response => {
                console.log("TerminVereinbaren.js: vet.profile.readOne() ", response.status, response.data);
                if (response.status === HttpStatus.OK) {
                    this.setState({ doctor: response.data, fields: JSON.parse(response.data.form), dataLoaded: true });
                    this.handleEventUpdate();
                }
            })
            .catch(e => {
                console.log(e);
            });
    }

    handleEventUpdate() {
        DatabaseService.getEvents({ id: this.state.doctor.id })
            .then(response => {
                console.log("TerminVereinbaren.js: getEvents() ", response.status, response.data);
                if (response.status === HttpStatus.OK) {
                    var mappedData = response.data.map((singleEvent) => {
                        return { id: singleEvent.id, title: "Terminanfrage", start: new Date(singleEvent.time), end: new Date(new Date(singleEvent.time).getTime() + this.state.doctor.slotLength * 60000) };
                    });
                    this.setState({ events: mappedData, backdropOpen: false });
                }
            })
            .catch(e => {
                console.log(e);
            });
    }

    customSlotPropGetter = date => {
        if (this.state.events === null || this.state.events.length === 0) return {}
        if (this.state.events.some((element) => element.start.valueOf() <= date.valueOf() && element.end.valueOf() > date.valueOf()))
            return {
                style: {
                    backgroundColor: '#faa'
                },
            }
        else return {}
    }

    onFinish = values => {
        console.log(values);
        this.setState({ currentStep: 2 });
    };

    handleSelection = () => {
        const data = {
            besuchsgrund: this.state.selectedVal,
            vetID: this.state.doctor.id,
            vetName: this.state.doctor.vorname + " " + this.state.doctor.nachname,
            userID: store.get('usrId'),
            userName: this.props.user.vorname + " " + this.props.user.nachname,
            time: this.state.localEvents[0].start,
            slotlength: this.state.doctor.slotlaenge,
            petId: this.state.petID,
            petName: this.props.animalInfo.find(el => el.id === this.state.petID).name,
            form: JSON.stringify(this.state.fields)
        };

        DatabaseService.event
            .create(data)
            .then(response => {
                console.log("TerminVereinbaren.js: event.create() ", response.status, response.data);
                if (response.status === HttpStatus.OK) {
                    console.log(response.data);
                    this.setState({ modalVisible: false })
                    window.location.reload();
                }
            })
            .catch(e => {
                console.log(e);
            });
    };


    onNameChange = event => {
        this.setState({
            name: event.target.value,
        });
    };

    onSelectSlot = (start, end) => {
        if (this.state.fields && this.state.fields.startsWith("{\"form\"")) {
            this.setState({ currentStep: this.state.currentStep + 1, localEvents: [{ title: "Terminanfrage", start: start, end: end }], modalVisible: true })
        }
        else {
            this.setState({ currentStep: this.state.currentStep + 1, localEvents: [{ title: "Terminanfrage", start: start, end: end }], terminButton: false })
        }
    };

    addItem = () => {
        console.log('addItem');
        const { items, name } = this.state;
        this.setState({
            items: [...items, name || `New item ${index++}`],
            name: '',
        });
    };

    render() {
        const { items, name } = this.state;
        if (!this.state.dataLoaded) {
            return (
                <Space style={{ height: "100%" }}>
                    <Spin size="large" />
                </Space>
            );
        }
        return (
            <>
                <h1>Termin online vereinbaren</h1>
                <h4>Füllen Sie die folgenden Felder aus:</h4>

                <Steps direction="vertical" current={this.state.currentStep}>
                    <Step title={<h5>Besuchsgrund</h5>} description={
                        <Select
                            style={{ width: "50%" }}
                            size="large"
                            onChange={(value) => this.setState({ currentStep: this.state.currentStep + 1, selectedVal: value })}
                            placeholder="Besuchsgrund auswählen"
                            dropdownRender={menu => (
                                <div>
                                    {menu}
                                    <Divider style={{ margin: '4px 0' }} />
                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                        <Input style={{ flex: 'auto' }} value={name} onChange={this.onNameChange} />
                                        <a
                                            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                            onClick={this.addItem}
                                        >
                                            <PlusOutlined /> Grund hinzufügen
                                        </a>
                                    </div>
                                </div>
                            )}
                        >
                            {items.map(item => (
                                <Option key={item}>{item}</Option>
                            ))}
                        </Select>
                    } />
                    <Step title={<h5>Wählen Sie ein Datum und klicken Sie auf die gewünschte Uhrzeit</h5>} description={
                        <div style={{ backgroundColor: "white", padding: 5 }}>
                            <Calendar
                                disabled={true}
                                selectable
                                localizer={localizer}
                                events={this.state.localEvents}
                                step={this.state.doctor.slotLength}
                                timeslots={2}
                                slotPropGetter={this.customSlotPropGetter}
                                min={new Date(`December 1, 1970 ${this.state.doctor.start}`)}
                                max={new Date(`December 1, 1970 ${this.state.doctor.end}`)}
                                defaultView={Views.WEEK}
                                scrollToTime={new Date(1970, 1, 1)}
                                defaultDate={new Date()}
                                views={["week", "day"]}
                                onSelectEvent={(event) => alert(event.title)}
                                onSelectSlot={({ start, end }) => this.onSelectSlot(start, end)}
                            />
                        </div>
                    } />
                </Steps>
                <div style={{ width: "100%", paddingTop: 30, display: "flex", justifyContent: "center" }}>
                    <Button type="primary" size="large" onClick={this.handleSelection} disabled={this.state.terminButton}>Termin anfragen</Button>
                </div>
                <Modal
                    title="Abschließende Fragen beantworten"
                    cancelText="Abbrechen"
                    okText="Abschließen"
                    visible={this.state.modalVisible}
                    onOk={() => this.setState({ modalVisible: false, terminButton: false })}
                    onCancel={() => this.setState({ modalVisible: false })}
                >
                    <div style={{ width: "100%", display: "flex", paddingTop: 15, justifyContent: 'center', alignItems: 'center' }}>
                        <Formik
                            initialValues={this.state.fields}
                            onSubmit={async (values) => {
                                this.setState({ fields: values });
                            }}
                        >
                            {({ values }) => (
                                <Form>
                                    <FieldArray name="form">
                                        {({ insert, remove, push }) => (
                                            <div>
                                                {values.form.length > 0 &&
                                                    values.form.map((field, index) => (
                                                        <div className="row" style={{ margin: 15 }} key={index}>
                                                            <span style={{ marginRight: 15 }}>{field.feldName}</span>
                                                            <Field
                                                                name={`form.${index}.feldData`}
                                                                type={field.feldTyp}
                                                            >
                                                                {({ field, form, meta }) => (
                                                                    <div>
                                                                        <Input {...field} />
                                                                    </div>
                                                                )}
                                                            </Field>
                                                            <ErrorMessage
                                                                name={field.feldName}
                                                                component="div"
                                                                className="field-error"
                                                            />
                                                        </div>
                                                    ))}
                                            </div>
                                        )}
                                    </FieldArray>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Modal>
            </>
        );
    }


}

export default TerminVereinbaren
