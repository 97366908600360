import React, { Component } from "react";
import { Input, AutoComplete } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import PersonIcon from "@material-ui/icons/Person";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Paper from "@material-ui/core/Paper";
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import ButtonBase from "@material-ui/core/ButtonBase";
import { Popover, Button, Menu } from 'antd';
import { MailOutlined, AppstoreOutlined, SettingOutlined } from '@ant-design/icons';
import VetSelectedDialog from "./vetSelectedDialog"
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import DatabaseService from "../../services/database.service"
import { Link, Redirect } from "react-router-dom";
import "./SearchBar.css";

class MainSearchBar extends Component {

    state = {
        lat: 48.137154,
        lng: 11.576124,
        zoom: 11,
        current: 'aerzte',
        doctors: [{ vorname: "", nachname: "", username: "", email: "", passwort: "" }],
        options: [<></>],
        currentId: 0,
        currentName: "",
        currentSlotLength: 0,
        min: "",
        max: "",
        events: [{ id: 0, title: "", start: new Date(2015, 3, 7), end: new Date(2015, 3, 10) }],
        open: false,
        redirect: false,
        selectedId: -1,
    };

    handleClick = e => {
        this.setState({ current: e.key });
    };

    renderTitle = title => (
        <Menu onClick={this.handleClick} selectedKeys={[title]} mode="horizontal">
            <Menu.Item key="aerzte">
                Ärzte
            </Menu.Item>
            <Menu.Item key="kliniken" disabled>
                Kliniken
            </Menu.Item>
        </Menu>
    );

    renderItem = (title) => ({
        value: title.id,
        label: (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    alignItems: "flex-start",
                    height: "100%",
                    width: "100%",
                    backgroundColor: "#F2F2F2",
                }}
            >
                <div
                    style={{
                        padding: "5px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <div style={{ display: "flex", flexDirection: 'row', alignItems: 'center' }}>
                        <PersonIcon style={{ fontSize: 30 }} />
                        <span
                            style={{
                                fontSize: 18,
                                padding: "5px",
                                backgroundColor: "#81BEF7",
                            }}
                        >
                            {title.titel + " " + title.vorname + " " + title.nachname}
                        </span>
                    </div>
                    <span style={{ fontSize: 18 }}>
                        {title.rate}€ / 15min
                    </span>
                </div>
                <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between', width: "100%" }}>
                    <div style={{ padding: "5px", display: "flex", flexDirection: 'row', alignItems: 'center' }}>
                        <LocationOnIcon style={{ fontSize: 23 }} />
                        <span style={{ fontSize: 20, padding: "5px" }}>
                            München
                        </span>
                    </div>
                    <span style={{ fontSize: 18, padding: "5px", textAlign: 'right' }}>
                        Fachgebiet: Hunde
                    </span>
                </div>
            </div>
        ),
    });

    position = [this.state.lat, this.state.lng]

    content = (
        <Map center={this.position} zoom={this.state.zoom}>
            <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={this.position}>
                <Popup>
                    A pretty CSS3 popup. <br /> Easily customizable.
                </Popup>
            </Marker>
        </Map>
    );

    handleOpen(val, option) {
        this.setState({ currentId: val, redirect: true })
    }

    handleEventUpdate(i, sl) {
        DatabaseService.getEvents({ id: i })
            .then(response => {
                var mappedData = response.data.map((singleEvent) => {
                    return { id: singleEvent.id, title: "Terminanfrage", start: new Date(singleEvent.time), end: new Date(new Date(singleEvent.time).getTime() + sl * 60000) };
                });
                this.setState({ events: mappedData, open: true, currentId: i, currentSlotLength: sl });
            })
            .catch(e => {
                console.log(e);
            });

    }

    handleClose() {
        this.setState({ open: false });
    }

    suffix = (
        <Popover content={this.content} title="Standort auswählen" trigger="click">
            <LocationOnOutlinedIcon />
        </Popover>
    );

    fetchVets = () => {
        DatabaseService.vet.profile
            .read()
            .then(response => {
                console.log(response.data);
                this.setState({ doctors: response.data, options: response.data.map(doc => { if (doc.email === "") return <></>; return this.renderItem(doc) }) });
            })
            .catch(e => {
                console.log(e);
            });
    };

    searchVets = value => {
        DatabaseService.vet.profile
            .search(value)
            .then(response => {
                console.log(response.data);
                this.setState({ doctors: response.data, options: response.data.map(doc => { if (doc.email === "") return <></>; return this.renderItem(doc) }) });
            })
            .catch(e => {
                console.log(e);
            });
    };

    render() {
        var options = [
            {
                label: this.renderTitle(this.state.current),
                options: this.state.options
            }
        ];

        if (this.state.redirect) {
            return (<Redirect to={`/profile/${this.state.currentId}`} />);
        }

        return (
            <>
                <AutoComplete
                    dropdownClassName="certain-category-search-dropdown"
                    style={{
                        width: "100%",
                    }}
                    onChange={value => this.searchVets(value)}
                    options={options}
                    onFocus={() => this.fetchVets()}
                    onSelect={(value, option) => this.handleOpen(value, option)}
                >
                    <Input.Search size="large" placeholder="Suche" enterButton suffix={this.suffix} />
                </AutoComplete>
            </>
        );

    }
}

export default MainSearchBar
