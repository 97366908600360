import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DescriptionIcon from '@material-ui/icons/Description';
import Paper from '@material-ui/core/Paper';
import store from 'store'
import AddIcon from '@material-ui/icons/Add';
import ButtonBase from "@material-ui/core/ButtonBase";
import UploadArea from "./components-redesign/new-dashboard/uploadArea"
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import UploadService from "./services/upload.service"


class FileList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fileList: null,
      uploadActive: false,
      fileDialog: false,
      source: null,
      animalId: this.props.animalId
    }
    this.loadFileList();
  }

  loadFileList() {
    UploadService.getFileList(this.state.animalId)
      .then(response => {
        this.setState({
          fileList: response.data.Contents.map((fileInf, index) => {
            return <ListItem button onClick={() => this.handleOpen(fileInf.Key)}>
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText primary={fileInf.Key.split('!')[2]} />
            </ListItem>
          })
        })
      })
  }

  handleClose() {
    this.loadFileList();
    this.setState({ fileDialog: false });
  }

  handleOpen(fI) {
    this.setState({ fileDialog: true });
    UploadService.getFile(fI)
      .then(response => {
        const base64 = btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            '',
          ),
        );
        this.setState({ source: "data:;base64," + base64 });
      });
  }

  render() {
    if (this.state.uploadActive) {
      return (
        <Dialog onClose={() => this.props.onClose()} maxWidth="lg" aria-labelledby="simple-dialog-title" open={this.props.open}>
          <DialogContent>
            <IconButton onClick={() => this.setState({ uploadActive: false })}>
              <CloseIcon />
            </IconButton>
            <UploadArea uploaderId={this.state.animalId}/>
          </DialogContent>
        </Dialog>
      );
    }
    return (
      <Dialog onClose={() => this.props.onClose()} maxWidth="lg" aria-labelledby="simple-dialog-title" open={this.props.open}>
        <DialogContent>
          <List component="nav" aria-label="main mailbox folders">
            {
              this.state.fileList
            }
          </List>
          <ButtonBase
            focusRipple
            style={{ width: "100%", paddingBottom: 10 }}
            onClick={() => this.setState({ uploadActive: true })}
          >
            <AddIcon />
            <span style={{ fontSize: 20, textAlign: 'center' }}>Datei hochladen</span>
          </ButtonBase>
          <Dialog onClose={() => this.handleClose()} maxWidth="lg" aria-labelledby="simple-dialog-title" open={this.state.fileDialog}>
            <DialogContent style={{ display: "flex" }}>
              <img src={this.state.source} />
            </DialogContent>
          </Dialog>
        </DialogContent>
      </Dialog>
    );
  }

}
export default FileList;
