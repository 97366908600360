import React from 'react';
import {
    Layout,
    Form,
    Input,
    Tooltip,
    Cascader,
    Select,
    Row,
    Col,
    Checkbox,
    Button,
    AutoComplete,
} from 'antd';
import MainSearchBar from "./new-dashboard/MainSearchBar"
import Pfote from "./Pfote.png";
import "./LogIn.css"
import { ButtonBase, Paper } from '@material-ui/core';
import BackgroundImage from "./Volna4k-Edit.png"
import { NavLink, Link } from "react-router-dom";
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Redirect } from "react-router-dom";
import store from 'store'
import isLoggedIn from '../is_logged_in';
import DatabaseService from "../services/database.service";

const { Header, Content, Footer } = Layout;

class Register extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showMessage: false,
            errors: {}
        };
    }

    handleHeaderClick = e => {
        console.log(e.key);
    };

    onFinish = values => {

        this.setState({ showMessage: true });

        DatabaseService.checkUsernameAvail(this.state.account.username)
            .then(response => {
                if (!response.data.result) {
                    this.state.errors.username = "Username already in use"
                    return;
                }
                else {
                    DatabaseService.animalOwner
                        .checkEmail(this.state.account.email)
                        .then(response => {
                            if (!response.data.result) {
                                this.state.errors.email = "E-Mail already in use";
                                return;
                            }
                            else {
                                let data = {
                                    vorname: this.state.account.vorname,
                                    nachname: this.state.account.nachname,
                                    username: this.state.account.username,
                                    email: this.state.account.email,
                                    passwort: this.state.account.passwort,
                                };

                                DatabaseService.animalOwner
                                    .create(data)
                                    .then(response => {
                                        this.setState({
                                            vorname: response.data.vorname,
                                            nachname: response.data.nachname,
                                            username: response.data.username,
                                            email: response.data.email,
                                            passwort: response.data.passwort,

                                            submitted: true
                                        });
                                        console.log(response.data);
                                    })
                                    .catch(e => {
                                        console.log(e);
                                    });
                            }
                        })
                        .catch(e => {
                            console.log(e);
                            return;
                        });
                }
            })
            .catch(e => {
                console.log(e);
                return;
            });

    };

    formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };
    tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };

    render() {

        return (
            <Layout className="layout" style={{ backgroundImage: `url(${BackgroundImage})` }}>
                <Header className="header" style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Link to="/">
                        <img src={Pfote} width="65" height="65" alt="" loading="eager" />
                    </Link>
                    <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Link to="/Login">
                            <Button type="primary" >Anmelden</Button>
                        </Link>
                    </div>
                </Header>
                <Content>
                    <div className="sitelayoutcontent">
                        {this.state.showMessage ? (
                            <Paper style={{ display: "flex", marginTop: 20, flexDirection: 'column', justifyContent: 'center', backgroundColor: "#e3e3e3" }}>
                                <span style={{ textAlign: 'center', color: "#1e90ff", padding: 20 }}>Zur Verifikation ihres Accounts wurde eine E-Mail an ihre Addresse versendet</span>
                                <span style={{ textAlign: 'center', color: "#1e90ff", padding: 20 }}>Öffnen sie den Link in dieser Mail und folgen sie den Anweisungen</span>
                            </Paper>
                        ) : (
                            <div className="sitelayoutcontentregister">
                                <Form
                                    {...this.formItemLayout}
                                    name="register"
                                    onFinish={this.onFinish}
                                    scrollToFirstError
                                    size="large"
                                >

                                    <Form.Item
                                        name="Vorname"
                                        label="Vorname"
                                        rules={[{ required: true, message: 'Bitte Vorname eingeben!', whitespace: true }]}
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        name="Nachname"
                                        label="Nachname"
                                        rules={[{ required: true, message: 'Bitte Nachname eingeben!', whitespace: true }]}
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        name="Benutzername"
                                        label={
                                            <span>
                                                Benutzername&nbsp;
                                                <Tooltip title="Unter welchem namen sollen andere Nutzer sie sehen?">
                                                    <QuestionCircleOutlined />
                                                </Tooltip>
                                            </span>
                                        }
                                        rules={[{ required: true, message: 'Bitte Username eingeben!', whitespace: true }]}
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        name="email"
                                        label="E-mail"
                                        rules={[
                                            {
                                                type: 'email',
                                                message: 'Bitte eine gültige E-mail eingeben!',
                                            },
                                            {
                                                required: true,
                                                message: 'Bitte E-mail eingeben!',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        name="passwort"
                                        label="Passwort"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Bitte Passwort eingeben!',
                                            },
                                        ]}
                                        hasFeedback
                                    >
                                        <Input.Password />
                                    </Form.Item>

                                    <Form.Item
                                        name="bestätigung"
                                        label="Passwort bestätigen"
                                        dependencies={['passwort']}
                                        hasFeedback
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Bitte bestätigen sie ihr Passwort!',
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject('Die Passwörter stimmen nicht überein!');
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password />
                                    </Form.Item>

                                    <Form.Item
                                        name="agreement"
                                        valuePropName="checked"
                                        rules={[
                                            { validator: (_, value) => value ? Promise.resolve() : Promise.reject('Sie müssen den Bedingungen zustimmen') },
                                        ]}
                                        {...this.tailFormItemLayout}
                                    >
                                        <Checkbox>
                                            Ich habe die <a href="">Nutzungsbedingungen</a> gelesen und stimme diesen zu
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item {...this.tailFormItemLayout}>
                                        <Button type="primary" htmlType="submit" block>
                                            Registrieren
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        )}
                    </div>
                </Content>
                <Footer style={{ height: "15px", display: "flex", flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <NavLink className="nav-item nav-link" to="/team">
                        Team
                    </NavLink>
                    <NavLink className="nav-item nav-link" to="/contact">
                        Kontakt
                    </NavLink>
                    <NavLink className="nav-item nav-link" to="/news">
                        News
                    </NavLink>
                    <NavLink className="nav-item nav-link" to="/impressum">
                        Impressum
                    </NavLink>
                </Footer>
            </Layout>
        );
    }
}

export default Register;
