import React from 'react';
import { motion, AnimateSharedLayout } from "framer-motion";
import Profil from "./Profil"

const spring = {
    type: "spring",
    stiffness: 500,
    damping: 30
};

class Settings extends React.Component {

    constructor(props) {
        super(props);
        console.log("Settings.js: this.props ", this.props);
        this.state = {
            currentHeaderKey: "profil"
        }
    }

    detectMob() {
        return ( ( window.innerWidth <= 800 ) && ( window.innerHeight <= 1000 ) );
    }

    render() {
        if (this.detectMob()) {
            return (
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", width: "100%", padding: 10 }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%", fontSize: 22 }}>
                        <AnimateSharedLayout>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", marginTop: 10 }}>
                                <span style={{ fontWeight: "bold" }} onClick={() => this.setState({ currentHeaderKey: 'profil' })}>Mein Konto</span>
                                {this.state.currentHeaderKey === 'profil' && <motion.div className="horizontalLineMenu" layoutId="marker" transition={spring} />}
                            </div>
                        </AnimateSharedLayout>
                    </div>
                    <div>
                    </div>
                    {this.state.currentHeaderKey === "profil" && <Profil {...this.props} user={this.props.user} onChange={(data) => this.props.onChange('einstellungen:profil', data)} />}
                </div>
            );
        }

        return (
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", width: "100%" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%", fontSize: 22 }}>
                    <AnimateSharedLayout>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", marginTop: 10 }}>
                            <span style={{ fontWeight: "bold" }} onClick={() => this.setState({ currentHeaderKey: 'profil' })}>Mein Konto</span>
                            {this.state.currentHeaderKey === 'profil' && <motion.div className="horizontalLineMenu" layoutId="marker" transition={spring} />}
                        </div>
                    </AnimateSharedLayout>
                </div>
                <div>
                </div>
                {this.state.currentHeaderKey === "profil" && <Profil {...this.props} user={this.props.user} onChange={(data) => this.props.onChange('einstellungen:profil', data)} />}
            </div>
        );
    }


}

export default Settings
