import React from 'react';
import './Dashboard.css';
import { Layout, Menu, Dropdown, Button } from 'antd';
import MainSearchBar from "./MainSearchBar"
import Pfote from "./Pfote.png";
import AnimalView from "./AnimalView"
import { ButtonBase } from '@material-ui/core';
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import CallDialog from "./callDialog"
import DatabaseService from "../../services/database.service"
import UploadService from "../../services/upload.service"
import store from 'store'
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import EventNoteIcon from '@material-ui/icons/EventNote';
import PetsIcon from '@material-ui/icons/Pets';
import PersonIcon from '@material-ui/icons/Person';
import HomeIcon from '@material-ui/icons/Home';
import { Redirect } from "react-router-dom";
import Settings from "./Settings";
import { MenuOutlined } from '@ant-design/icons';


const { Header, Content, Footer } = Layout;

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            doctorsCalling: false,
            callDeclined: false,
            nextEvent: {},
            userEvents: [],
            updateUserEvents: false,
            sessionDoctor: { vorname: "", nachname: "", username: "", email: "" },
            vorname: "",
            nachanme: "",
            navState: 0
        };

        this.updateEvents();

        DatabaseService.animalOwner
            .readOne(store.get('usrId'))
            .then(response => {
                this.setState({ vorname: response.data.vorname, nachname: response.data.nachname });
            })
            .catch(e => {
                console.log(e);
            });

        setInterval(async () => {
            this.setState({ updateUserEvents: !this.state.updateUserEvents });
            if (this.state.updateUserEvents) this.updateEvents();
            UploadService.
                sessionActive(store.get('usrId'))
                .then(response => {
                    if (response.data.sessionId) {
                        DatabaseService.vet.profile
                            .readOne(response.data.vetId)
                            .then(res => {
                                this.setState({ doctorsCalling: true, sessionCreds: response.data, sessionDoctor: res.data });
                            })
                            .catch(e => {
                                console.log(e);
                            });
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        }, 5000);
    }



    handleCallDialogClose() {
        this.setState({ doctorsCalling: false, callDeclined: true });
    }


    updateEvents() {
        DatabaseService.event
            .read(store.get('usrId'))
            .then(response => {
                if (!response.data) {
                    return;
                }
                this.setState({ nextEvent: { id: response.data[0].id, confirmed: response.data[0].confirmed, title: "Terminanfrage", start: new Date(response.data[0].time), end: new Date(new Date(response.data[0].time).getTime() + response.data[0].slotlength * 60000), vetID: response.data[0].vetID, userID: response.data[0].userID, petId: response.data[0].petId } });
                var mappedData = response.data.map((singleEvent) => {
                    const now = new Date();
                    const mappedEvent = { id: singleEvent.id, confirmed: singleEvent.confirmed, title: Number(singleEvent.confirmed) ? "Bestätigt" : "Terminanfrage", start: new Date(singleEvent.time), end: new Date(new Date(singleEvent.time).getTime() + singleEvent.slotlength * 60000), vetID: singleEvent.vetID, userID: singleEvent.userID, petId: singleEvent.petId };
                    const differenceNext = this.state.nextEvent.end.valueOf() - now.valueOf();
                    const differenceMap = mappedEvent.end.valueOf() - now.valueOf();
                    if (differenceNext < 0 || (differenceMap < differenceNext && differenceMap > 0)) {
                        this.setState({ nextEvent: mappedEvent });
                    }
                    return mappedEvent;
                });
                this.setState({ userEvents: mappedData });
            })
            .catch(e => {
                console.log(e);
            });
    };




    render() {
        var currentContent;
        const localizer = momentLocalizer(moment);

        switch (this.state.navState) {
            case 0:
                currentContent = (
                    <MainSearchBar updateEvents={() => this.updateEvents()} />
                );
                break;
            case 1:
                currentContent = (
                    <div style={{ height: "100%", width: "100%" }}>
                        <AnimalView />
                    </div>
                );
                break;
            case 2:
                currentContent = (
                    <Calendar
                        localizer={localizer}
                        events={this.state.userEvents}
                        defaultView={Views.DAY}
                        scrollToTime={new Date(1970, 1, 1, 6)}
                        defaultDate={new Date()}
                        views={["day"]}
                        style={{ padding: 0, width: "100%", margin: 0, backgroundColor: "white" }}
                    />
                );
                break;
            case 3:
                currentContent = (
                    <div style={{ height: "100%", width: "100%" }}>
                        <Settings />
                    </div>
                );
                break;
            case 4:
                store.remove('loggedIn');
                currentContent = (
                    <Redirect to="/" />
                );
                break;
            default:
                currentContent = (
                    <MainSearchBar />
                );
                break;
        }

        return (
            <Layout className="layout" style={{ height: "100vh" }}>
                <Header style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between' }}>
                    <ButtonBase onClick={() => this.setState({ currentHeaderKey: 'dashboard' })}>
                        <img src={Pfote} width="65" height="65" alt="" loading="eager" />
                    </ButtonBase>
                    <Dropdown overlay={
                        <Menu onClick={() => this.setState({ navState: 4 })} >
                            <Menu.Item key="0">Logout</Menu.Item>
                        </Menu>
                    } trigger={['click']}>
                        <span style={{ color: "white" }}>
                            {this.state.vorname + " " + this.state.nachname}
                        </span>
                    </Dropdown>
                </Header>
                <Content>
                    <div className="site-layout-content">
                        {currentContent}
                    </div>
                </Content>
                <Footer style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "0px", margin: "0px" }}>
                    <BottomNavigation
                        style={{ width: "100%" }}
                        value={this.state.navState}
                        onChange={(event, newValue) => {
                            this.setState({ navState: newValue })
                        }}
                        showLabels
                    >
                        <BottomNavigationAction label="Home" icon={<HomeIcon />} />
                        <BottomNavigationAction label="Tiere" icon={<PetsIcon />} />
                        <BottomNavigationAction label="Termine" icon={<EventNoteIcon />} />
                        <BottomNavigationAction label="Profil" icon={<PersonIcon />} />
                    </BottomNavigation>
                </Footer>
                <CallDialog
                    open={this.state.doctorsCalling}
                    onClose={() => this.handleCallDialogClose()}
                    name={this.state.sessionDoctor.titel + " " + this.state.sessionDoctor.vorname + " " + this.state.sessionDoctor.nachname}
                />
            </Layout>
        );
    }
}

export default Dashboard;
