import React from "react";
import Paper from '@material-ui/core/Paper';
import ButtonBase from "@material-ui/core/ButtonBase";
import Popover from "react-popover"
import PetPopover from "./PetPopover"
import AddIcon from '@material-ui/icons/Add';
import store from 'store'
import DatabaseService from "../../services/database.service"
import "./AnimalView.css"
import { List, Form, Input, Button, InputNumber } from 'antd';
import { Space } from 'antd';

class AnimalView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            addAnimal: false,
            data: [
            ],
            popoverOpen: false,
        };

        this.getPetList();
    }

    getPetList() {
        const usrData = { id: parseInt(store.get('usrId'), 10) };

        DatabaseService.animal
            .read(usrData)
            .then(response => {
                console.log(response.data);
                this.setState({ data: response.data });
            })
            .catch(e => {
                console.log(e);
            });
    }

    onFinish = values => {

        const submitData = {
            id: store.get('usrId'),
            name: values.name,
            tierart: values.tierart,
            tierrasse: values.tierrasse,
            tierAlter: values.alter
        }

        DatabaseService.animal
            .create(submitData)
            .then(response => {
                console.log(response.data);
                this.setState({ addAnimal: false });
                this.getPetList();
            })
            .catch(e => {
                console.log(e);
            });
    };

    onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    tailLayout = {
        wrapperCol: { offset: 3, span: 25 },
    };

    render() {
        var Footer;

        if (this.state.addAnimal) {
            Footer = (
                <Paper style={{ height: "500px", width: "310px", display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <span style={{ color: "#1e90ff", textAlign: "center", fontSize: 20, margin: 10 }}>Füge dein Haustier hinzu!</span>
                    <Form
                        {...this.layout}
                        name="basic"
                        initialValues={{ remember: true }}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                    >
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[{ required: true, message: 'Bitte den Namen deines Tieres eingeben!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Tierart"
                            name="tierart"
                            rules={[{ required: true, message: 'Bitte die Tierart deines Tieres eingeben!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Tierrasse"
                            name="tierrasse"
                            rules={[{ required: true, message: 'Bitte die Tierrasse deines Tieres eingeben!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Alter"
                            name="alter"
                            rules={[{ required: true, message: 'Bitte das Alter deines Tieres eingeben!' }]}
                        >
                            <InputNumber />
                        </Form.Item>

                        <Form.Item {...this.tailLayout}>
                            <Button type="primary" htmlType="submit">
                                Hinzufügen
                            </Button>
                            <Button htmlType="button" style={{ marginLeft: 10 }} onClick={() => this.setState({ addAnimal: false })}>
                                Abbrechen
                            </Button>
                        </Form.Item>
                    </Form>
                </Paper>
            );
        }
        else {
            Footer = (
                <ButtonBase
                    style={{ height: "100%", width: "100%" }}
                    onClick={() => this.setState({ addAnimal: true })}
                >
                    <Paper style={{ height: "200px", width: "300px", display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <AddIcon style={{ fontSize: 40 }} />
                    </Paper>
                </ButtonBase>
            );
        }

        return (
            <List
                dataSource={this.state.data}
                footer={Footer}
                renderItem={item => (
                    <List.Item>
                        <Popover isOpen={item.popoverOpen} onOuterAction={() => {
                            const listData = this.state.data;
                            listData.map(obj => { return obj.popoverOpen = false; });
                            this.setState({ data: listData });
                        }} preferPlace="below" body={<PetPopover animalId={item.id} />}>
                            <ButtonBase
                                style={{ height: "100%", width: "100%" }}
                                onClick={() => {
                                    const listData = this.state.data;
                                    listData.map(obj => { return obj.popoverOpen = false; });
                                    listData[listData.findIndex((element) => element.id === item.id)].popoverOpen = true;
                                    this.setState({ data: listData });
                                }}
                            >
                                <Paper className="animal-paper-box" >
                                    <span style={{ color: "#1e90ff", fontSize: 30, fontWeight: 'bold', textAlign: 'center' }}>{item.name}</span>
                                    <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between', margin: 8 }}>
                                        <div>
                                            <span style={{ fontSize: 25, color: "#1e90ff" }}>Tierart: </span>
                                            <span style={{ fontSize: 25 }}>{item.tierart}</span>
                                        </div>
                                        <div>
                                            <span style={{ fontSize: 25, color: "#1e90ff" }}>Alter: </span>
                                            <span style={{ fontSize: 25 }}>{item.tierAlter}</span>
                                        </div>
                                    </div>
                                </Paper>
                            </ButtonBase>
                        </Popover>
                    </List.Item>
                )}
            />
        );
    }

}

export default AnimalView
