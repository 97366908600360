import React from 'react';
import { Input, List } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import DatabaseService from "../../services/database.service"

class Termin extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            doctors: []
        }
        this.fetchVets();
    }


    fetchVets = () => {
        DatabaseService.vet.profile
            .read()
            .then(response => {
                console.log(response.data);
                this.setState({ doctors: response.data });
            })
            .catch(e => {
                console.log(e);
            });
    };

    searchVets = e => {
        const { value } = e.target;
        DatabaseService.vet.profile
            .search(value)
            .then(response => {
                if (response.data === null)
                    this.setState({ doctors: [] });
                else
                    this.setState({ doctors: response.data });
            })
            .catch(e => {
                console.log(e);
            });
    };

    render() {
        return (
            <>
                <Input size="large" placeholder="Suche" prefix={<SearchOutlined />} onChange={e => this.searchVets(e)} />
                <List
                    itemLayout="horizontal"
                    style={{ width: "100%", height: "100%" }}
                    dataSource={this.state.doctors}
                    renderItem={item => (
                        <List.Item style={{ width: "100%", height: "100%" }} onClick={() => {
                            this.props.setRightPane('vet');
                            this.props.setCurrentDoctor(item);
                        }}>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-around",
                                    alignItems: "flex-start",
                                    height: "100%",
                                    width: "100%",
                                }}
                            >
                                <div
                                    style={{
                                        padding: "5px",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        width: "100%",
                                    }}
                                >
                                    <div style={{ display: "flex", flexDirection: 'row', alignItems: 'center' }}>
                                        <span
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: 20,
                                                padding: "5px",
                                            }}
                                        >
                                            {item.titel + " " + item.vorname + " " + item.nachname}
                                        </span>
                                    </div>
                                    <span style={{ fontWeight: "bold", fontSize: 20 }}>
                                        {item.rate}€ / {item.slotLength}min
                                    </span>
                                </div>
                                <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between', width: "100%" }}>
                                    <div style={{ padding: "5px", display: "flex", flexDirection: 'row', alignItems: 'center' }}>
                                        <span style={{ fontSize: 20, padding: "5px" }}>
                                            München
                                        </span>
                                    </div>
                                    <span style={{ fontSize: 20, padding: "5px", textAlign: 'right' }}>
                                        Fachgebiet: Hunde
                                    </span>
                                </div>
                            </div>
                        </List.Item>
                    )}
                />

            </>
        );
    }


}

export default Termin
