import React, {useState} from 'react';
import Logo from "./assets/HelloVetPetLogo.png"
import { Form, Input, Layout, Button, Collapse, Checkbox, Select, Result, Tooltip } from 'antd';
import PhoneIcon from '@material-ui/icons/Phone';
import MainHeader from "./assets/MainHeader.png"
import HeaderSub from "./assets/HeaderSub.png"
import Phone from "./assets/phone.png"
import Computer from "./assets/Bitmap.png"
import Background1 from "./assets/Path-6.png"
import Background2 from "./assets/Path 2.png"
import Background3 from "./assets/Path 3.png"
import Kooperationspartner from "./assets/koop_2x.png"
import MeraLogo from "./assets/Mera-logo.png"
import RosengartenLogo from "./assets/rosengarten.png"
import TierheimheldenLogo from "./assets/tierheimhelden.svg"
import Gruende from "./assets/gruende.png"
import Features from "./assets/features.png"
import Kollegen from "./assets/kollegen.png"
import LohntEsSich from "./assets/lohnt_es_sich.png"
import VorteileImg from "./assets/VorteileImg.png"
import CheckIcon from "./assets/CheckIcon.png"
import CalendarIcon from "./assets/CalendarIcon.png"
import Kollege from "./assets/Kollege.png"
import KollegeBackground from "./assets/KollegeBackground.png"
import DeskImg from "./assets/DeskImg.png"
import LineFooter from "./assets/LineFooter.png"
import UserCat from "./assets/UserCat.png"
import UserDog from "./assets/UserDog.png"
import UserDot1 from "./assets/UserDot1.png"
import UserDot2 from "./assets/UserDot2.png"
import UserDot3 from "./assets/UserDot3.png"
import UserElement from "./assets/UserElement.png"
import UserPath1 from "./assets/UserPath1.png"
import HilfeIcon from "./assets/HilfeIcon.png"
import ProblemIcon from "./assets/ProblemIcon.png"
import LogoLMU from "./assets/LogoLMU.png"
import LogoEXIST from "./assets/Logo-EXIST.png"
import { HashLink } from 'react-router-hash-link';
import axios from "axios"
import {
  Link
} from "react-router-dom";
import {UnmountClosed as FormCollapse} from 'react-collapse';
import { CSSTransition } from 'react-transition-group';
import { CloseOutlined } from '@ant-design/icons';
import Countdown from 'react-countdown';
import "./UserHome.css"

const { Panel } = Collapse;
const { Header, Content, Footer } = Layout;
const text = `
Einfach nur TOP Leistung! Sehr freundlich, gefühlvoll und hatte sofort das Problem erkannt. Unser kleiner Mops-Welpe Platon ist wieder fit. Einfach nur TOP Leistung! Sehr freundlich, gefühlvoll und hatte sofort das Problem erkannt. Unser kleiner Mops-Welpe Platon ist wieder fit.
`;



const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
};

const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
};

const countdownRenderer = ({ days, hours, minutes, seconds}) => {
        return (
          <div style={{display:"flex", flexDirection:"row"}}>
                  <div style={{flex:1, display:"flex", flexDirection:"column", alignItems:"center", paddingRight:40}}>
                  <span className="main-heading" style={{color:"#25D2AA",  fontFamily: "WorkSans" }}>{days}</span>
                  <span className="section-text">Tage</span>
                  </div>
                  <div style={{flex:1, display:"flex", flexDirection:"column", alignItems:"center", paddingRight:40}}>
                  <span className="main-heading" style={{color:"#25D2AA",  fontFamily: "WorkSans" }}>{hours}</span>
                  <span className="section-text">Stunden</span>
                  </div>
                  <div style={{flex:1, display:"flex", flexDirection:"column", alignItems:"center", paddingRight:40}}>
                  <span className="main-heading" style={{color:"#25D2AA",   fontFamily: "WorkSans" }}>{minutes}</span>
                  <span className="section-text">Minuten</span>
                  </div>
                  <div style={{flex:1, display:"flex", flexDirection:"column", alignItems:"center"}}>
                  <span className="main-heading" style={{color:"#25D2AA",  fontFamily: "WorkSans" }}>{seconds}</span>
                  <span className="section-text">Sekunden</span>
                    </div>
            </div>
        );
};

function UserHome() {
  const  [collapseOpen, setCollapseOpen]=useState(false);
  const [emailSubmitted, setEmailSubmitted] = useState(false);

  const onFinish = values => {

      axios.post("https://api.hellovet.de/store_mail.php", {mail:values.email})
      .then(function (response) {
                setEmailSubmitted(true);
        })
        .catch(function (error) {
          console.log(error);
        })
  };

  const sendQuestion = values => {

    axios.post("https://api.hellovet.de/store_question.php", {name:values.name, mail:values.email, question:values.anfrage})
    .then(function (response) {
              setCollapseOpen(false);
      })
      .catch(function (error) {
        console.log(error);
      })

  }

  return (
    <Layout className="layout" style={{backgroundColor:"white"}}>
      <Header className="navbar" style={{backgroundColor:"white"}}>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center" }}>
          <HashLink smooth to="/#top" >
             <img src={Logo} className="logo" />
          </HashLink>
          <div className="navbar-menu">
          <HashLink smooth to="/#kontakt" scroll={el => scrollWithOffset(el)}>
            <Button style={{ backgroundColor: "#25D2AA", color: "white" }} shape="round">
              Mehr Infos erhalten
            </Button>
          </HashLink>
          </div>
        </div>
      </Header>
      <Content >
        <div>
          <div style={{ position: "absolute", width: "100%", top: "12%", zIndex: 7, display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }}>
            <span className="main-heading">Triff (d)ein(e)</span>
            <span className="main-heading">HaustierarztIn, online.</span>
            <div style={{ height: "0.7vh"}}/>
              <span className="main-subheading" >Neugierig?</span>
            <span className="main-subheading" > Dann melde Dich jetzt für unseren Newsletter an.</span>
            <span className="main-subheading">Wir informieren Dich sobald sich die Türen öffnen und </span>
            <span className="main-subheading">Du mit Deinem HelloVet Konto eine Jahresfutterration</span>
            <span className="main-subheading">für Deinen Vierbeiner gewinnen kannst.</span>
            <Tooltip color="white" placement="top" title={<span style={{color:"black",  fontSize: 14, fontFamily: "WorkSans" }}>Nur vollständig ausgefüllte Tierakten nehmen an der Verlosung bis zum 31.12.2021 teil. Der Preis (je eine Jahresfutterration à 250kg für Hunde und Katzen) wird gesponsert von der Mera-Petfood GmbH. Der Rechtsweg ist ausgeschlossen. </span>}>
            <span style={{color:"#25D2AA",  fontSize: 14, fontFamily: "WorkSans" }}>Teilnahmebedingungen</span>
            </Tooltip>

            { !emailSubmitted &&
              <Form
                  style={{ marginTop: "1vh", width:"280px"}}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  size="large"
                  requiredMark={false}
              >

                  <Form.Item
                      name="email"
                      rules={[
                        {
                            type: 'email',
                            message: 'Keine gültige E-Mail!',
                          },
                        { required: true, message: 'Bitte E-Mail eingeben!' }
                      ]}
                      style={{marginBottom:15}}
                  >
                      <Input style={{borderRadius:"10px"}} placeholder="max.mustermann@net.de"/>
                  </Form.Item>

                  <Form.Item >
                      <Button style={{ backgroundColor: "#25D2AA", color: "white",  fontSize: 18 }} shape="round" htmlType="submit" block>
                          Anmelden
                      </Button>
                  </Form.Item>
              </Form>  }
              <CSSTransition
              in={emailSubmitted}
              timeout={300}
              classNames="alert"
              unmountOnExit
              >
              <Result
              status="success"
              title="E-Mail abgeschickt"

              />
                  </CSSTransition>
          </div>
          <img src={Phone} className="phone-image" />
          <img src={UserCat} className="user-cat" />
          <img src={UserDot2} className="user-dot2"/>
          <img src={UserDot1} className="user-dot1" />
          <img src={UserDot3} className="user-dot3" />
          <img src={UserPath1} className="user-path" />
          <img src={UserElement} className="user-element" />

          <div style={{ position: "absolute", width: "100%", top: "76vh", display: "flex", flexDirection: "column", alignItems: "center",backgroundImage: `url(${Kooperationspartner})`, backgroundRepeat:"no-repeat", backgroundSize:"contain", zIndex: 10 }}>

          <div style={{marginTop: "5vw",width:"100%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
          <span className="section-text" style={{ zIndex: 14 }}>Unsere Netzwerkpartner</span>
          <div style={{width:"80%", maxWidth:"1800px", display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
            <img src={RosengartenLogo} style={{  width:"14vw" }}/>
            <img src={MeraLogo} style={{  width:"7vw" }}/>
            <img src={TierheimheldenLogo} style={{ marginLeft:30, width:"12vw" }} />
            <img src={LogoLMU} style={{ marginLeft:30, width:"12vw" }} />
            <img src={LogoEXIST} style={{ marginLeft:30, width:"12vw" }} />
          </div>
          </div>

            <div style={{height:"80px"}}/>
          <div style={{width:"100%", display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"center", padding:20}}>
          <span  className="section-text">Wir bitten Dich noch um ein kleinwenig Geduld. HelloVet befindet sich gerade in der internen Testphase.  </span>
          <span  className="section-text" >Viele Vier- und Zweibeiner überprüfen das System auf Herz und Nieren. </span>
          <span  className="section-text" >Aber nicht mehr lang, und HelloVet steht Dir und Deinem vierbeinigen Familienmitglied</span>
          <span  className="section-text" >tagtäglich mit Rat und Tat zur Verfügung. Wir freuen uns auf Euch!</span>
                        <div style={{height:"30px"}}/>
                  <Countdown
                    date={'2021-11-01T00:00:00'}
                    renderer={countdownRenderer}
                    />
              </div>
            <div style={{height:"100px"}} id="kontakt"/>
            <span className="section-heading" >Du hast weitere Fragen?</span>
            <span className="section-text" style={{ marginTop: 10}} >Dann stell sie uns! Wir sind für Dich da und helfen gern.</span>
            <Button style={{ marginTop: 50, marginBottom: 30, backgroundColor: "#25D2AA", color: "white", height: "60px", width: "320px", fontSize: 24 }} shape="round" onClick={()=>setCollapseOpen(!collapseOpen)}>
              Hier Frage stellen
        </Button>
            <FormCollapse isOpened={collapseOpen}>
              <div style={{borderStyle:"solid", borderColor:"grey", borderRadius: "9px", borderWidth:2, padding:20, marginBottom:40}}>
              <Form name="nest-messages" onFinish={sendQuestion} layout="vertical" requiredMark={false}>
              <Form.Item name="name" label="Name" rules={[{ required: true, message: "Bitte deinen Namen eingeben" }]} style={{marginBottom:10}}>
              <Input placeholder="Max Mustermann"/>
              </Form.Item>
              <Form.Item name="email" label="Email" rules={[{ type: 'email', required: true, message: "Bitte deine E-Mail eingeben"  }]} style={{marginBottom:10}}>
              <Input placeholder="max.mustermann@net.de"/>
              </Form.Item>
              <Form.Item name="anfrage" label="Anfrage">
              <Input.TextArea />
              </Form.Item>
              <Form.Item style={{marginBottom:0}} >
                  <Button style={{ backgroundColor: "#25D2AA", color: "white" }} shape="round" htmlType="submit" block>
                      Senden
                  </Button>
              </Form.Item>
              </Form>
              </div>
              </FormCollapse>
            <span className="section-text" style={{ marginTop: 10 }} >hellovet.com</span>
            <img src={LineFooter} style={{ width: "100%", zIndex: 0 }} />

            <div style={{  display: "flex", flexDirection: "row" }}>
              <div style={{paddingRight:30}}>
              <img src={Logo} className="logo" />
              </div>
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }}>
                <span className="footer-headings" >Rechtliches</span>
                <Link className="footer-elements" to="/datenschutz" >
                <span  >Datenschutz</span>
                </Link>
                <Link className="footer-elements" to="/impressum" >
                <span  >Impressum</span>
                </Link>
              </div>
            </div>
            <span style={{ marginTop: 90, marginBottom: 50, fontSize: 16, fontFamily: "WorkSans" }} >Veticine Ventures GmbH, Alle Rechte vorbehalten.</span>
          </div>

        </div>
      </Content>
    </Layout>
  );

}

export default UserHome
